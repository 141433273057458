<template>
  <div>
    Edit News
    <v-text-field type="number" v-model="config.limit" label="Display Limit"/>
    <v-btn @click="save">Apply</v-btn>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
export default {
    props: {
        language: {
            type: String,
            required: true
        }
    },
    components: {
        draggable
    },
    created: function(){
        let config = JSON.stringify(this.model)
        if(config){
            config = JSON.parse(config)
            this.config = config
        }
    },
    data: function(){
        return {
            config: {
                limit: 3
            }
        }
    },
    methods: {
        template: function(){
            return {
                path: null,
                label: {}
            }
        },
        save: function(){
            this.$emit('input',this.config)
        },
        add: function(){
            this.config.links.push(this.template())
        },
        remove: function(index){
            this.config.links.splice(index, 1)
        }
    },
    computed: {
        model: function(){
            return this.$attrs.value
        }
    }
}
</script>

<style>

</style>