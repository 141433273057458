<template>
    <v-card>
        <v-card-title>
            Component Details
        </v-card-title>
        <v-card-text>
            <v-select v-model="form.component" label="Component Type" :items="componentList"/>
            <component :is="form.component" v-model="form.config" :languages="languages" @cancel="cancel" @ok="ok"/>
        </v-card-text>
        <v-card-actions>
            <slot name="actionButtons"></slot>
        </v-card-actions>
    </v-card>
</template>

<script>
import Title from './lib/TitleForm'
import HTMLText from './lib/HTMLTextForm'
import Partners from './lib/Partners'
import Videos from './lib/VideoForm'
export default {
    props: {
        languages: {
            type: Array,
            required: true
        }
    },
    components: {
        Title,
        HTMLText,
        Partners,
        Videos
    },
    methods: {
        cancel: function(){
            console.log('cancel')
        },
        ok: function(data){
            console.log('ok',data)
        }
    },
    computed: {
        form: function(){
            return this.$attrs.value
        },
        componentList: function(){
            return [
                {text:'Title',value:'Title'},
                {text:'HTML Text',value:'HTMLText'},
                {text:'Partners',value:'Partners'},
                {text:'Videos',value:'Videos'}
            ]
        }
    }
}
</script>

<style>

</style>