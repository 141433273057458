<template>
<div v-if="ready">

    <!-- <v-card>
        <v-card-title>Preview</v-card-title>
        <v-card-text>
            <div style="text-align: center; width: 80%; margin: 0 auto; border: 1px solid grey;">
                <Banner :config="config"/>
            </div>
        </v-card-text>
    </v-card> -->

    <v-btn @click="newSlide">New Slide</v-btn>
    <v-switch v-model="config.cycle.enabled" :true-value="true" :false-value="false" label="Enable Cycle"/>
    <v-text-field type="number" v-model="config.cycle.delay" v-if="config.cycle.enabled" label="Cycle Delay (seconds)"/>
    <v-card flat>
        <v-card-text>
            <v-expansion-panels>
                <draggable v-model="config.slides" style="width:100%;" >
                    <v-expansion-panel v-for="(slide, slideIndex) in config.slides" :key="`slide_${slideIndex}`">
                        <v-expansion-panel-header>
                            {{slide.button[$store.getters.language]}}

                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-tabs v-model="ui.activeTab">
                                <v-tab v-for="language in languages" :key="`slide-language-tab-${language}`">
                                    {{language}}
                                </v-tab>
                            </v-tabs>

                            <v-tabs-items v-model="ui.activeTab">
                                <v-tab-item v-for="language in languages" :key="`slide-language-tab-item-${language}`">
                                    <v-text-field v-model="slide.button[language]" :label="'Button Text'"></v-text-field>
                                    <v-text-field v-model="slide.click[language]" :label="'Slide click'"></v-text-field>

                                    <v-card>
                                        <v-card-actions>
                                            <v-btn icon color="error" @click="removeSlide(slideIndex)"><v-icon>mdi-delete</v-icon></v-btn>
                                        </v-card-actions>
                                        <v-card-subtitle>
                                            HTML
                                        </v-card-subtitle>
                                        <v-card-text>
                                            <!-- <v-textarea v-model="slide.html[language]"/> -->
                                            <TinyEditor v-model="slide.html[language]" :init="tinyConfig.init" :api-key="tinyConfig.apiKey"/>
                                            <hr>
                                            <v-card>
                                                <v-card-subtitle>
                                                    Preview
                                                </v-card-subtitle>
                                                <v-card-text>
                                                    <div v-html="slide.html[language]"/>
                                                </v-card-text>
                                            </v-card>
                                        </v-card-text>
                                    </v-card>

                                    <v-card>
                                        <v-card-subtitle>
                                            Background Image
                                        </v-card-subtitle>
                                        <v-card-text>
                                            <v-text-field v-model="slide.image[language]"/>
                                            <p>Image Sizing Recommendations</p>
                                            <p><span style='margin-right:16px;'><strong>Image ratio rule:</strong> W 2.76x H</span> <strong>Rendered aspect ratio:</strong> 604:219</span></p>
                                            <Browser v-model="temp.slide.image[language]" @setBucket="setBucket" @selectAsset="selectAsset(slideIndex, language)"/>
                                        </v-card-text>
                                    </v-card>

                                    <!-- <v-card v-if="slide.image[language]">
                                        <v-card-subtitle>
                                            Background Image
                                        </v-card-subtitle>
                                        <v-card-text>
                                            <div>
                                                <img  :src="`${bucket}/${slide.image[language]}`" style="width:100%;"/>
                                            </div>
                                        </v-card-text>
                                    </v-card> -->
                                </v-tab-item>
                            </v-tabs-items>




                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </draggable>

            </v-expansion-panels>

            <!-- <v-card-actions>
                <v-spacer></v-spacer>
                    <v-btn @click="" icon>
                        <v-icon>
                            mdi-content-save
                        </v-icon>
                    </v-btn>
                <v-spacer></v-spacer>
            </v-card-actions> -->
        </v-card-text>
    </v-card>
</div>
</template>

<script>
import TinyEditor from '@tinymce/tinymce-vue'
import draggable from 'vuedraggable'
import Banner from '@/components/Banner/mainBanner/mainBanner.vue'
import Browser from '@/components/Assets/Manager'

export default {
    name: 'editMainBanner',
    props: {
        language: {
            type: String,
            required: true
        },
        languages: {
            type: Array,
            required: true
        }
    },
    components: {
        TinyEditor,
        Banner,
        draggable,
        Browser
    },
    created: function(){
        this.tinyConfig.apiKey = process.env.VUE_APP_TINYMCE_API_KEY
        let languages = this.languages
        function langs(){
            let output = {}
            for(let i=0; i<languages.length; i++){
                let language = languages[i]
                output[language] = null
            }
            return output
        }
        this.$set(this.temp.slide,'image',langs())

        let config = JSON.stringify(this.model)
        config = JSON.parse(config)
        if(!config || !config.slides){
            config.slides = []
        }

        for(let i=0; i<config.slides.length; i++){
            if(!config.slides[i].click){
                config.slides[i].click = langs()
            }
        }
        if(!config.cycle){
            config.cycle = {
                enabled: false,
                delay: 5
            }
        }
        this.config = config
        this.ready = true
        // this.save()
    },
    data: function(){
        return {
            ready: false,
            tinyConfig: {
                init: {
                    selector: 'textarea',
                    plugins: 'code fullscreen anchor autolink charmap codesample emoticons image media link lists searchreplace table visualblocks wordcount',
                    toolbar: 'customExitButton | undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat | fullscreen | code',
                    height: "800",
                    setup: function(editor){
                        // editor.ui.registry.addButton('customExitButton', {
                        //     text: 'Exit',
                        //     onAction: function (_) {
                        //         GuideEditor.exit()
                        //     }
                        // });
                        // editor.ui.registry.addButton('customSaveButton', {
                        //     text: 'Save',
                        //     onAction: function (_) {
                        //         GuideEditor.save()
                        //     }
                        // });
                        // editor.ui.registry.addButton('customPreviewButton', {
                        //     text: 'Preview',
                        //     onAction: function (_) {
                        //         // editor.execCommand('mceFullScreen');
                        //         GuideEditor.state.preview = true
                        //     }
                        // });
                        // editor.ui.registry.addButton('customGuideDetails', {
                        //     text: 'Guide Details',
                        //     onAction: function (_) {
                        //         // editor.execCommand('mceFullScreen');
                        //         GuideEditor.state.guideDetails = true
                        //     }
                        // });
                        // editor.ui.registry.addButton('customSelectTopicsButton', {
                        //     text: 'Select Topic(s)',
                        //     onAction: function (_) {
                        //         // editor.execCommand('mceFullScreen');
                        //         GuideEditor.state.selectTopics = true
                        //     }
                        // });

                        editor.on('init', function(e) {
                            // editor.execCommand('mceFullScreen');
                            // if(!GuideEditor.guide.meta.id){
                            //     GuideEditor.state.guideDetails = true
                            // }
                        });
                    }
                },
                apiKey: null
            },
            ui: {
                activeTab: 0
            },
            config: null,
            bucket: null,
            temp: {
                slide: {
                    index: null,
                    language: null
                }
            }
        }
    },
    methods: {
        setBucket: function(data){
            this.bucket = data
        },
        // save: function(){
        //     this.$emit('save',{definitionIndex:this.definitionIndex, config:this.config})
        // },
        selectAsset: function(slideIndex, language){
            this.temp.slide.index = slideIndex
            this.temp.slide.language = language
        },
        newSlide: function(){
            let languages = this.languages
            function template(languages){
                function langs(){
                    let output = {}
                    for(let i=0; i<languages.length; i++){
                        let language = languages[i]
                        output[language] = null
                    }
                    return output
                }
                let output = {
                    button: langs(),
                    html: langs(),
                    image: langs()
                }
                return output
            }
            let record = template(languages)
            this.config.slides.push(record)
        },
        removeSlide: function(slideIndex){
            this.config.slides.splice(slideIndex, 1)
        }
    },
    computed: {
        model: function(){
            return this.$attrs.value
        },
        tempSlide: function(){
            return this.temp.slide
        }
    },
    watch: {
        config: {
            deep: true,
            handler: function(){
                let slides = this.config.slides
                let cycle = this.config.cycle
                this.model.slides = slides
                this.model.cycle = cycle
            }
        },
        tempSlide: {
            deep: true,
            handler: function(){
                let tempSlide = this.temp.slide
                let slideIndex = tempSlide.index
                if(tempSlide.index!=null){
                    let targetSlide = this.config.slides[tempSlide.index]
                    let language = tempSlide.language
                    this.config.slides[slideIndex].image[language] = tempSlide.image[language].Key
                }
            }
        }
    }
}
</script>

<style>

</style>
