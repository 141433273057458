<template>
  <div>

    <v-btn @click="add">Add Card</v-btn>
    <hr>
    <v-expansion-panels v-model="panelIndex">
      <draggable v-model="config" style="width: 100%;">
        <v-expansion-panel v-for="(panel, panelIndex) in config.cards" :key="panelIndex">
            <v-expansion-panel-header>
                {{panel.params.title['en-ca'] ? panel.params.title['en-ca'] : 'No title'}}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-container>
                <v-row>
                  <v-col>
                      <!-- <v-list-item>
                        <template v-if="panel.params.banner && panel.params.banner.Key">
                          <v-list-item-avatar>
                            <v-img style="width:40px; height: 40px;" :src="`${$store.getters.bucket}/${panel.params.banner.Key ? panel.params.banner.Key : panel.banner}`"/>
                          </v-list-item-avatar>
                          <v-list-item-content>
                            {{panel.params.banner.Key}}
                          </v-list-item-content>
                        </template>
                        <v-list-item-action>
                          <v-btn @click="states.selectBanner=!states.selectBanner" small>Select Banner</v-btn>
                        </v-list-item-action>
                      </v-list-item>

                      <v-list-item>
                        <template v-if="panel.params.icon && panel.params.icon.Key">
                          <v-list-item-avatar>
                            <svg style="width:40px; height: 40px;" :data-src="`${$store.getters.bucket}/${panel.params.icon.Key ? panel.params.icon.Key : panel.icon}`"/>
                          </v-list-item-avatar>
                          <v-list-item-content>
                            {{panel.params.icon.Key}}
                          </v-list-item-content>
                        </template>
                        <v-list-item-action>
                          <v-btn @click="states.selectIcon=!states.selectIcon" small>Select Icon</v-btn>
                        </v-list-item-action>
                      </v-list-item> -->

                      <v-select :items="['en-ca','fr-ca']" label="Language" v-model="language"/>

                      <v-stepper v-model="ui.step" non-linear>
                        <v-stepper-header>
                          <v-stepper-step step="1" editable :complete="panel.params.banner[language]!=null">
                            Head Image
                          </v-stepper-step>
                          <v-stepper-step step="2" editable :complete="panel.params.icon[language]!=null">
                            Head Icon
                          </v-stepper-step>
                          <v-stepper-step step="3" editable>
                            Content
                          </v-stepper-step>
                        </v-stepper-header>

                        <v-steper-items>
                          <v-stepper-content step="1" complete editable>
                            <v-card v-if="panel.params.banner[language] && panel.params.banner[language].Key">
                              <v-card-subtitle>Preview</v-card-subtitle>
                              <v-card-text>
                                <v-img style="max-width: 400px;" :src="`${$store.getters.bucket}/${panel.params.banner[language].Key ? panel.params.banner[language].Key : panel[language].banner}`"/>
                                <br>{{panel.params.banner[language].Key}}
                              </v-card-text>
                            </v-card>
                            <br>
                            <p>Image Sizing Recommendations</p>
                            <p><span style='margin-right:16px;'><strong>Image ratio rule:</strong> W 1x H</span> <strong>Rendered aspect ratio:</strong> 360:360</span></p>
                            <Browser v-model="panel.params.banner[language]" @selectAsset="states.selectBanner=false"/>
                          </v-stepper-content>

                          <v-stepper-content step="2" complete editable>
                            <v-card v-if="panel.params.icon[language] && panel.params.icon[language].Key">
                              <v-card-subtitle>Preview</v-card-subtitle>
                              <v-card-text>
                                <svg style="width:60px; height: 60px;" :data-src="`${$store.getters.bucket}/${panel.params.icon[language].Key ? panel.params.icon[language].Key : panel[language].icon}`"/>
                                <br>{{panel.params.icon[language].Key}}
                              </v-card-text>
                            </v-card>
                            <Browser v-model="panel.params.icon[language]" @selectAsset="states.selectIcon=false"/>
                          </v-stepper-content>

                          <v-stepper-content step="3" complete editable>
                            <v-select v-model="panel.params.bg_color.theme" :items="_bgcolors" label="Background color"></v-select>
                            <v-switch v-model="panel.params.button.link.newTab" :true-value="true" :false-value="false" label="Open link in new tab"/>
                            <v-text-field v-model="panel.params.title[language]" :label="'Title'"/>
                            <v-text-field v-model="panel.params.subtitle[language]" :label="'Subtitle'"/>
                            <v-text-field v-model="panel.params.button.label[language]" :label="'Button'"/>
                            <v-text-field v-model="panel.params.button.link[language]" :label="'Button Target Link'"/>
                          </v-stepper-content>
                        </v-steper-items>
                      </v-stepper>




                      <!-- <v-select v-model="panel.params.icon" :items="_icons" label="Icon">
                        <template v-slot:selection="{ item }">
                            <v-icon>{{item.value}}</v-icon>
                        </template>


                        <template v-slot:item="{ active, item, attrs, on }">
                            <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
                                <v-list-item-action>

                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        <v-row no-gutters align="center">
                                            <span>{{ item.text }}</span>
                                            <v-spacer></v-spacer>
                                            <v-icon>{{item.value}}</v-icon>
                                        </v-row>
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </template>
                      </v-select> -->
                      <!-- <v-select v-model="panel.params.bg_color.theme" :items="_bgcolors" label="Background color"></v-select>
                      <v-switch v-model="panel.params.button.link.newTab" :true-value="true" :false-value="false" label="Open link in new tab"/> -->
                  </v-col>
                </v-row>
                <!-- <v-row>
                  <v-col cols="6" v-for="language in $store.getters.languages" :key="'panelConfig-'+language">
                    <h4>{{language}}</h4>
                    <v-text-field v-model="panel.params.title[language]" :label="'Title'"/>
                    <v-text-field v-model="panel.params.subtitle[language]" :label="'Subtitle'"/>
                    <v-text-field v-model="panel.params.button.label[language]" :label="'Button'"/>
                    <v-text-field v-model="panel.params.button.link[language]" :label="'Button Target Link'"/>
                  </v-col>
                </v-row> -->
              </v-container>
              <v-btn @click="remove(panelIndex)">Remove Card</v-btn>
            </v-expansion-panel-content>
        </v-expansion-panel>
      </draggable>
    </v-expansion-panels>
  </div>
</template>

<script>
import Browser from '@/components/Assets/Manager'
import draggable from 'vuedraggable'
export default {
  components: {
    draggable,
    Browser
  },
  props: {
    structure: {
      type: Object,
      required: true
    }
  },
  created: function(){
    let config = JSON.stringify(this.model)
    config = JSON.parse(config)
    // if(!config.length){
    //   config = []
    // }
    if(typeof config.cards == 'undefined'){
      config = {
        layout: {},
        cards: []
      }
    }
    this.config = config
    this.language = this.$store.getters.language
  },
  data: function(){
    return {
      language: null,
      ui: {
        step: 1
      },
      states: {
        selectIcon: false,
        selectBanner: false
      },
      config: {},
      panelIndex: null
    }
  },
  methods: {
    template: function(){
      return {
          params: {
            flex: 4,
            bg_color: {
              theme: 0,
              custom: null
            },
            icon: {},
            banner: {},
            title: {
              "en-ca": null,
              "fr-ca": null
            },
            subtitle: {
              "en-ca": null,
              "fr-ca": null
            },
            button: {
              label: {
                "en-ca": null,
                "fr-ca": null
              },
              link: {
                "en-ca": null,
                "fr-ca": null,
                "newTab": false
              }
            }
          }
      }
    },
    reset: function(){
      let output = [
        {
            // component: PageCard,
            params: {
              flex: 4,
              bg_color: {
                theme: 0,
                custom: null
              },
              icon: 'mdi-account-supervisor',
              title: 'MEET OUR LEADERSHIP TEAM',
              subtitle: 'Meet the 2022-2024 Leadership Team.',
              button: {
                label: 'Read More'
              }
            }
        },
        {
            // component: PageCard,
            params: {
              flex: 4,
              bg_color: {
                theme: 1,
                custom: null
              },
              icon: 'mdi-monitor',
              title: 'WATCH VTE & CANCER',
              subtitle: 'Patient information video on VTE and cancer.',
              button: {
                label: 'Watch here'
              }
            }
        },
        {
            // component: PageCard,
            params: {
              flex: 4,
              bg_color: {
                theme: 2,
                custom: null
              },
              icon: 'mdi-cellphone',
              title: 'GET THE APP',
              subtitle: 'Works offline in hospitals when you need it!',
              button: {
                label: 'Download now'
            }
            }
        }
      ]
      this.config = output
    },
    add: function(){
      this.config.cards.push(this.template())
    },
    remove: function(index){
      this.config.splice(index,1)
      this.model.splice(index,1)
    },
    // save: function(){
    //   this.$emit('save',{config:this.config, definitionIndex:this.definitionIndex})
    // }
  },
  computed: {
    model: function(){
      return this.$attrs.value
    },
    _bgcolors: function(){
      return [
        {
          text: 'Primary - A',
          value: 0
        },
        {
          text: 'Primary - B',
          value: 1
        },
        {
          text: 'Primary - C',
          value: 2
        }
      ]
    },
    _icons: function(){
      return [
        {
          text: 'mdi-account-supervisor',
          value: 'mdi-account-supervisor'
        },
        {
          text: 'mdi-monitor',
          value: 'mdi-monitor'
        },
        {
          text: 'mdi-cellphone',
          value: 'mdi-cellphone'
        }
      ]
    }
  },
    watch: {
        config: {
            deep: true,
            handler: function(){
                // this.save()
                let config = this.config
                for(let i in config){
                  this.model[i] = config[i]
                }
            }
        }
    }
}
</script>

<style>

</style>
