<template>

</template>

<script>
export default {
    created: function(){
        this.init()
    },
    data: function(){
        return {
            pins: {}
        }
    },
    methods: {
        init: async function(){
            let pins = await this.download()
            for(let key in pins){
                this.$set(this.pins, key, {})

                for(let id in pins[key]){
                    this.pins[key][id] = pins[key][id]
                }
            }

            this.$store.commit('pins', pins)            
            let controllers = this.$store.getters.controllers
            controllers.pins = this
            this.$store.commit('controllers',controllers)
        },
        get: function(){
            return this.pins
        },
        download: async function(){
            if(this.user){
                let response = await this.sendRequest('get','/api/pins')
                if(response.status==200){
                    return response.data
                }else{
                    console.error(response)
                }
            }else{
                return null
            }
        },
        pin: async function(key, item){
            let pins = this.pins

            if(this.user){
                if(!pins[key]){
                    this.$set(this.pins, key, {})
                }                
                let state = !this.isPinned(key, item)
                pins[key][item.id] = state
                this.$store.commit('pins', pins)
                await this.sendRequest('put','/api/pins',{key, item, state})
                this.$forceUpdate()
            }else{
                this.$router.push({path:'/sign_in'})
            }

        },
        isPinned: function(key, item){
            let pins = this.pins

            if(pins && pins[key]){
                return pins[key][item.id] === true
            }
            return false
        }  
    },
    computed: {
        user(){
            return this.$store.getters.user
        }
    },
    watch: {
        user(){
         if(this.user==undefined){
            console.log('Clearing PINS')
            this.pins = null
         }else{
            this.init()
         }
        }
    }
}
</script>

<style>

</style>