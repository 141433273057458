<template>
<v-container class="banner">
    <v-row>
        <v-col cols="12" sm="6" md="6" lg="6" class='theme-blue' style='padding:24px;'>
            <v-img :src="`${$store.getters.bucket}/${article.content.image}`"/>
        </v-col>

        <v-col cols="12" sm="6" md="6" lg="6" class="theme-blue" style='padding:24px;'>
            <strong>{{article.content.title[language]}}</strong>
            <center><hr class="small"></center>
            {{article.content.subtitle[language]}}
            <div style="margin: 25px; text-align: center;">
                <v-btn @click="$emit('readMore',article)"> READ MORE</v-btn>
            </div>
        </v-col>
    </v-row>
</v-container>
</template>

<script>
export default {
  props: {
    language: {
      type: String,
      required: true
    },
    article: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped lang="scss">
.banner{
  width: 100%;
}
.theme-blue{
  background-color: $primary;
  color: white;
  font-weight: bold;
}
strong{
  font-size: 1.8em;
  font-weight: bold;
}
.banner button{
  background-color: $tc-red !important;
  color: white;
}
</style>
