<template>
  <div style="text-align: left;">
    <v-btn icon @click="getAssets">
      <v-icon>mdi-reload</v-icon>
    </v-btn>    
    <!-- Upload initPath: {{ initPath }} -->
    <v-form v-model="validFolder">
        <div v-if="assets.length>0" style="text-align: left;">
            <Gallery v-if="ready" :assetTree="assetTree" :assets="assets" @selectBranch="selectFolder" @setPath="setPath" :initPath="initPath"/>
            <!-- <v-treeview dense hoverable v-if="ready" :items="assetTree" open-on-click item-key="shortCutKey" :open-all="true">
                <template v-slot:prepend="{ item, open }">
                    <v-icon>
                    mdi-folder-open
                    </v-icon>
                </template>

                <template v-slot:label="{item}">
                    <span v-html="item.Key"/>
                </template>

                <template v-slot:append="{ item }">
                    <v-btn text @click="selectFolder(item)">Select Folder</v-btn>
                </template>
            </v-treeview> -->
            <v-text-field v-model="form.folder" required :rules="rules.folder" :label="`Folder starting with /${base}/`"/>
        </div>
        
        
        <div v-if="form.folder && validFolder">
            Upload Asset to: {{form.folder}}
            <v-container>
                <v-row>
                    <v-col>
                        <v-file-input
                            label="Select Image"
                            show-size
                            filled
                            prepend-icon="mdi-camera"
                            accept="image/*"
                            :rules="rules.image"
                            v-model="form.image.file"
                            @change="setImage"
                        />
                        <div v-if="buffer.image.file" style="width: 400px; border: 1px solid grey;">
                            <v-img :src="buffer.image.file"/>
                            <!-- Tags separated by new line, comma or space
                            <template v-for="language in $store.getters.languages">
                                <v-text-field v-model="form.image.aria[language]" :label="'ARIA '+language+''" :key="'image_aria_'+language"/>
                                <v-textarea v-model="buffer.image.tags[language]" :label="'SEO '+language+''" @change="setTags('image')" :key="'image_tags_'+language"/>
                            </template> -->
                            <v-btn @click="uploadFile('image', form.folder, form.image.file, form.image.tags, form.image.aria)">Upload</v-btn>
                        </div>                        
                    </v-col>

                    <v-col>
                        <v-file-input
                            label="Select Document"
                            show-size
                            filled
                            accept=".doc,.docx,application/msword,.ppt,.pptx,application/vnd.ms-powerpoint,.xls,.xlsx,application/vnd.ms-excel,.pdf"
                            :rules="rules.document"
                            v-model="form.document.file"
                            @change="setDocument"
                        />
                        <v-card v-if="buffer.document.file">
                            <v-card-subtitle>Document</v-card-subtitle>
                            <!-- <v-card-text>
                                <div style="width: 400px; border: 1px solid grey;">
                                    <template v-for="language in $store.getters.languages">
                                        <v-textarea v-model="buffer.document.tags[language]" :label="'SEO '+language+'Tags separated by new line, comma or space'" @change="setTags('document')" :key="'document_tags_'+language" style="width: 48%; display: inline-block;"/>
                                    </template>
                                </div>
                            </v-card-text> -->
                            <v-card-actions>
                                <v-btn @click="uploadFile('document', form.folder, form.document.file, form.document.tags, form.document.aria)">Upload</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>






            <!-- <v-card v-else>
                <v-card-subtitle>Link</v-card-subtitle>
                <v-card-text>
                    <div v-for="language in $store.getters.languages" :key="'link_'+language" style="width: 48%; display: inline-block; border: 1px solid grey; padding: 3px;">
                        {{language}}
                        <v-text-field v-model="form.link.url[language]" label="URL"/>
                        <v-text-field v-model="form.link.label[language]" label="Label"/>
                        <v-textarea v-model="buffer.link.tags[language]" :label="'SEO '+language+'Tags separated by new line, comma or space'" @change="setTags('link')"/>
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-btn @click="uploadLink(form.link)">Upload</v-btn>                    
                </v-card-actions>
            </v-card> -->
        </div>
    </v-form>

    <!-- <json-viewer :duplicates="duplicates"/> -->

    <v-dialog v-model="states.uploading" persistent width="200">
        <v-card>
            <v-card-text>
                <div style="padding: 25px;">
                    <h3 align='center'>Uploading</h3>
                </div>
            </v-card-text>
        </v-card>
    </v-dialog>
  </div>
</template>
<script>
import axios from "axios";
import AssetBrowser from '@/components/Assets/Browser'
import Gallery from '@/components/Assets/Gallery'
export default {
    props: {
        base: {
            type: String,
            required: false,
            default: 'uploads'
        },
        endPoint: {
            type: String,
            required: false,
            default: '/api/admin/assets'
        },
        initPath: {
            type: Array,
            required: false,
            default: ()=>{return [0]}
        }
    },
    components: {
        AssetBrowser,
        Gallery
    },
    created: function(){
        this.getAssets()
    },
    data: function(){
        return {
            ready: false,
            validFolder: false,
            form: {
                image: {
                    file: null,
                    tags: {},
                    aria: {}
                },
                document: {
                    file: null,
                    tags: {},
                    aria: {}
                },
                link: {
                    url: {},
                    label: {},
                    tags: {},
                    aria: {}
                },
                folder: null
            },
            buffer: {
                image: {
                    file: null,
                    tags: {}
                },
                document: {
                    file: null,
                    tags: {}
                },
                link: {
                    url: {},
                    label: {},
                    tags: {}
                }
            },
            rules: {
                image: [
                    (value) => !value || value.size < 20000000 || 'Avatar size should be less than 20 MB!', 
                ],
                document: [
                    (value) => !value || value.size < 20000000 || 'Avatar size should be less than 20 MB!', 
                ],
                folder: [
                    value => !!value || 'Required',
                    value => this.validUploadFolder(value) || 'Must end with /',
                    value => this.validUploadRoot(value) || 'Must start with /uploads/',
                ]
            },
            assets: [],
            assetTree: [],
            bucket: null,
            states: {
                uploading: false
            },
            duplicates: []
        }
    },
    methods: {
        getUploadUrl: async function(){
            let file = this.form.document.file ? this.form.document.file : this.form.image.file ? this.form.image.file : null
            if(file!=null){
                let data = {
                    folder: this.form.folder,
                    fileName: file.name,
                    contentType: file.type
                }
                
                let response = await this.sendRequest('post','/api/admin/assets/preSignedUrl/upload', data)
                if(response.status==200){
                    return response.data.url
                }
            }
            return null
        },
        getAssets: async function(){
            this.ready = false
            let response = await this.sendRequest('get',this.endPoint)
            if(response.status==200){
                // this.assets = response.data.contents
                // this.bucket = response.data.bucket

                this.assets = response.data.contents
                this.bucket = response.data.bucket
                this.rebuildTree()                    
                this.setPath(this.initPath)
            }
            this.ready = true
        },
        rebuildTree: function(){
            this.assetTree = this.buildTree(this.assets, 'Key', '/' ,'children')
        },
        buildTree: function(flatArray, keyField, delimiter, childField)  {
            let tree = [
                {
                Key: this.base,
                children: []
                }
            ]
            
            function insertAsset(tree, node, keyField, delimiter, childField, debug){
                let stack = node.Key.replace(/\/$/, "").split(delimiter)
                let isFile = node.Size>0

                function branchPointer(array){
                let output = ""
                for(let i=0; i<array.length; i++){
                    let value = array[i]
                    output += `[${value}]['${childField}']`
                }
                return output
                }

                function assetPointer(array){
                let output = ""
                for(let i=0; i<array.length; i++){
                    let value = array[i]
                    if(i==array.length-1){
                    output += `[${value}]`
                    }else{
                    output += `[${value}]['${childField}']`
                    }
                }
                return output
                }

                function tracePointer(array, Key, trace){
                    let index = array.findIndex((item)=>{return item.Key==Key})
                    
                    if(index<0){
                        let record = {}
                        record[keyField] = Key,
                        record[childField] = []
                        array.push(record)
                        index = array.findIndex((item)=>{return item.Key==Key})
                    }
                    
                    trace.push(index)
                    }

                    let trace = []
                    for(let i=0; i<stack.length; i++){
                    let Key = stack[i]
                    let pointerString = branchPointer(trace)
                    let branch = eval(`tree${pointerString}`)
                    
                    tracePointer(branch, Key, trace)
                    if(i==stack.length-1){
                        pointerString = assetPointer(trace)
                        let target = eval(`tree${pointerString}`)
                        target.asset = node
                    }
                }
            }






            let index = {}
            //Add each S3 asset
            for(let i=0; i<flatArray.length; i++){
                let node = flatArray[i]

                let debugThis = [
                'uploads/hcp/education/cpd/Header-Program-940x126.jpg'
                ]
                let debug = debugThis.includes(node.Key)

                let isFile = node.Size && node.Key.indexOf('.')>=0
                
                if(node.Key.indexOf('.')>=0){
                if(typeof index[node.ETag] == 'undefined'){
                    index[node.ETag] = {
                    counter: 1,
                    file: {}
                    }
                }else{
                    index[node.ETag].counter++
                }      
                }

                insertAsset(tree, node, keyField, delimiter, childField, debug)
            }


            let duplicates = []
            for(let tag in index){
                let item = index[tag]
                if(item.counter>1){
                duplicates.push(item)
                }
            }

            this.duplicates = duplicates
            return tree

        },
        buildTree_og: function(flatArray, keyField, delimiter, childField)  {
            let tree = [
                {
                Key: this.base,
                children: []
                }
            ]
            
            function insertAsset(tree, node, keyField, delimiter, childField){
                function searchTree(tree, key){
                    let match = tree.filter((item)=>{return item[keyField]==key})

                    if(match.length==0){
                        for(let i=0; i<tree.length; i++){
                        let branch = tree[i]
                        let result = searchTree(branch[childField], key)
                        if(result){
                            return result
                        }
                        }
                    }

                    if(match.length==1){
                        return match[0]
                    }

                    return false

                }

                let keyString = node[keyField].replace(/\/$/, "")
                let stack = keyString.split(delimiter)
                let trace = []
                for(let depth=0; depth<stack.length; depth++){
                    let key = stack[depth]
                    let result = searchTree(tree, key, keyField)
                    if(result){                        
                        trace.push(result)
                    }else{
                        let record = {}
                        record[keyField] = key
                        record[childField] = []
                        if(depth==stack.length-1){
                            record.asset = node
                        }
                        trace[trace.length-1][childField].push(record)
                        insertAsset(tree, node, keyField, delimiter, childField)
                        break;
                    }
                }

            }

            function findNode(array, node, keyField, delimiter, childField, search=null, trace=null){
                let keyString = node[keyField].replace(/\/$/, "")
                let searchStack
                if(!search){
                    searchStack = keyString.split(delimiter)
                }else{
                    searchStack = search.searchStack
                }

                let result
                for(let stackDepth = search ? search.stackDepth+1 : 0; stackDepth<searchStack.length; stackDepth++){
                    let key = searchStack[stackDepth]
                    if(key.length>0){
                        for(let i=0; i<array.length; i++){
                        let record = array[i]
                        if(trace){
                            record.trace = trace
                        }
                        let recordKey = record[keyField]
                        if(recordKey == key){
                            if(stackDepth==searchStack.length-1){
                                return record
                            }else if(record.children){
                                result = findNode(record.children, node, keyField, delimiter, childField, {searchStack, stackDepth}, trace!=null ? `${trace}/${record.Key}` : `${record.Key}`)
                            }else{
                                return false
                            }
                        }
                        }
                    }
                }
                
                return result
            }


            //Add each S3 asset
            for(let i=0; i<flatArray.length; i++){
                let node = flatArray[i]
                let result = findNode(tree, node, keyField, delimiter, childField)
                // let shouldInsert = (node.Key.lastIndexOf('/') == node.Key.length-1)
                if(!result){
                    // console.log('insertAsset',{node: node.Key, ETag: node.ETag, result, keyField, delimiter, childField})
                    insertAsset(tree, node, keyField, delimiter, childField)
                }
            }

            console.log(tree)
            return tree

        },        
        validUploadRoot: function(folder){
            let split
            let trailingSlash
            if(folder){
                trailingSlash = folder.charAt(folder.length-1)=='/'
                split = folder.split(`/${this.base}/`)
            }
            let isValid = (folder && folder.length>0 && split.length>1 && split[0]=='' && trailingSlash)
            return isValid
        },
        validUploadFolder: function(folder){
            let trailingSlash
            if(folder){
                trailingSlash = folder.charAt(folder.length-1)=='/'
            }
            let isValid = (folder && folder.length>0 && trailingSlash)
            return isValid
        },
        resetImage: function(){
            this.buffer.image.file = null
            this.buffer.image.tags = null
            this.form.image.file = null
            this.form.image.tags = null
            this.form.image.area = null
        },
        setImage: function(event){
            // console.log('setImage',event)
            this.setBuffer(event, 'image')
        },
        setDocument: function(event){
            this.setBuffer(event, 'document')
        },
        setBuffer: function(event, type){
            let target = this.buffer[type]
            if(event && event.size>0){
                var reader = new FileReader();
                reader.onload = function(e){
                    target.file = reader.result
                }
                reader.readAsDataURL(event)
            }else{
                this.resetImage()
            }
        },
        setTags: function(type){
            let tagContainer = this.buffer[type].tags
            for(let language in tagContainer){
                let tags = tagContainer[language].split(/[\n,]+/)
                for(let i=0; i<tags.length; i++){
                    tags[i] = tags[i].trim()
                }
                this.form[type].tags[language] = tags

            }

        },
        uploadFile: async function(type, folder, input, tags, aria){
            var self = this;
            let file = this.form.document.file ? this.form.document.file : this.form.image.file ? this.form.image.file : null

            if(file){
                self.states.uploading = true
                const data = new FormData();
                data.append('tags', JSON.stringify(tags));
                data.append('aria', JSON.stringify(aria));
                data.append('file',input);
                data.append('folder', folder)
    
                let url = await this.getUploadUrl()
                if(url){
                    delete axios.defaults.headers['Authorization']
                    axios.defaults.headers['Content-Type'] = file.type
    
                    let response = await axios.put(url, file)
                    console.log('uploadFile',response)
                    self.states.uploading = false
                    if(response.status==200){
                        self.form[type].file = null
                        self.buffer[type].file = null

                        for(let i=0; i<this.languages.length; i++){
                            let language = this.languages[i]
                            self.form[type].tags[language] = []
                            self.form[type].aria[language] = null
                            self.buffer[type].tags[language] = null
                        }
                    }
                    self.$emit('refresh')
                    self.getAssets()
                }

            }

            // let response = await this.sendRequest('upload','/api/admin/assets/uploadFile',data)
            // console.log('response',{response, data})
            // if(response.status==200){
            //     if(response.data.uploadResult.ETag){
            //         self.form[type].file = null
            //         self.buffer[type].file = null

            //         for(let i=0; i<this.languages.length; i++){
            //             let language = this.languages[i]
            //             self.form[type].tags[language] = []
            //             self.form[type].aria[language] = null
            //             self.buffer[type].tags[language] = null
            //         }
            //     }
            // }
            // self.states.uploading = false
            // self.$emit('refresh')

        },
        uploadLink: async function(link){
            let response = await this.sendRequest('put','/api/admin/assets/uploadLink',{link, folder: this.form.folder})
        },
        // buildTree: function(pages){
        //     let language = this.language
        //     let items = generatePagesArray(pages)
        //     function generatePagesArray(pages, trace=null){
        //         let items = []

        //         for(let p in pages){
        //         let page = pages[p]
        //         let tracer = trace ? `${trace}/${p}` : `/${p}`
        //         let item = {
        //             label: page.name && page.name[language] ? '<b>'+page.name[language]+'</b>'+': '+tracer : tracer,
        //             name: tracer,
        //             shortCutKey: tracer ? tracer.substring(1).replaceAll('/','._folders.') : '',
        //             children: generatePagesArray(page._folders ? page._folders : [], tracer)
        //         }
        //         items.push(item)
        //         }

        //         items.sort((a, b) => {
        //             let fa = a.shortCutKey!=null && a.shortCutKey!=undefined && a.shortCutKey.length>0 ? a.shortCutKey.toLowerCase() : '',
        //                 fb = b.shortCutKey!=null && b.shortCutKey!=undefined && b.shortCutKey.length>0 ? b.shortCutKey.toLowerCase() : ''

        //             if (fa < fb) {
        //                 return -1;
        //             }
        //             if (fa > fb) {
        //                 return 1;
        //             }
        //             return 0;
        //         });

        //         return items
        //     }

        //     return items
        // },
        selectFolder: function(item){
            // console.log('selectFolder',item)
            // // this.form.folder = item.name+'/'
            // this.form.folder = item.trace ? `/${item.trace}/${item.Key}/` : `/${item.Key}/`
            this.setPath(this.initPath)
        },
        selectAsset: function(data){
            console.log('selectAsset',data)
        },
        setPath: function(path){
            let folder = ""
            let tree = this.assetTree
            let stack = []
            for(let i=0; i<path.length; i++){
                let index = path[i]
                let trace = `[${index}]['children']`//i==0 ? `[${index}]` : `[${index}]['children']`
                stack.push(trace)
            }

            let trace = ""
            for(let i=0; i<stack.length; i++){
                trace += stack[i]
                let nodeString = trace.substr(0,trace.lastIndexOf("['children']"))
                let node = eval(`tree${nodeString}`)
                folder += `/${node.Key}`
            }
            this.form.folder = `${folder}/`
            this.$emit('setPath',path)
        }
    },
    computed: {
        languages: function(){
            return this.$store.getters.languages
        },
        // _assets: function(){
        //     function template(){
        //         return {
        //             _folders: {},
        //             _assets: []
        //         }
        //     }
        //     let stats = {
        //         folders: 0,
        //         files: 0,
        //         size: 0,
        //         types: {}
        //     }
        //     let output = {
        //         uploads: template()
        //     }
        //     let assets = this.assets
            
        //     for(let i=0; i<assets.length; i++){
        //         let asset = assets[i]
        //         let trace = asset.Key.split('/')
        //         let isFolder = trace[trace.length-1]==''
        //         if(isFolder){
        //             stats.folders++
        //             let path = ''
        //             for(let t=0; t<trace.length-1; t++){
        //                 let value = trace[t]
        //                 let container = path+'.'+value
        //                 let target = eval("output"+container)
        //                 path += '.'+value+'._folders'
        //                 if(target==undefined){
        //                     console.log('creating container', container)
        //                     eval("output"+container+"=template()")
        //                 }
        //             }
        //         }
        //     }

            
        //     for(let i=0; i<assets.length; i++){
        //         let asset = assets[i]
        //         let trace = asset.Key.split('/')
        //         let isFolder = trace[trace.length-1]==''
        //         if(!isFolder){
        //             stats.files++
        //             stats.size+=asset.Size
        //             let parts = trace[trace.length-1].split('.')
        //             let type = parts[parts.length-1].toUpperCase()
        //             if(!stats.types[type]){
        //                 stats.types[type] = 0
        //             }else{
        //                 stats.types[type]++
        //             }
        //             let path = ''
        //             for(let t=0; t<trace.length-1; t++){
        //                 let value = trace[t]
        //                 let container = path+'.'+value
        //                 let target = eval("output"+container)
        //                 path += '.'+value+'._folders'
        //                 if(!target){
        //                     eval("output"+container+"=template()")
        //                     target = eval("output"+container)
        //                 }
        //                 target._assets.push(asset)
        //             }
        //         }
        //     }

        //     let tree = this.buildTree(output)

        //     return {
        //         stats,
        //         assets: output,
        //         tree
        //     }
        // },
        _duplicates: function(){
            let output = {}
            let assets = this.assets
            for(let i=0; i<assets.length; i++){
                let asset = assets[i]
                let trace = asset.Key.split('/')
                let isFolder = trace[trace.length-1]==''
                if(!isFolder && !output[asset.ETag]){
                    let matches = assets.filter((item)=>{return item.ETag==asset.ETag})
                    if(matches.length>1){
                        output[asset.ETag] = matches
                    }
                }
            }

            return output
        }
    }
}
</script>

<style>

</style>