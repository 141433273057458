<template>
  <div>
    <PageLanguage :page="_page" @language="setLanguage"/>
    <template v-for="(globalComponent, globalComponentIndex) in _globalComponents">
      <template v-if="globalComponent!='MainBody'">
        <div v-if="displayGlobalComponent(globalComponent)" :key="`global_${globalComponent}`">
          <div v-if="$store.getters.debug" style="text-align: left; color: red; font-weight: bold;">
            globalComponent: {{globalComponent}}
          </div>
          <component :is="globalComponent" :site="site" :page="_page" :config="_configMap[globalComponent]" :language="language"/>
        </div>
      </template>
      <template v-else>
        <template v-for="(pageComponent, pageCopmonentIndex) in _page.definition">
          <div :key="globalComponentIndex+'_'+pageCopmonentIndex">
            <div v-if="$store.getters.debug" style="text-align: left; color: red; font-weight: bold;">
              pageComponent: {{pageComponent.component}}
            </div>
            <component :is="pageComponent.component" :site="site" :page="_page" :config="pageComponent.config" :language="ui.language" @tags="emitTags"/>
            <div v-if="$store.getters.debug && !pageComponent.config" >
              <h5>Missing Config</h5>
              <json-viewer :value="pageComponent"/>
            </div>
          </div>
        </template>
      </template>
    </template>
  </div>
</template>

<script>
import PageLanguage from '@/components/PageLanguage/PageLanguage'
import Header from '@/components/Header/Header'
import Footer from '@/components/Footer/Footer'
import NavBar from '@/components/Nav/NavBar/NavBar'
import MainBanner from '@/components/Banner/mainBanner/mainBanner'
import PageBanner from '@/components/Banner/pageBanner/pageBanner'
import CustomNav from '@/components/Nav/customNav/customNav'
import PageNav from '@/components/Nav/pageNav/pageNav'
import CalloutCards from '@/components/Section/Pages/CalloutCards.vue'
import HighlightResources from '@/components/Section/Highlight/Resources.vue'
import Article from '@/components/Article/Article.vue'
import CTATwitter from '@/components/CTA/twitter.vue'
import NewsSection from '@/components/Section/News/NewsSection.vue'
import Stories from '@/components/Stories/StoriesCarousel.vue'
import TextBlock from '@/components/Section/TextBlock/TextBlock.vue'
import MediaBlock from '@/components/Section/MediaBlock/MediaBlock.vue'
import ClinicalTools from '@/components/ClinicalTools/ClinicalTools.vue'
import ClinicalGuides from '@/components/ClinicalGuides/ClinicalGuides.vue'
import Pins from '@/components/Account/Pins'
import Team from '@/components/Team/Team'


export default {
  props: {
    site: {
      type: Object,
      required: true
    }
  },
  // metaInfo: function(){
  //   return this.metaData
  // },
  components: {
    PageLanguage,
    Header,
    Footer,
    NavBar,
    MainBanner,
    CustomNav,
    PageBanner,
    CalloutCards,
    HighlightResources,
    Article,
    CTATwitter,
    NewsSection,
    Stories,
    TextBlock,
    MediaBlock,
    PageNav,
    ClinicalTools,
    ClinicalGuides,
    Pins,
    Team
  },
  data: function(){
    return {
      draggable: true,
      resizable: true,
      responsive: true,
      ui: {
        language: 'en-ca'
      }
    }
  },
  methods: {
    displayGlobalComponent: function(globalComponent){
      let _page = this._page
      let result = _page.hideGlobalComponents==undefined || !_page.hideGlobalComponents.includes(globalComponent)
      return result
    },
    get_lvl_1_component: function(component){
      if(this.$route.matched[0].path!=''){
        let site = this.site
        let path = this.$route.matched[0].path.replace(/\/$/, "");
        let trace = '.home'+path.replaceAll('/', this.$route.path=='/' ? '' : '._pages.')
        let page = eval(`site${trace}`)
        let pageNav = page.definition ? page.definition.filter((item)=>{return item.component==component}) : []
        return pageNav[0]
      }else{
        return false
      }      
    },
    emitTags: function(data){
      this.$emit('tags',data)
    },
    setLanguage: function(language){
      this.ui.language = language
    }
  },
  computed: {
    metaData: function(){
      return this.tags
    },
    language: function(){
      return this.$store.getters.language
    },
    _page: function(){
        return {
            definition: [
              {
                component: 'NavBar',
                config: {
                  inherit: true
                }
              },      
              {
                component: 'PageBanner',
                config: {
                  inherit: true
                }
              },             
              {
                  component: 'Article',
                  config: {}
              }
            ]
        }
    },
    _configMap: function(){
      let output = {}
      let definition = this._page.definition ? this._page.definition : []
      for(let i=0; i<definition.length; i++){
        let item = definition[i]
        output[item.component] = item.config
      }
      return output
    },
    _globalComponents: function(){
      return this.$store.getters.globalComponents
    }
  },
  watch: {
    _page: {
      deep: true,
      handler: function(){
        let language = this.language
        this.$emit('pageUpdate',{
          language,
          title: this._page.name,
          description: this._page.description ,
        })
      }
    }
  }
}
</script>

<style>
.vue-grid-item.vue-grid-placeholder {
    background: red;
    opacity: 0.2;
    transition-duration: 100ms;
}  
.vue-grid-item{
  border: 1px solid grey;
    z-index: 2;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
}
.grid::before {
    content: '';
    background-size: calc(calc(100% - 5px) / 12) 40px;
    background-image: linear-gradient(
            to right,
            lightgrey 1px,
            transparent 1px
    ),
    linear-gradient(to bottom, lightgrey 1px, transparent 1px);
    height: calc(100% - 5px);
    width: calc(100% - 5px);
    position: absolute;
    background-repeat: repeat;
    margin:5px;
}
</style>