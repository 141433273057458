<template>
  <div>
      <v-card>
        <v-card-subtitle>
          Mode: {{forms.mode}}
        </v-card-subtitle>
        <v-card-text>
          <v-tabs v-model="ui.tab">
            <v-tab v-for="language in languages" :key="`tab-${language}`">
              {{language}}
            </v-tab>            
          </v-tabs>

            <v-form ref="new_page" v-model="forms.valid">
              Path: {{structure.parent.name.replace('/home','')}} / <v-text-field v-model="forms.inputs.path" required :rules="rules.validPath" style="display:inline-block; width: 200px;"/>
              <v-tabs-items v-model="ui.tab">
                <v-tab-item v-for="language in languages" :key="`tab-item-${language}`">
                  <v-card>
                    <v-card-subtitle>
                      Friendly Name (shows up in: [sitemap & path bread crumb])
                    </v-card-subtitle>
                    <v-card-text>
                      <!-- <div style="display: inline-block; width: 50%;" v-for="language in $store.getters.languages" :key="'meta_'+language"> -->
                        <v-text-field outlined v-model="forms.inputs.meta.label[language]" :label="`Name: ${language}`" required :rules="rules.required" />
                        <v-textarea outlined v-model="forms.inputs.meta.description[language]" :label="`Description: ${language}`" required :rules="rules.required" />
                      <!-- </div> -->
                    </v-card-text>
                  </v-card>
                </v-tab-item>                    

              </v-tabs-items>
              <v-switch v-model="forms.inputs.meta.public" :true-value="true" :false-value="false" @change="setAllowed" label="Publicly Accessible"/>
              <v-switch v-model="forms.inputs.meta.blockSiteMap" :true-value="true" :false-value="false" label="Block from site mapping"/>
            </v-form>
          
        </v-card-text>

        <!-- <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :disabled="!forms.valid" @click="$emit('save')">
            <v-icon>mdi-content-save</v-icon>
          </v-btn>
        </v-card-actions> -->
      </v-card>
  </div>
</template>

<script>
export default {
  created: function(){
    console.log('routeForm - created', {structure: this.structure, forms: this.forms, nameType: typeof this.forms.inputs.name})
    if(!this.forms.inputs.meta.label){
      this.forms.inputs.meta.label = {}
    }
    if(!this.forms.inputs.meta.description){
      this.forms.inputs.meta.description = {}
    }
    // if(this.forms.inputs.description==undefined){
    //   this.forms.inputs.description = {}
    // }
     
  },
    props: {
        structure: {
            type: Object,
            required: true
        }
    },
    data: function(){
        return {
            ui: {
              tab: 0
            },
            rules: {
                required: [
                v => !!v || 'Required'
                ],
                validPath: [
                v => !!v || 'Path name is required',
                v => !this.checkPath() || 'Path name is in use',
                v => !this.checkCharacters() || 'Invalid character',
                v => !this.checkTail() || 'Invalid trailing character'
                ]
            }
        }
    },
    methods: {
        checkPath: function(){
            let test = {
                a: (this.structure.page._pages && this.structure.page._pages[this.forms.inputs.path]) ? true : false,
                b: this.structure.page && this.structure.page[this.forms.inputs.path] ? true : false
            }
            let exists = ( test.a || test.b ) ? true : false
            return exists
        },
        checkCharacters: function(){
          let path = this.forms.inputs.path
          if(path && path.length>0){
            let bad = ['/','\\']
            for(let i=0; i<bad.length; i++){
              let character = bad[i]
              if(path.indexOf(character)>0){
                return true
              }
            }
          }
          return false
        },
        checkTail: function(){
          let path = this.forms.inputs.path
          if(path && path.length>0){
            let bad = ['.','-','/','_']
            for(let i=0; i<bad.length; i++){
              let character = bad[i]
              if(path.lastIndexOf(character)==path.length-1){
                return true
              }
            }
          }
          return false
        },
        setAllowed: function(){
            console.log('setAllowed')
            this.forms.inputs.meta.allowed = this.forms.inputs.meta.public ? '*' : ['SuperAdmin','admin','staff','tester']
        }
    },
    computed: {
        forms: function(){
            return this.$attrs.value
        },
        languages: function(){
          return this.$store.getters.languages
        }
    }
}
</script>

<style>

</style>