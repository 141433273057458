<template>
  <div class="clinical-tools" style="margin-top: 50px;" @click="track">
    <!-- <div style="display: block; border: 1px solid red;">
        <div class="summary">
          <table class="summary">
            <tr>
              <td class="label">Bleeding Risk</td>
              <td class="value">Moderate (2-day risk of major bleed 0-2%)</td>
            </tr>

            <tr>
              <td class="label">Anticoagulant</td>
              <td class="value">Apixaban</td>
            </tr>

            <tr>
              <td class="label">CrCl</td>
              <td class="value">20.3 mL/min</td>
            </tr>

            <tr>
              <td class="label">Indication For Antithrombotic</td>
              <td class="value">DVT/PE</td>
            </tr>

            <tr>
              <td class="label">Thromboembolic Risk</td>
              <td class="value">High</td>
            </tr>

          </table>
        </div>
    </div> -->



    <!-- <v-select v-model="language" :items="[{text:'English', value:'en-ca'},{text:'French', value:'fr-ca'}]" label="Language"/> -->
    <Title :title="'Clinical Tools'"/>
    <div id="calcContainer">
        <input id="language" type="hidden" v-model="language">
      <form onsubmit="return false;" style="display:none;">
        <select id="calcDropdown" onchange="selectCalc(this.value)"></select>
      </form>

      <v-container style="padding:6px;">
        <v-row>
          <v-col cols="12" sm="3" md="3" lg="3">
            <div id="calcMenu" class="calcMenu">
              <a id="calcMenuButton" href="javascript:toggleMenu()">Menu</a>
              <div id="calcMenuContents">


                <h2 @click="toggleGroup('calcAlgorithmItem')" class='header_calcAlgorithmItem'>
                    <span v-if="language==='fr-ca'">Algorithmes</span><span v-else>Algorithms</span> <v-icon>mdi-chevron-down</v-icon>
                </h2>
                <a href="javascript:void(0);" id="calcAlgorithmItem" class="$selectCalcFromMenu menuGroup calcAlgorithmItem" postrender="initMenuItem">
                  <span :class="getClassName"></span>
                </a>

                <h2 @click="toggleGroup('calcAcuteManagementItem')" class='header_calcAcuteManagementItem'>
                    <span v-if="language==='fr-ca'">Prise en charge en phase aiguë</span><span v-else>Acute Management</span> <v-icon>mdi-chevron-down</v-icon>
                </h2>
                <a href="javascript:void(0);" id="calcAcuteManagementItem" class="$selectCalcFromMenu menuGroup calcAcuteManagementItem" postrender="initMenuItem">
                  <span :class="getClassName"></span>
                </a>
                  
                <h2 @click="toggleGroup('calcChecklistsItem')" class='header_calcChecklistsItem'>
                    <span v-if="language==='fr-ca'">Listes de vérification</span><span v-else>Checklists</span> <v-icon>mdi-chevron-down</v-icon>
                </h2>
                <a href="javascript:void(0);" id="calcChecklistsItem" class="$selectCalcFromMenu menuGroup calcChecklistsItem" postrender="initMenuItem">
                  <span :class="getClassName"></span>
                </a>
                
                <h2 @click="toggleGroup('calcCalculatorItem')" class='header_calcCalculatorItem'>
                    <span v-if="language==='fr-ca'">Calculateurs</span><span v-else>Calculators </span> <v-icon>mdi-chevron-down</v-icon></h2>
                <a href="javascript:void(0);" id="calcCalculatorItem" class="$selectCalcFromMenu menuGroup calcCalculatorItem" postrender="initMenuItem">
                  <span :class="getClassName"></span>
                </a>

              </div>
            </div>
          </v-col>

          <v-col cols="12" sm="9" md="9" lg="9">
            <div id="calcScripts" style="display: none;"/>
            <Calculators v-if="state.websiteCalc" :language="language" :activeCalc="calc" @reset="$emit('reset')"/>
          </v-col>
        </v-row>
        <sup id="version" v-if="version" style="color: lightgrey;">
          Version: {{version}}
        </sup>
      </v-container>

    </div>

</div>
</template>

<script>
require('./calc/calc_v2.css')
require('./calc/websiteCalc.css')

require('./lib/jquery-1.8.3.js')
require('./lib/jkarma-0.23.js')

require('./lib/jspdf_v1_5_3.min.js')
require('./lib/html2canvas_v1_4_1.min.js')


// require('./lib/xstate.js')
// require('./lib/utils.js')
// require('./calc/calc_website.js')
// require('./calc/websiteCalc_v3.js')
import Calculators from './calculators.vue'
import Title from '@/components/Section/title.vue'
export default {
  mounted: function(){
    let self = this    
    let bucket = process.env.NODE_ENV=='development' ? 'http://localhost:8080' : window.location.origin //this.$store.getters.bucket
      // jQuery.ajax(bucket+'/clinical_tools/calc/calc_tester.js',{ 
      jQuery.ajax(bucket+'/clinical_tools/calc/calc_website.js',{ //--- confirm this is good to use ---
				success: function(response) {
					var parent = $('#calcScripts');
					parent.append(response);
          self.state.calcJS = true
				},
				cache: false,
				type: 'GET',
				dataType: 'script'
      })

      jQuery.ajax(bucket+'/clinical_tools/calc/websiteCalc_v3.js',{
        success: function(response) {
          var parent = $('#calcScripts');
          parent.append(response);
          self.state.websiteCalc = true
        },
        cache: false,
        type: 'GET',
        dataType: 'script'
      });

      jQuery.ajax(bucket+'/clinical_tools/calc/utils.js',{
				success: function(response) {
					var parent = $('#calcScripts');
					parent.append(response);
          self.state.utils = true
				},
				cache: false,
				type: 'GET',
				dataType: 'script'
      })

      jQuery.ajax(bucket+'/clinical_tools/calc/jspdf_v1_5_3.min.js',{
        success: function(response) {
          var parent = $('#calcScripts');
          parent.append(response);
          self.state.jsPDF = true
        },
        cache: false,
        type: 'GET',
        dataType: 'script'
      });

      // jQuery.ajax(bucket+'/clinical_tools/calc/xstate.js',{
      //   success: function(response) {
      //     var parent = $('#calcScripts');
      //     parent.append(response);
      //     self.state.xstate = true
      //   },
      //   cache: false,
      //   type: 'GET',
      //   dataType: 'script'
      // });

      jQuery.ajax(bucket+'/clinical_tools/calc/html2canvas_v1_4_1.min.js',{
        success: function(response) {
          var parent = $('#calcScripts');
          parent.append(response);
          self.state.html2canvas = true
        },
        cache: false,
        type: 'GET',
        dataType: 'script'
      });

      // jQuery.ajax(bucket+'/clinical_tools/calc_tester.html',{
      //   success: function(response) {
      //     var parent = $('#calcContainer');
      //     parent.append(response);
      //     self.state.html = true
      //     // var form = $(response).find('form');
      //     // $('#calcContainer').append(form);

      //     // initCalcs();

      //     // $('#version').html("Version " + calc.version);

      //     // debugCalc();

      //     // selectCalc('chads2vasc');
      //     // selectCalc('antithromboticAlgorithm');
      //     // selectCalc('cockcroft');
      //     // selectCalc('pesi');
      //     // selectCalc('simplifiedPesi');
      //     // selectCalc('periopAnticoagulantAlgorithm');
      //     // selectCalc('has-bled');


      //     /*
      //     $('[name=antithromboAlgAge]').val(66);
      //     $('[name=antithromboAlgWeight]').val(50);
      //     $('[name=antithromboAlgSerumCreatinine]').val(157);
      //     */

      //   },
      //   cache: false,
      //   type: 'GET',
      //   dataType: 'html'
      // });

      self.isMounted = true;
  },
  created: function(){
    // interval + delay to check ifMounted before setting accordion state
    let self = this;
    this.checkIsMountedInterval = setInterval(function(){
        if( self.isMounted == true){
            let delay = setTimeout(function(){
                self.toggleGroup(localStorage.getItem("clinicalToolsAccordionState"));
                clearInterval(self.checkIsMountedInterval);
                self.checkIsMountedInterval = null;
            },100);
        };
    },10);
  },
  components: {
    Calculators,
    Title
  },
  props: {
    language: {
      type: String,
      required: true,
      default: "en-ca"
    }
  },
  data: function(){
    return {
      version: null,
      state: {
        calcJS: false,
        websiteCalc: false,
        // html: false,
        utils: false,
        // xstate: false,
        jsPDF: false,
        html2canvas: false
      },
      isMounted: false,
      checkIsMountedInterval: null,
      calc: null
    }
  },
  methods:{
    toggleGroup: function(clicked){
      let show = document.getElementsByClassName(clicked)
      let hide = document.getElementsByClassName('menuGroup')

      // console.log('toggleGroup',{clicked, show, hide})
      for(let i=0; i<hide.length; i++){
        let elem = hide[i]
        elem.style.display = "none"
      }
      for(let i=0; i<show.length; i++){
        let elem = show[i]
        elem.style.display = "block"
      }

      let headersIcons = document.querySelectorAll('h2[class^="header_"] > .v-icon');
      for(let i = 0; i < headersIcons.length; i++){
        let headerIcon = headersIcons[i];
        headerIcon.classList.remove('mdi-chevron-down');
        headerIcon.classList.add('mdi-chevron-right');

        // end of loop, add chevron-down icon to clicked one
        if( i === headersIcons.length -1){
          let clickedHeaderIcon = document.querySelector(`.header_${clicked} > .v-icon`);
          clickedHeaderIcon.classList.remove('mdi-chevron-right');
          clickedHeaderIcon.classList.add('mdi-chevron-down');

          // add 'clicked' to localStorage, for retrieve accordion state on refresh/revisit
          localStorage.setItem('clinicalToolsAccordionState', clicked);
        };
      };
    },
    track: function(){
      // let calc = this.$route.query.calc
      let calc = window.location.href.split('?')[1] ? window.location.href.split('?')[1].split('calc=')[1] : 'antithromboticAlgorithm'
      // let calc = document.querySelector('a.menuGroup.selected').text
      if(this.calc != calc){
        this.calc = calc
          let data = {
              log_key: document.querySelector('a.menuGroup.selected').text.trim(),
              tool: 'website',
              section: 'Clinical Tools'
          }
          this.sendRequest('put',`/api/tracker`, data)
      }
    }
  },
  computed: {
    query: function(){
      return this.$route.query
    },
    getClassName(){
        return this.language==="fr-ca" ? '$name_fr_ca' : '$name'
    }
  },
  watch: {
    state: {
      deep: true,
      handler: function(){
        let state = this.state
        let ready = true
        for(let key in state){
          if(!state[key]){
            ready = false
          }
        }

        if(ready){
          initCalcs(this.language)
          this.version = calc.version
          $('#version').html("Version " + calc.version);
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">

.clinical-tools{

    > .v-input {
        max-width: 900px;
        margin: 40px auto 0;
        @media(max-width: 932px){
            margin: 16px 15px 0;
        }
    }
}
</style>

<style lang="scss">
.calcDescription, #calcMenuContents h2{
  background-color: $primary-1;
  color: white;
  cursor: pointer;
  position: relative;
  width: 100%;

  .v-icon {
      position: absolute;
      right: 8px;
      color:white;
  }
}

#calcMenuContents a{
  color: $functional;
  background-color: $secondary-light-2;
  border-bottom: 1px solid lightgrey;
}

#calcMenuContents a.selected{
  color: $red;
  background-color: white;
}

.calcMenu {
    width: initial !important;
}

#calcMenuButton {
    background-color: $primary-1;
    font-weight: bold;
    margin-bottom: -8px;
}

#calcMenuContents {
    @media(max-width: 767px){
        margin-top: 8px;
    }
}
#calcWrapper {
    a {
        color: white;
        font-style: italic;
    }
}
</style>


<style type="text/css">
  table.summary {
    width:320px;
    border-collapse: collapse;
    display: none;
  }
  table.summary td {
    vertical-align: top;
    border-bottom: 1px solid #eee;
    /*
    */

  }
  table.summary td.value {
    font-weight: 400;
  }
  table.summary td.label {
    text-align: right;
    padding-right: 10px;
    color: #666;
  }
  /*
  table.summary tr:nth-child(odd) {
    background: #eee;
  }
  */

</style>

<!--
<style type="text/css">
        table.summary {
                width:320px;
                border-collapse: collapse;
                display: none;
        }
        table.summary td {
                vertical-align: top;
                border-bottom: 1px solid #eee;
                /*
                */

        }
        table.summary td.value {
                font-weight: 400;
        }
        table.summary td.label {
                text-align: right;
                padding-right: 10px;
                color: #666;
        }
        /*
        table.summary tr:nth-child(odd) {
                background: #eee;
        }
        */

</style>
-->