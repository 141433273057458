<template>
  <div>    
    <v-container>
        <v-row>
            <v-col>Admin</v-col>
            <v-col>Users</v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-list dense>
                    <template v-for="user in groupUsers.admin">
                        <v-list-item :key="`admin_${user.Username}`">
                            <v-list-item-action>
                                <v-btn text v-if="isAdmin(user.Username)" @click="makeUser(user)">Make User</v-btn>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>
                                    {{fullName(user.Attributes)}}
                                    <template v-if="user.UserStatus=='EXTERNAL_PROVIDER'">
                                        - ({{resolveProvider(user.Username)}})
                                    </template>
                                </v-list-item-title>
                                <v-list-item-subtitle>{{email(user.Attributes)}} - {{user.UserStatus}}</v-list-item-subtitle>
                                <!-- {{new Date(user.UserCreateDate)}} -->
                            </v-list-item-content>
                        </v-list-item>
                    </template>
                </v-list>
            </v-col>
            <v-col>
                <v-list dense>
                    <template v-for="user in users">
                        <v-list-item :key="`user_${user.Username}`">
                            <v-list-item-content>
                                <v-list-item-title>
                                    {{fullName(user.Attributes)}}
                                    <template v-if="user.UserStatus=='EXTERNAL_PROVIDER'">
                                        - ({{resolveProvider(user.Username)}})
                                    </template>
                                </v-list-item-title>
                                <v-list-item-subtitle>{{email(user.Attributes)}} - {{user.UserStatus}}</v-list-item-subtitle>
                                <!-- {{new Date(user.UserCreateDate)}} -->
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-btn text v-if="!isAdmin(user.Username)" @click="makeAdmin(user)">Make Admin</v-btn>
                            </v-list-item-action>
                        </v-list-item>
                    </template>
                </v-list>
            </v-col>
        </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
    created: function(){
        this.getUsers()
    },
    data: function(){
        return {
            states: {
                ready: false
            },
            users: [],
            groups: [],
            groupUsers: []
        }
    },
    methods: {
        async getUsers(){
            let response = await this.sendRequest('get',`/api/admin/users`)
            if(response.status==200){
                let data = response.data
                for(let key in data){
                    this[key] = data[key]
                }
            }
            console.log('getUsers', response)
        },
        fullName: function(attributes){
            let given_name = attributes.filter((item)=>{return item.Name=='given_name'})
            let family_name = attributes.filter((item)=>{return item.Name=='family_name'})
            return `${given_name[0] ? given_name[0].Value : '-'} ${family_name[0] ? family_name[0].Value : '-'}`
        },
        email: function(attributes){
            let email = attributes.filter((item)=>{return item.Name=='email'})
            return email[0].Value
        },
        resolveProvider: function(username){
            let isGoogle = username.indexOf('google_') == 0
            let isFacebook = username.indexOf('facebook_') == 0
            if(isGoogle){ return 'Google'}
            if(isFacebook){ return 'Facebook'}
        },
        isAdmin: function(username){
            let admins = this.groupUsers.admin
            return admins.filter((user)=>{return user.Username == username}).length>0
        },
        async makeAdmin(user){            
            let response = await this.sendRequest('post',`/api/admin/users/makeAdmin`, user)
            console.log('makeAdmin', {user, response})
            this.getUsers()

        },
        async makeUser(user){            
            let response = await this.sendRequest('post',`/api/admin/users/makeUser`, user)
            console.log('makeUser', {user, response})
            this.getUsers()

        }
    }
}
</script>

<style>

</style>