<template>
<div>
    <ComponentForm v-model='form.component' :languages="languages">
        <template v-slot:actionButtons>
            <v-btn small @click="addItem">Add Component</v-btn>
        </template>
    </ComponentForm>

    <grid-layout :layout.sync="grid.layout"
                 :col-num="12"
                 :row-height="30"
                 :is-draggable="grid.draggable"
                 :is-resizable="grid.resizable"
                 :vertical-compact="true"
                 :use-css-transforms="true"
    >
        <grid-item v-for="(item, itemIndex) in grid.layout"
            :static="item.static"
            :x="item.x"
            :y="item.y"
            :w="item.w"
            :h="item.h"
            :i="item.i"
            :key="`grid-item-${itemIndex}`"
        >
            {{item.component.component}}
            <v-btn small icon @click="editComponent(item.i)" style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);">
                <v-icon>
                    mdi-pencil
                </v-icon>
            </v-btn>

            <v-btn small icon @click="removeItem(item.i)" style="position: absolute; top: 0px; right: 0px;">
                <v-icon>
                    mdi-delete
                </v-icon>
            </v-btn>
        </grid-item>
    </grid-layout>
    <!-- <v-btn small @click="ui.preview=true">
        Preview
    </v-btn>

    <v-dialog v-model="ui.preview">
        <v-card v-if="ui.preview">
            <v-card-subtitle>
                Preview
            </v-card-subtitle>
            <v-card-text>
                <Grid :config="{layout:grid.layout}" :language="this.$store.getters.language"/>
            </v-card-text>
            <v-card-actions>
                <v-btn text @click="ui.preview = false">
                    Close
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog> -->

    <v-dialog v-model="ui.edit" style="z-index: 100;">
        <ComponentForm v-if="editing" v-model='grid.layout[edit].component' :languages="languages">
            <template v-slot:actionButtons>
                <v-btn @click="ui.edit=false">
                    Close
                </v-btn>
            </template>
        </ComponentForm>
    </v-dialog>
</div>
</template>

<script>
import { GridLayout, GridItem } from "vue-grid-layout"
// import Grid from '@/components/Grid/ImageGrid'
import ComponentForm from './ComponentForm.vue'

export default {
    components: {
        GridLayout,
        GridItem,
        // Grid,
        ComponentForm
    },
    created: function(){
        this.initComponentForm()
        if((this.$attrs.value && this.$attrs.value.layout)){
            let layout = JSON.stringify(this.$attrs.value.layout)
            this.grid.layout = JSON.parse(layout)
        }
    },
    data() {
        return {
            config: {
                layout: []
            },
            grid: {
                layout: [],
                draggable: true,
                resizable: true
            },
            edit: null,
            undo: null,
            ui: {
                edit: false,
                preview: false,
                component: false
            },
            form: {
                component: null
            }
        }
    },
    methods: {
        initComponentForm: function(){
            this.form.component = this.componentTemplate()
        },
        componentTemplate: function(){
            let form = {
                component: null,
                config: {}
            }
            return form
        },
        addItem: function () {
            function assignID(layout){
                let keys = layout.map(item=>parseInt(item.i,10))
                keys.sort(function(a, b){return a-b})
                for(let i=0; i<keys.length; i++){
                    let key = keys[i]
                    if(key!=i){
                        return i
                    }
                }
                return keys.length
            }
            let component = JSON.stringify(this.form.component)
            component = JSON.parse(component)

            this.grid.layout.push({
                x: (this.grid.layout.length * 3) % (this.colNum || 12),
                y: this.grid.layout.length + (this.colNum || 12), // puts it at the bottom
                w: 3,
                h: 5,
                i: assignID(this.grid.layout),
                component
            })
            this.initComponentForm()
        },
        removeItem: function (val) {
            const index = this.grid.layout.map(item => item.i).indexOf(val);
            this.grid.layout.splice(index, 1);
        },
        editComponent: function (val) {
            const index = this.grid.layout.map(item => item.i).indexOf(val);
            let target = this.grid.layout[index]
            console.log('edit',target.component)
            // this.undo = JSON.stringify(target)
            // this.undo = JSON.parse(this.undo)
            // let value = JSON.stringify(target)
            // this.form.component = JSON.parse(value)
            this.edit = index
            this.ui.edit = true
        },
        save: function(){
            this.config.layout = this.grid.layout
            this.$emit('input',this.config)
        }
    },
    computed: {
        language: function(){
            return this.$store.getters.language
        },
        languages: function(){
            return this.$store.getters.languages
        },
        editing: function(){
            if(this.edit!=null){
                return this.grid.layout[this.edit]
            }
            return null
        }
    },
    watch: {
        grid: {
            deep: true,
            handler: function(){
                this.save()
            }
        }
    }
}
</script>

<style scoped>
.vue-grid-layout {
    background: #eee;
}

.vue-grid-item:not(.vue-grid-placeholder) {
    background: #ccc;
    border: 1px solid black;
}

.vue-grid-item .resizing {
    opacity: 0.9;
}

.vue-grid-item .static {
    background: #cce;
}

.vue-grid-item .text {
    font-size: 24px;
    text-align: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: 100%;
    width: 100%;
}

.vue-grid-item .no-drag {
    height: 100%;
    width: 100%;
}

.vue-grid-item .minMax {
    font-size: 12px;
}

.vue-grid-item .add {
    cursor: pointer;
}

.vue-draggable-handle {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 0;
    left: 0;
    background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='10'><circle cx='5' cy='5' r='5' fill='#999999'/></svg>") no-repeat;
    background-position: bottom right;
    padding: 0 8px 8px 0;
    background-repeat: no-repeat;
    background-origin: content-box;
    box-sizing: border-box;
    cursor: pointer;
}

.tox.tox-tinymce-aux {
  z-index: 4000;
}
</style>