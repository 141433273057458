<template>
    <v-card flat>
        <v-card-title>
            Category Manager
        </v-card-title>

        <v-card-text>
            <v-card flat v-if="!record">
                <v-card-text>
                    <v-list dense>
                        <v-list-item v-for="(category, categoryKey) in model" :key="`category-list-item-${category.key}`">
                            <v-list-item-content>
                                <v-list-item-title>
                                    [{{category.key}}] - {{category.label[language]}}
                                </v-list-item-title>                    
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-btn icon small @click="editCategory(categoryKey)">
                                    <v-icon>
                                        mdi-pencil
                                    </v-icon>
                                </v-btn>
                            </v-list-item-action>
                        </v-list-item>
                    </v-list>
                    <v-btn @click="newCategory">New Category</v-btn>                    
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="$emit('close')">Close</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>                
            </v-card>

            <v-card flat v-if="record">
                <v-card-text>
                    <v-text-field v-model="record.key" label="Category Key"/>
                    <v-container>
                        <v-row>
                            <v-col v-for="language in languages" :key="`category-form-${language}`">
                                <v-text-field v-model="record.label[language]" label="Label"/>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-btn @click="cancel">Cancel</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn :disabled="!keyConflict" @click="deleteRecord">Delete</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn @click="save">Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    props: {
        languages: {
            type: Array,
            required: true
        },
        language: {
            type: String,
            required: true
        }
    },
    data: function(){
        return {
            editing: null,
            form: {
                category: null
            }
        }
    },
    methods: {
        categoryTemplate: function(){
            let languages = this.languages
            let output = {
                key: null,
                label: {}
            }
            for(let i=0; i<languages.length; i++){
                let language = languages[i]
                output.label[language] = null
            }

            return output
        },
        newCategory: function(){
            this.editing = null
            this.form.category = this.categoryTemplate()
        },
        editCategory: function(index){
            this.editing = index
            let category = JSON.stringify(this.model[index])
            category = JSON.parse(category)
            this.form.category = category
        },
        cancel: function(){
            this.editing = null
            this.form.category = null
        },
        save: async function(){
            let record = JSON.stringify(this.record)
            record = JSON.parse(record)
            let editing = this.editing
            let response
            if(editing!=null){
                response = await this.sendRequest('patch','/api/admin/resources/category', record)
                // this.model[editing] = record
            }else{
                response = await this.sendRequest('put','/api/admin/resources/category', record)
                // this.model.push(record)
            }
            console.log(response)
            this.model[record.key] = record
            this.form.category = null
            this.$emit('refresh')
        },
        deleteRecord: async function(){
            let record = this.form.category
            let response = await this.sendRequest('delete','/api/admin/resources/category', record)
            console.log(response)
            this.editing = null
            this.form.category = null
            delete this.model[record.key]
            this.$emit('refresh')
        }
    },
    computed: {
        model: function(){
            return this.$attrs.value
        },
        record: function(){
            return this.form.category
        },
        keyConflict: function(){
            return this.model[this.form.category.key] ? true : false
        }
    }
}
</script>

<style>

</style>