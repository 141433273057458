import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    Authorizer: undefined,
    Disclaimer: undefined,
    siteBlocker: false,
    debug: false,
    language: 'en-ca',
    languages: ['en-ca','fr-ca'],
    labels: undefined,
    mainNavOpen: false,
    exposeLabels: false,
    width: null,
    height: null,
    pathAfterLogin: null,
    inactivity: 0,
    timezone: null,
    user: undefined,
    userID: 'guest',
    signedIn: false,
    appVisible: true,
    globalComponents: [
      'Header',
      'MainBody',
      'Footer'
    ],
    assets: null,
    bucket: null,
    componentConfigurations: null,
    resources: null,
    flexSearch: {
      searchBar: null,
      siteDocument: null,
      guidesDocument: null,
      articlesDocument: null,
      storiesDocument: null,
      resourcesDocument: null
    },
    clinicalGuideData: null,
    articles: null,
    stories: [],
    redirects: [],
    site: null,
    contact: {
      address: {
        street: '128 Halls Road',
        city: 'Whitby',
        province: 'ON',
        country: 'Canada',
        postal_code: 'L1P 1Y8',
        gmap: 'https://goo.gl/maps/ei1rnJV52op7K4Yu6'
      },
      phone: '+1 647-528-8586',
      email: 'info@thrombosiscanada.ca',
      charity_number: '843620477 RC0001',
    },
    pins: {},
    controllers: {},
    fbid: 3338402923051209
  },
  getters: {
    Authorizer: function(state){
      return state.Authorizer
    },
    contact: function(state){
      return state.contact
    },
    Disclaimer: function(state){
      return state.Disclaimer
    },
    globalComponents: function(state){
      return state.globalComponents
    },
    pathAfterLogin: function(state){
      return state.pathAfterLogin
    },
    debug: function(state){
      return state.debug
    },
    language: function(state){
      return state.language
    },
    languages: function(state){
      return state.languages
    },
    labels: function(state){
      return state.labels
    },
    mainNavOpen: function(state){
      return state.mainNavOpen
    },
    width: function(state){
      return state.width
    },
    height: function(state){
      return state.height
    },
    inactivity: function(state){
      return state.inactivity
    },
    siteBlocker: function(state){
      return state.siteBlocker
    },
    timezone: function(state){
      return state.timezone
    },
    exposeLabels: function(state){
      return state.exposeLabels
    },
    user: function(state){
      return state.user
    },
    userID: function(state){
      return state.userID
    },
    signedIn: function(state){
      return state.signedIn
    },
    appVisible: function(state){
      return state.appVisible
    },
    assets: function(state){
      return state.assets
    },
    bucket: function(state){
      return state.bucket
    },
    componentConfigurations: function(state){
      return state.componentConfigurations
    },
    resources: function(state){
      return state.resources
    },
    articles: function(state){
      return state.articles
    },
    searchBar: function(state){
      return state.flexSearch.searchBar
    },
    siteDocument: function(state){
      return state.flexSearch.siteDocument
    },
    guidesDocument: function(state){
      return state.flexSearch.guidesDocument
    },
    clinicalGuideData: function(state){
      return state.clinicalGuideData
    },
    articlesDocument: function(state){
      return state.flexSearch.articlesDocument
    },
    resourcesDocument: function(state){
      return state.flexSearch.resourcesDocument
    },
    storiesDocument: function(state){
      return state.flexSearch.storiesDocument
    },
    stories: function(state){
      return state.stories
    },
    redirects: function(state){
      return state.redirects
    },
    site: function(state){
      return state.site
    },
    pins: function(state){
      return state.pins
    },
    controllers: function(state){
      return state.controllers
    },
    fbid: function(state){
      return state.fbid
    }
  },
  actions: {
    Authorizer: function(context, payload){
      context.commit('Authorizer',payload)
    },
    Disclaimer: function(context, payload){
      context.commit('Disclaimer',payload)
    },
    pathAfterLogin: function(context, payload){
      context.commit('pathAfterLogin',payload)
    },
    debug: function(context, payload){
      context.commit('debug',payload)
    },
    language: function(context, payload){
      context.commit('language',payload)
    },
    languages: function(context, payload){
      context.commit('languages',payload)
    },
    labels: function(context, payload){
      context.commit('labels',payload)
    },
    // mainNavOpen: function(context, payload){
    //   context.commit('mainNavOpen',payload)
    // },
    width: function(context, payload){
      context.commit('width',payload)
    },
    height: function(context, payload){
      context.commit('height',payload)
    },
    inactivity: function(context, payload){
      context.commit('inactivity',payload)
    },
    siteBlocker: function(context, payload){
      context.commit('siteBlocker',payload)
    },
    timezone: function(context, payload){
      context.commit('timezone',payload)
    },
    exposeLabels: function(context, payload){
      context.commit('exposeLabels',payload)
    },
    user: function(context, payload){
      context.commit('user',payload)
    },
    userID: function(context, payload){
      context.commit('userID',payload)
    },
    signedIn: function(context, payload){
      context.commit('signedIn',payload)
    },
    appVisible: function(context, payload){
      context.commit('appVisible',payload)
    },
    assets: function(context, payload){
      context.commit('assets',payload)
    },
    bucket: function(context, payload){
      context.commit('bucket',payload)
    },
    componentConfigurations: function(context, payload){
      context.commit('componentConfigurations',payload)
    },
    resources: function(context, payload){
      context.commit('resources',payload)
    },
    articles: function(context, payload){
      context.commit('articles',payload)
    },
    searchBar: function(context, payload){
      context.commit('searchBar',payload)
    },
    siteDocument: function(context, payload){
      context.commit('siteDocument',payload)
    },
    guidesDocument: function(context, payload){
      context.commit('guidesDocument',payload)
    },
    clinicalGuideData: function(context, payload){
      context.commit('clinicalGuideData',payload)
    },
    articlesDocument: function(context, payload){
      context.commit('articlesDocument',payload)
    },
    resourcesDocument: function(context, payload){
      context.commit('resourcesDocument',payload)
    },
    storiesDocument: function(context, payload){
      context.commit('storiesDocument',payload)
    },
    stories: function(context, payload){
      context.commit('stories',payload)
    },
    redirects: function(context, payload){
      context.commit('redirects',payload)
    },
    site: function(context, payload){
      context.commit('site',payload)
    },
    pins: function(context, payload){
      context.commit('pins',payload)
    },
    controllers: function(context, payload){
      context.commit('controllers',payload)
    }
  },
  mutations: {
    Authorizer: function(state, payload){
      state.Authorizer = payload
    },
    Disclaimer: function(state, payload){
      state.Disclaimer = payload
    },
    pathAfterLogin: function(state, payload){
      state.pathAfterLogin = payload
    },
    debug: function(state, payload){
      state.debug = payload
    },
    language: function(state, payload){
      state.language = payload
    },
    languages: function(state, payload){
      state.languages = payload
    },
    labels: function(state, payload){
      state.labels = payload
    },
    mainNavOpen: function(state, payload){
      state.mainNavOpen = payload
    },
    width: function(state, payload){
      state.width = payload
    },
    height: function(state, payload){
      state.height = payload
    },
    inactivity: function(state, payload){
      state.inactivity = payload
    },
    siteBlocker: function(state, payload){
      state.siteBlocker = payload
    },
    timezone: function(state, payload){
      state.timezone = payload
    },
    exposeLabels: function(state, payload){
      state.exposeLabels = payload
    },
    user: function(state, payload){
      state.user = payload;
      // if(payload===undefined){
      //   state.signedIn = false
      //   state.userID = 'guest'
      //   state.exposeLabels = false
      //   state.debug = false
      // }else{
      //   if(state.languages.includes(state.user.attributes['custom:language'])){
      //     state.language = state.user.attributes['custom:language']
      //   }
      //   state.signedIn = true
      //   state.userID = state.user.signInUserSession.accessToken.payload.sub ? state.user.signInUserSession.accessToken.payload.sub : 'guest'
      // }
    },
    userID: function(state, payload){
      state.userID = payload
    },
    signedIn: function(state, payload){
      state.signedIn = payload
    },
    appVisible: function(state, payload){
      state.appVisible = payload
    },
    assets: function(state, payload){
      let assetMap = {}
      let tagMap = {}

      let contents = payload.contents
      for(let i=0; i<contents.length; i++){
        let asset = contents[i]
        assetMap[asset.Key] = asset


        if(!tagMap[asset.ETag]){
          tagMap[asset.ETag] = {
            aria: {},
            seo: {}
          }
        }
      }
      let assets = payload.assets
      for(let i=0; i<assets.length; i++){
        let asset = assets[i]
        if(!tagMap[asset.ETag]){
          tagMap[asset.ETag] = {
            aria: {},
            seo: {}
          }
        }

        let tags = asset.tags
        for(let tagType in tags){
          let tagSet = tags[tagType]
          for(let language in tagSet){
            let data = tagSet[language]
            if(typeof data=='string'){
              if(!tagMap[asset.ETag][tagType][language]){
                tagMap[asset.ETag][tagType][language] = ''
              }
              tagMap[asset.ETag][tagType][language] += ' '+data
              tagMap[asset.ETag][tagType][language] = tagMap[asset.ETag][tagType][language].trim()
            }

            if(typeof data=='object'){
              if(!tagMap[asset.ETag][tagType][language]){
                tagMap[asset.ETag][tagType][language] = []
              }
              let array1 = tagMap[asset.ETag][tagType][language] ? tagMap[asset.ETag][tagType][language] : []
              let array2 = data ? data : []
              let array3 = [... new Set([...array1, ...array2])]
              tagMap[asset.ETag][tagType][language] = array3
            }
          }
        }
      }
      state.assets = {
        map: assetMap,
        tags: tagMap,
        contents: payload.contents
      }
    },
    bucket: function(state, payload){
      state.bucket = payload
    },
    componentConfigurations: function(state, payload){
      state.componentConfigurations = payload
    },
    resources: function(state, payload){
      state.resources = payload
    },
    articles: function(state, payload){
      state.articles = payload
    },
    searchBar: function(state, payload){
      state.flexSearch.searchBar = payload
    },
    siteDocument: function(state, payload){
      state.flexSearch.siteDocument = payload
    },
    guidesDocument: function(state, payload){
      state.flexSearch.guidesDocument = payload
    },
    articlesDocument: function(state, payload){
      state.flexSearch.articlesDocument = payload
    },
    storiesDocument: function(state, payload){
      state.flexSearch.storiesDocument = payload
    },
    resourcesDocument: function(state, payload){
      state.flexSearch.resourcesDocument = payload
    },
    clinicalGuideData: function(state, payload){
      state.clinicalGuideData = payload
    },
    stories: function(state, payload){
      state.stories = payload
    },
    redirects: function(state, payload){
      state.redirects = payload
    },
    site: function(state, payload){
      state.site = payload
    },
    pins: function(state, payload){
      state.pins = payload
    },
    controllers: function(state, payload){
      state.controllers = payload
    }
  },
  modules: {
  }
})
