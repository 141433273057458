<template>
<div>
  <Layout class='CalloutCard' :preKey="'pages'" :columnComponents="PageCards.cards" :language="language"/>
</div>


  <!-- <v-container fluid style="width: 85%; margin: 0 auto;">
    <v-row dense justify="center">
      <v-col v-for="(page, pageIndex) in PageCards" :key="`page_${pageIndex}`" :cols="page.flex">
        <Page
          :page="page"
          :icon="page.icon"
          :bg_color="page.bg_color"
          :title="page.title"
          :subtitle="page.subtitle"
          :button="page.button"
        />
      </v-col>
    </v-row>
  </v-container>   -->
</template>

<script>
import Layout from '../layout_3col.vue'
import PageCard from './pageCard.vue'
export default {
    name: 'CalloutCards',
    props: {
      config: {
        type: Object,
        required: true
      },
      language: {
        type: String,
        required: true
      }
    },
    components: {
        Layout,
        PageCard
    },
    computed: {
      PageCards: function(){
        let config = this.config

        for(let i=0; i<config.cards.length; i++){
          config.cards[i].component = PageCard
        }

        return config
      }
    }
}
</script>

<style>

</style>
