<template>
    <v-card>
        <v-card-title>
            Filter List
        </v-card-title>
        <v-card-text>
            <v-text-field label="Search String" clearable @keyup="searchString" @click:clear="searchString"/>
            <v-select v-model="filter.type" :items="resourceTypes" clearable label="By Type"/>
            <v-select v-model="filter.publish" :items="[{text:'Published', value:'Y'},{text:'Not Published', value: 'N'}]" clearable label="Published"/>
            <v-select v-model="filter.enabled" :items="[{text:'Enabled', value:'Y'},{text:'Disabled', value: 'N'}]" clearable label="Enabled"/>
            <v-select multiple v-model="filter.categories" :items="categories" clearable label="By Category"/>
            <v-select multiple label="Language" v-model="filter.language" clearable :items="[{text:'English', value:'en-ca'},{text:'French', value:'fr-ca'}]"/>
            <v-select label="Sort by" v-model="filter.sort" :items="[{text:'Title', value:'title'},{text:'Latest', value:'updated'}]"/>
        </v-card-text>
    </v-card>  
</template>

<script>
export default {
    created: function(){
        this.update()
    },
    props: {
        resourceTypes: {
            type: Array,
            required: true
        },
        categories: {
            type: Array,
            required: true
        }
    },
    data: function(){
        return {
            filter: {
                string: null,
                type: null,
                publish: 'Y',
                enabled: 'Y',
                categories: [],
                sort: 'title',
                language: []
            },
            timer: null
        }
    },
    methods: {
        update: function(){
                this.$emit('update',this.filter)
        },
        searchString: function(e){
            clearTimeout(this.timer)
            let self = this
            if(e){
                let value = e.target.value
                this.timer = setTimeout(() => {
                    self.filter.string = value
                }, 800);
            }else{
                self.filter.string = null
            }
        }
    },
    watch: {
        filter: {
            deep: true,
            handler: function(){
                this.update()
            }
        }
    }
}
</script>

<style>

</style>