<template>
  <div v-if="debug">
    <v-btn @click="buildPageIndex">buildPageIndex</v-btn> | 
    <v-btn @click="buildArticleIndex">buildArticleIndex</v-btn> | 
    <v-btn @click="buildResourceIndex">buildResourceIndex</v-btn> | 
    <v-btn @click="buildStoriesIndex">buildStoriesIndex</v-btn> | 
    <v-btn @click="getFlexSearch">getFlexSearch</v-btn>    
  </div>
</template>

<script>
import Flexsearch from 'flexsearch'
export default {
    created: function(){
        this.init()
    },
    data: function(){
        return {
            flexSearchData: null
        }
    },
    methods: {
        init: async function(){
            await this.getFlexSearch() 
            this.initFlexSearch()           
        },
        getFlexSearch: async function(){
            let response = await this.sendRequest('get','/api/getFlexSearch')
            let flexSearchData = response.data
            this.$store.commit('clinicalGuideData',response.data.GuideData)
            this.$store.commit('articles',response.data.ArticlesData)
            this.$store.commit('resources',response.data.ResourcesData)
            this.$store.commit('stories',response.data.StoriesData)
            this.flexSearchData = flexSearchData
        },
        initFlexSearch: function(){
            let self = this
            const { Document } = Flexsearch
            let language = this.language
            let flexSearchData = this.flexSearchData

            function buildSiteContent(page){
                let output = {}
                function getPages(page, output, parent){
                    if(!parent){
                        let pagePath = parent ? parent : '/'
                        output[pagePath] = getContent(page)
                        // console.log('page',{
                        //     key: pagePath,
                        //     page,
                        //     output: output[pagePath]
                        // })
                    }
                    for(let path in page._pages){
                        let key = parent ? `${parent}/${path}` : `/${path}`
                        let childPage = page._pages[path]
                        output[key] = getContent(childPage, key)
                        // console.log('child',{
                        //     key,
                        //     childPage,
                        //     output: output[key]
                        // })
                        getPages(childPage, output, key)
                    }
                }

                function getContent(page, tracer){
                    // if(tracer){console.log('getContent',{page, tracer})}
                    let definition = page.definition ? page.definition : []
                    function stripHtml(html){
                        let tmp = document.createElement("DIV");
                        tmp.innerHTML = html && html.length > 0 ? html.replace(/<img[^>]*>/g,"") : html;
                        return tmp.textContent || tmp.innerText || "";
                    }

                    let contentArray = []
                    let pageLabel = page.meta.label[language]
                    // console.log('pageLabel',{pageLabel, page})
                    contentArray.push(pageLabel)
                    for(let i=0; i<definition.length; i++){
                        let record = definition[i]
                        if(record.component=='TextBlock'){
                            let sections = record.config.section

                            if(sections){
                                for(let s=0; s<sections.length; s++){
                                    let section = sections[s]
                                    
                                    for(let ss=0; ss<section.length; ss++){
                                        let sectionComponent = section[ss]
                                        let content
                                        if(typeof sectionComponent.content[language]=='string'){
                                            content = stripHtml(sectionComponent.content[language])
                                            contentArray.push(content)
                                        }else if(sectionComponent.config.contentType=='title'){                                          
                                            content = stripHtml(sectionComponent.content.title[language])
                                            if(content){
                                                contentArray.push(content)
                                            }
                                            content = stripHtml(sectionComponent.content.subtitle[language])
                                            if(content){
                                                contentArray.push(content)
                                            }
                                        }
                                    }
                                }

                            }
                        }else if(record.component=='Members'){
                            let members = self.$store.getters.componentConfigurations.members.config.members
                            for(let m=0; m<members.length; m++){
                                let member = members[m]
                                contentArray.push(JSON.stringify(member))
                            }
                        }
                    }
                    return contentArray
                }
                
                getPages(page, output)
                
            
                return output
            }


            let siteContent = buildSiteContent(this.$store.getters.site.home)
            // console.log('buildSiteContent',siteContent)

            const siteDocument = new Document({
                document: {
                    id: "id",
                    index: ["text"]
                }
            })
            for(let key in siteContent){
                let page = {
                    id: key,
                    text: siteContent[key]
                }
                siteDocument.add(page)
            }
                // for(let key in flexSearchData.SiteData[language]){
                //     let page = {
                //         id: key,
                //         // text: flexSearchData.SiteData[language][key],
                //         text: siteContent[key]
                //     }
                //     // console.log('adding page',{
                //     //     text_a: flexSearchData.SiteData[language][key],
                //     //     text_b: siteContent[key],
                //     //     key,
                //     //     siteContent
                //     // })
                //     siteDocument.add(page)
                // }                

            const guidesDocument = new Document({
                document: {
                    id: "id",
                    index: ["name","objective","html"]
                }
            })
                for(let i=0; i<flexSearchData.GuideData[language].guides.length; i++){
                let guide = flexSearchData.GuideData[language].guides[i]
                    guidesDocument.add(guide)
                }

            const articlesDocument = new Document({
                document: {
                    id: "id",
                    index: ["type", "topics","title", 'subtitle' ,"body"]
                }
            })
                for(let i=0; i<flexSearchData.ArticlesData.length; i++){
                    let record = flexSearchData.ArticlesData[i]
                    if(record.publish=='Y'){
                        let article = {
                            id: record.pageName,
                            type: record.type,
                            topics: record.topics,
                            title: record.content.title[language],
                            subtitle: record.content.subtitle[language],
                            body: record.content.body[language]
                        }
                        articlesDocument.add(article)
                    }
                }

            const resourcesDocument = new Document({
                document: {
                    id: "id",
                    index: ["title","subtitle","type", "category", "description","link","asset"]
                }
            })
                for(let i=0; i<flexSearchData.ResourcesData.length; i++){
                    let record = flexSearchData.ResourcesData[i]
                    if(record.enabled=='Y' && record.public=='Y' && record.publish=='Y'){
                        let resource = {
                            id: record.id,
                            title: record.definition.title[language],
                            subtitle: record.definition.subtitle[language],
                            type: record.definition.type,
                            category: record.definition.topics,
                            link: record.definition.resource.link[language],
                            asset: record.definition.resource.asset[language],
                            description: record.definition.description[language]
                        }
                        resourcesDocument.add(resource)
                    }
                }

            const storiesDocument = new Document({
                document: {
                    id: "id",
                    index: ["title", 'subtitle' ,"body"]
                }
            })
                for(let i=0; i<flexSearchData.StoriesData.length; i++){
                    let record = flexSearchData.StoriesData[i]
                    if(record.publish=='Y'){
                        let story = {
                            id: record.id,
                            title: record.story.title[language],
                            subtitle: record.story.subtitle[language],
                            body: record.story.content[language]
                        }
                        storiesDocument.add(story)
                    }
                }


            // console.log({
            //     flexSearchData
            // })
            this.$store.commit('siteDocument',siteDocument)
            this.$store.commit('guidesDocument',guidesDocument)
            this.$store.commit('articlesDocument',articlesDocument)
            this.$store.commit('resourcesDocument',resourcesDocument)
            this.$store.commit('storiesDocument',storiesDocument)
        },
        buildArticleIndex: async function(){
            let response = await this.sendRequest('post','/api/admin/buildArticleIndex')//,{page:'/about_us'}
            let data = response.data
            // console.log('buildArticleIndex',data)
        },
        buildStoriesIndex: async function(){
            let response = await this.sendRequest('post','/api/admin/buildStoriesIndex')//,{page:'/about_us'}
            let data = response.data
            // console.log('buildStoriesIndex',data)
        },
        buildResourceIndex: async function(){
            let response = await this.sendRequest('post','/api/admin/buildResourceIndex')//,{page:'/about_us'}
            let data = response.data
            // console.log('buildResourceIndex',data)
        },
        buildPageIndex: async function(){
            let response = await this.sendRequest('post','/api/admin/buildPageIndex')//,{page:'/about_us'}
            let data = response.data
            // console.log('buildPageIndex',data)

            // const { Document } = Flexsearch
            // const document = new Document({
            //     document: {
            //     id: "id",
            //     index: ["text"]
            //     }
            // });

            // let response = await this.sendRequest('post','/api/admin/clinical_guides/buildFlexSearch')
            // console.log(response)

            // for(let key in data){
            //     let page = {
            //     id: key,
            //     text: data[key]
            //     }
            //     document.add(page)
            // }

            // let results = document.search([{field:"text",query:"Thrombophilia"}])
            // let collection = []
            // for(let i=0; i<results.length; i++){
            //     let result = results[i].result
            //     for(let ii=0; ii<result.length; ii++){
            //     let guideID = result[ii]
            //     if(!collection.includes(guideID)){
            //         collection.push(guideID)
            //     }
            //     }
            // }
            

            // console.log('initFlexSearch',{results, collection})
        
        
        }        
    },
    computed: {
        language: function(){
            return this.$store.getters.language
        },
        debug: function(){
            return this.$store.getters.debug
        }
    },
    watch: {
        language: function(){
            this.initFlexSearch()
        }
    }
}
</script>

<style>

</style>