<template>
  <div>    
    <template v-for="(sections, sectionIndex) in definition.config.section">
        <template v-for="(section, index) in sections">

            <div :key="`${page_key}-textblock-${sectionIndex}-${index}`">
                <div v-if="section.config.contentType=='title'">
                    <h1>
                        {{section.content.title[language]}}
                    </h1>
                    <h2>
                        {{section.content.title[language]}}
                    </h2>
                </div>
                <div v-else style="color: grey; font-style: italic;">
                    Not handled contentType: {{section.config.contentType}}
                </div>
                <json-viewer v-if="debug" :value="section"/>
            </div>

        </template>
    </template>
  </div>
</template>

<script>
export default {
    name: "SEO_TextBlock",
    props: {
        definition: {
            type: Object,
            required: true
        },
        page_key: {
            type: String,
            required: true
        },
        language: {
            type: String,
            required: true
        },
        debug: {
            type: Boolean,
            required: false
        }
    },
    data: function(){
        return {
            contentTypes: [
                'title',
                'html',
                'button',
                'Articles'
            ]
        }
    }
}
</script>

<style>

</style>