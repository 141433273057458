<template>
  <div>
    <v-card>
      <v-card-title>
        Edit Article: {{isPromoted ? 'Promoted' : ''}}
      </v-card-title>
      <v-card-subtitle>
        <v-text-field v-model="model.pageName" label="Page Name:  Used in URL"/>
      </v-card-subtitle>
      <v-card-text v-if="model.pageName && model.pageName.length>=1">


        <v-container>
          <v-row>
            <v-col>
              Select Audience
              <v-checkbox v-model='model.audience' v-for="type in _audience" :value="type.value" :key="'article-audience-'+type.value" :label="type.text"/>
            </v-col>
            <v-col>
              Select Categories
              <v-checkbox v-model='model.type' v-for="type in _types" :value="type.value" :key="'article-type-'+type.value" :label="type.text"/>
            </v-col>
            <v-col>
              Select Topics
              <v-checkbox :class="[{'to-delete':topic.key==form.new_topic.key}]" v-model='model.topics' v-for="topic in _topics" :value="topic.key" :key="'article-topic-'+topic.key" :label="`[${topic.key}] - ${topic.text[language]}`"/>
            </v-col>
            <v-col>
              Topic Editor
              <v-card>
                <v-card-text>
                  <v-text-field v-model="form.new_topic.key" label="Topic Key - Displays between [ ]" @keyup="form.new_topic.key=form.new_topic.key.toUpperCase()"/>
                  <div v-for="language in $store.getters.languages" :key="'news_editor_'+language">
                    <v-text-field v-model="form.new_topic.text[language]" :label="`Text: ${language}`"/>
                  </div>
                </v-card-text>
                <v-card-actions v-if="form.new_topic.key && form.new_topic.key.length>1">
                  <v-btn small @click="saveTopic">
                    SAVE
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn small @click="deleteTopic">
                    DELETE
                  </v-btn>
                </v-card-actions>
              </v-card>

            </v-col>
          </v-row>
        </v-container>

        <v-switch :disabled="isPromoted" v-model="model.enabled" true-value="Y" false-value="N" label="enabled"/>
        <v-switch :disabled="isPromoted" v-model="model.publish" true-value="Y" false-value="N" label="publish"/>


        Publishing Date
        <v-date-picker v-model="model.content.date"></v-date-picker>

        <div v-if="model.type.includes('events') || model.type.includes('webinars')">
          <v-switch v-model="model.content.displayStartDate" :true-value="true" :false-value="false" label="Display Start Date"/>
          <v-container>
            <v-row>
              <v-col>
                <h3>Start Date</h3>
                <v-date-picker v-model="temp.start.date"></v-date-picker>
              </v-col>
              <v-col>
                <h3>Start Time</h3>
                <v-time-picker
                  v-model="temp.start.time"
                  format="24hr"
                  landscape
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <h3>End Date</h3>
                <v-date-picker v-model="temp.end.date"></v-date-picker>
              </v-col>
              <v-col>
                <h3>End Time</h3>
                <v-time-picker
                  v-model="temp.end.time"
                  format="24hr"
                  landscape
                />
              </v-col>
            </v-row>
          </v-container>
        </div>


        <v-text-field v-model="model.content.image" label="image" :disabled="true"/>
        <!-- <v-switch :true-value="true" :false-value="false" v-model="ui.uploader" label="Use Uploader"/> -->

        <div>
            <p style='text-decoration:underline;'>Image Sizing Recommendations</p>
            <p><span style='margin-right:16px;'><strong>Image ratio rule:</strong> W 1.2x H</span> <strong>Rendered aspect ratio:</strong> 6:5</span></p>
        </div>

        <v-btn text @click="ui.uploader=!ui.uploader">
          Upload
        </v-btn>
        <v-dialog v-model="ui.uploader">
          <v-card>
              <v-card-text>
                <Uploader v-if="ui.uploader" @setPath="setPath" :initPath="path"/>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="ui.uploader=!ui.uploader" text>
                  Close
                </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
          </v-card>

        </v-dialog>
        <Browser v-if="!ui.uploader" @selectAsset="selectContentImage" @setPath="setPath" :initPath="path"/>
        <v-container>
          <v-row>
            <v-col v-for="language in $store.getters.languages" :key="'news_editor_'+language">
              {{language}}
              <v-text-field v-model="model.content.title[language]" label="Title"/>
              <v-text-field v-model="model.content.subtitle[language]" label="subtitle"/>
              <!-- <v-textarea v-model="model.content.body[language]" label="body"/> -->
              <TinyEditor v-model="model.content.body[language]" :init="config.tinymce.init" :api-key="config.tinymce.apiKey"/>
              <v-textarea v-model="model.content.footer[language]" label="footer"/>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

    </v-card>

    <v-snackbar v-model="ui.snackbar.display" :multi-line="true" vertical>
      <div v-html="ui.snackbar.message"/>
      <template v-slot:action="{ attrs }">
        <v-btn
          color="red"
          text
          v-bind="attrs"
          @click="ui.snackbar.display=false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import Browser from '@/components/Assets/Browser'
import Uploader from '@/components/Assets/Uploader'
import TinyEditor from '@tinymce/tinymce-vue'
export default {
  props: {
    articles: {
      type: Array,
      required: true
    },
    _types: {
      type: Array,
      required: true
    },
    _componentConfig: {
      type: Object,
      required: true
    },
    isPromoted: {
      type: Boolean,
      required: true
    },
    language: {
      type: String,
      required: true
    }
  },
  created: function(){
    function resolveDigits(number){
      if(number<10){
        return `0${number}`
      }else{
        return number
      }
    }
    let start = this.model.content.start ? new Date(this.model.content.start) : null
    let end = this.model.content.end ? new Date(this.model.content.end) : null

    if(end){
      this.temp.start.date = `${start.getFullYear()}-${resolveDigits(start.getMonth()+1)}-${resolveDigits(start.getDate())}`
      this.temp.start.time = `${start.getHours()}:${start.getMinutes()}`
    }

    if(end){
      this.temp.end.date = `${end.getFullYear()}-${resolveDigits(end.getMonth()+1)}-${resolveDigits(end.getDate())}`
      this.temp.end.time = `${end.getHours()}:${end.getMinutes()}`
    }

    this.config.tinymce.apiKey = process.env.VUE_APP_TINYMCE_API_KEY
    console.log({
      start,
      end
    })
  },
  components: {
    Uploader,
    Browser,
    TinyEditor
  },
  data: function(){
    return {
      path: [0],
      config: {
        tinymce: {
            init: {
                relative_urls: false,
                selector: 'textarea',
                plugins: 'code fullscreen anchor autolink charmap codesample emoticons image media link lists searchreplace table visualblocks wordcount',
                toolbar: 'customExitButton | undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat | fullscreen | code',
                height: "800",
                setup: function(editor){
                    editor.on('init', function(e) {
                        // editor.execCommand('mceFullScreen');
                    });
                }
            },
            apiKey: null
          }
      },
      ui: {
        snackbar: {
          display: false,
          message: null
        },
        uploader: false
      },
      form: {
        new_topic: {
          key: null,
          text: {}
        }
      },
      temp: {
        start: {date: null, time: null},
        end: {date: null, time: null}
      }
    }
  },
  methods: {
    setTime: function(){
      let start = this.temp.start
      let t1
      if((start.date && start.date.length>1) && (start.time && start.time.length>1)){
        t1 = new Date(start.date + ' '+start.time)
      }
      this.model.content.start = t1 ? t1.getTime() : null


      let end = this.temp.end
      let t2
      if((end.date && end.date.length>1) && ((end.time && end.time.length>1))){
        t2 = new Date(end.date + ' '+end.time)
      }
      this.model.content.end = t2 ? t2.getTime() : null
    },
    selectContentImage: function(asset){
      this.model.content.image = asset.Key
    },
    saveTopic: async function(){
      let form = this.form.new_topic
      let key = form.key
      let text = form.text
      let record = {
        key,
        text
      }

      let response = await this.sendRequest('put','/api/admin/articles/topic/save',record)
      this.$store.commit('componentConfigurations',response.data.componentConfigurations)
    },
    deleteTopic: async function(){
      let language = this.language
      let form = this.form.new_topic
      let key = form.key
      let record = {
        key
      }
      let articles = this.articles
      let hasTopic = articles.filter((article)=>{return article.topics.includes(key)})
      if(hasTopic.length>0){
        let snackbar = this.ui.snackbar
        snackbar.display = true
        let list = "<ol>"
        for(let i=0; i<hasTopic.length; i++){
          let article = hasTopic[i]
          console.log({
            article, language
          })
          list+=`<li>${article.content.title[language]} - [${article.type}]</li>`
        }
        list+="</ol>"
        snackbar.message = "Topic in use by other articles: "+list
      }else{
        let response = await this.sendRequest('delete','/api/admin/articles/topic',record)
        this.$store.commit('componentConfigurations',response.data.componentConfigurations)
      }


    },
    setPath(data){
        console.log('editArticle > setPath',data)
        this.path = data
    }
  },
  computed: {
    model: function(){
      return this.$attrs.value
    },
    _topics: function(){
      return this._componentConfig.config.topics
    },
    _audience: function(){
      return [
        {text:'HCP',value:'hcp'},
        {text:'Patient',value:'patient'}
      ]
    }
  },
  watch: {
    temp: {
      deep: true,
      handler: function(){
        this.setTime()
      }
    }
  }
}
</script>

<style lang="scss">
.to-delete label{
  font-weight: bold;
  color: $tc-red !important;
}
</style>
