<template>
  <div>
    <v-btn @click="add">
        Add Member
    </v-btn>
    <template v-for="language in $store.getters.languages">
        <v-text-field v-model="config.title[language]" label="Title" :key="'members-title-'+language"/>
        <v-text-field v-model="config.subtitle[language]" label="Subtitle" :key="'members-subtitle-'+language"/>
    </template>

    <draggable v-model="config.members">
        <v-list-item v-for="(member, memberIndex) in config.members" :key="'member-index-'+memberIndex">

            <v-list-item-avatar>
                <v-icon>
                    mdi-menu
                </v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
                <v-list-item-title>
                    {{member.name}}, {{member.postnominals[language]}} - {{member.title[language]}}
                </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
                <v-btn icon @click="edit(memberIndex)">
                    <v-icon>
                        mdi-pencil
                    </v-icon>
                </v-btn>
            </v-list-item-action>
        </v-list-item>
    </draggable>

    <template v-if="editMember!=null && config.members[editMember]">
        <strong>Editing Member</strong>
        <v-card>
            <v-card-text>
                <v-text-field v-model="config.members[editMember].name" label="Name"/>
                <v-text-field v-model="config.members[editMember]['background-color']" label="Background Color"/>
                <v-text-field v-model="config.members[editMember].social.twitter" label="Twitter"/>
                <v-switch v-model="selectImage" :true-value="true" :false-value="false" :label="typeof config.members[editMember].image == 'string' ? `Change: ${config.members[editMember].image}` : typeof config.members[editMember].image=='object' ? config.members[editMember].image.Key : 'Upload'"></v-switch>
                <div v-if="selectImage">
                    <br>
                    <p style='text-decoration:underline;'>Image Sizing Recommendations</p>
                    <p><span style='margin-right:16px;'><strong>Image ratio rule:</strong> W 1.46x H</span> <strong>Rendered aspect ratio:</strong> 438:300</span></p>
                    <p><strong>Note:</strong> Portraits are usually taller and skinny. If so, it's recommended to create an image with the portait photo over a backing of similar or complimentary colour to the background in the portait. The overall dimensions of this created image should match the recommended image ratio rule.</p>
                </div>
                <Browser v-if="selectImage" :search="'team'" v-model="config.members[editMember].image"/>
                <v-card v-for="language in $store.getters.languages" :key="'member-index-'+editMember+'-'+language">
                    <v-card-subtitle>{{language}}</v-card-subtitle>
                    <v-card-text>
                        <v-text-field v-model="config.members[editMember].postnominals[language]" label="Postnominals"/>
                        <v-text-field v-model="config.members[editMember].title[language]" label="Title"/>
                        <v-textarea v-model="config.members[editMember].about[language]" label="About"/>
                    </v-card-text>
                </v-card>
            </v-card-text>

            <v-card-actions>
                <v-btn @click="editMember=null">
                    close
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn @click="remove(editMember)">
                    Remove
                </v-btn>
            </v-card-actions>
        </v-card>
    </template>
    <!-- <fieldset>
        <legend>
            Preview
        </legend>
        <Team/>
    </fieldset> -->
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import Browser from '@/components/Assets/Manager'
import Team from '@/components/Team/Team.vue'
export default {
    components: {
        draggable,
        Team,
        Browser
    },
    created: function(){
        let config = JSON.stringify(this.model)
        if(config){
            config = JSON.parse(config)
            this.config = config
        }
        this.save()
    },
    data: function(){
        return {
            config: {
                members: [],
                title: {},
                subtitle: {}
            },
            selectImage: false,
            editMember: null
        }
    },
    methods: {
        template: function(){
            let output = {
                'background-color': 'white',
                image: null,
                name: null,
                postnominals: {},
                title: {},
                social: {},
                about: {}

            }

            for(let lang in this.$store.getters.languages){
                output.postnominals[lang] = null
                output.title[lang] = null
                output.about[lang] = null
            }
            return output
        },
        save: function(){
            this.$emit('input',this.config)
        },
        add: function(){
            let record = this.template()
            this.config.members.push(record)
            this.editMember = this.config.members.length-1
        },
        remove: function(index){
            this.config.members.splice(index, 1)
        },
        edit: function(index){
            this.editMember = index
        }
    },
    computed: {
        model: function(){
            return this.$attrs.value
        },
        language: function(){
            return this.$store.getters.language
        }
    },
    watch: {
        config: {
            deep: true,
            handler: function(){
                this.save()
                this.$forceUpdate()
            }
        }
    }
}
</script>

<style>

</style>
