<template>
  <div>
    <v-select v-model="form.x" :label="'X-Align'" :items="xAlignOptions"/>
    <v-select v-model="form.y" :label="'Y-Align'" :items="yAlignOptions"/>    
  </div>
</template>

<script>
export default {
  created: function(){
    if(Object.keys(this.form)==0){
      this.initForm()
    }
  },
  methods: {
    initForm: function(){
      this.form = {
        x: null,
        y: null
      }
    }
  },
  computed: {
    form: function(){
      return this.$attrs.value
    },
    xAlignOptions: function(){
      return [
        {text: 'left', value: 'left'},
        {text: 'center', value: 'center'},
        {text: 'right', value: 'right'},
      ]
    },
    yAlignOptions: function(){
      return [
        {text: 'top', value: 'top'},
        {text: 'center', value: 'center'},
        {text: 'bottom', value: 'bottom'},
      ]
    }
  }
}
</script>

<style>

</style>