<template>
  <div>
    <h1>Redirect Editor</h1>
    <template  v-if="ready">
        <v-btn class="themed-button-dark" small @click="edit()">New</v-btn>
        <v-container fluid>
            <v-row v-for="record in records" :key="`record-id-${record.id}`">
                <v-col>
                    <v-btn class="themed-button-dark" small @click="edit(record)">
                        <v-icon>
                            mdi-pencil
                        </v-icon>
                    </v-btn>
                </v-col>
                <v-col>
                    <a :href="record.path" target="_blank">{{record.path}}</a>
                </v-col>
                <v-col>
                    <router-link :to="record.redirect">{{record.redirect}}</router-link>
                </v-col>
                <v-col>
                    <v-switch v-model="record.enabled" @change="toggle(record)" true-value="Y" false-value="N" label="Enabled" style="margin-top: 0px;"/>
                </v-col>
            </v-row>
        </v-container>
    </template>

    <v-dialog v-model="ui.edit" persistent>
        <v-card v-if="ui.edit">
            <v-card-title/>
            <v-card-text>
                <v-switch v-model="editing.enabled" true-value="Y" false-value="N" label="Enabled"/>
                <v-text-field v-model='editing.path' label="Path" required :rules="rules.isUnique"></v-text-field>
                <v-text-field v-model='editing.redirect' label="Redirect to"></v-text-field>                
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn small color="error" @click="ui.edit = false">
                    Close
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn small color="success" @click="save(editing)">
                    Save
                </v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
    name: "RedirectEditor",
    created: async function(){
        await this.init()
    },
    data: function(){
        return {
            ready: false,
            ui: {
                edit: false
            },
            editing: null,
            records: null,
            rules: {
                isUnique: [
                    v => !!v || 'Required',
                    v => this.isUnique(v) || 'Must be unique',
                    v => !(v.lastIndexOf('/') == v.length-1) || 'No trailing slashes'
                ]
            }
        }
    },
    methods: {
        init: async function(){
            function sortByPath(a,b){
                if(a.path < b.path){
                    return -1
                }
                if(a.path > b.path){
                    return 1
                }

                return 0
            }            
            
            this.records = null
            this.ready = false
            let response = await this.sendRequest('get','/api/getSite')
            if(response.status==200){
                this.records = response.data.redirects.sort(sortByPath)
                this.$store.commit('redirects',this.records)
                this.ready = true
            }
        },
        recordTemplate: function(){
            let output = {
                path: null,
                redirect: null,
                enabled: "Y"
            }

            return output
        },
        isUnique: function(v){
            let records = this.records
            let results = records.filter((item)=>{return item.path==v})
            let condition = {
                1: results.length==0,
                2: (results.length==1 && results[0].id == this.editing.id)
            }
            return condition[1] || condition[2]
        },
        edit: function(record){
            if(record){
                let clone = JSON.stringify(record)
                this.editing = JSON.parse(clone)
            }else{
                this.editing = this.recordTemplate()
            }
            this.ui.edit = true
        },
        close: function(){
            this.ui.edit = false
            this.editing = null
        },
        save: async function(record){
            let method = record.id ? 'patch' : 'put'
            let response = await this.sendRequest(method, `/api/admin/redirects`, record)
            if(response.status==200){
                await this.init()
                this.close()
            }
        },
        toggle: function(record){
            this.save(record)
        }
    }
}
</script>

<style>

</style>