<template>
  <div>
    Edit Members - Sorted by last name after save
    <v-btn @click="newMember">New Member</v-btn>
    <v-dialog persistent v-if="_member!=null" v-model="_showDialog">
        <v-card v-if="_member!=null">
            <v-card-title>
                Member Details
            </v-card-title>
            <v-card-text>
                <v-text-field v-model="_member.firstname" label="First Name"/>
                <v-text-field v-model="_member.middelname" label="Middle Name" style="width: 150px;"/>
                <v-text-field v-model="_member.lastname" label="Last Name"/>
                <v-container>
                    <v-row>
                        <v-col v-for="language in $store.getters.languages" :key="`member-${language}`">
                            {{language}}
                            <v-text-field v-model="_member.credentials[language]" label="Credentials"/>
                            <v-text-field v-model="_member.specialty[language]" label="Specialty"/>
                            <v-text-field v-model="_member.position[language]" label="Position"/>
                            <v-text-field v-model="_member.affillation[language]" label="Affillation"/>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-btn small @click="save">
                    Save
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn small @click="cancel">Cancel</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    
    <v-list v-else>
        <!-- <draggable v-model="members" @change="save"> -->
        <v-list-item v-for="(member,memberIndex) in members" :key="`member-${memberIndex}`">
            <v-list-item-avatar>
                {{memberIndex+1}}
            </v-list-item-avatar>
            <v-list-item-content>
                <v-list-item-title>
                     {{member.lastname}}, {{member.firstname}} {{member.middlename}} <span style="font-size: 0.8em" v-if="member.credentials[language] && member.credentials[language].length>0">- {{member.credentials[language]}}</span>
                </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
                <div>
                    <v-btn x-small icon @click="editMember(memberIndex)"><v-icon>mdi-pencil</v-icon></v-btn>
                    <v-btn x-small icon @click="removeMember(memberIndex)"><v-icon>mdi-delete</v-icon></v-btn>
                </div>
            </v-list-item-action>
        </v-list-item>
        <!-- </draggable> -->
    </v-list>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
export default {
    props: {
        language: {
            type: String,
            required: true
        }
    },
    created: function(){
        let _componentConfiguration = JSON.stringify(this._componentConfiguration)
        _componentConfiguration = JSON.parse(_componentConfiguration)
        let members = _componentConfiguration.config.members
        this.members = members
        this.ready = true
    },
    components: {
        draggable
    },
    data: function(){
        return {
            reay: false,
            members: [],
            editMemberIndex: null
        }
    },
    methods: {
        template: function(){
            let output = {
                firstname: null,
                lastname: null,
                middlename: null,
                credentials: {},
                specialty: {},
                position: {},
                affillation: {}

            }
            for(let i=0; i<this.$store.getters.languages.length; i++){
                let language = this.$store.getters.languages[i]
                
                for(let key in output){
                    let target = output[key]
                    if(target!=null && typeof target=='object'){
                        output[key][language] = null
                    }
                }

            }
            return output
        },
        newMember: function(){
            let newMember = this.template()
            this.members.push(newMember)
            this.editMember(this.members.length-1)
        },
        editMember: function(index){
            this.editMemberIndex = index
        },
        removeMember: function(index){
            let member = this.members[index]            
            if(confirm('Confirm member removal?\r\n'+member.firstname+' '+member.lastname+' \r\n*This cannot be undone')){
                this.members.splice(index, 1)
                this.save()
            }
            if(this.editMemberIndex == index){
                this.editMemberIndex = null
            }
        },
        cancel: function(){
            this.editMemberIndex=null;
        },
        save: async function(){
            let data = JSON.stringify(this._componentConfiguration)
            data = JSON.parse(data)
            data.config.members = this.members
            let response = await this.sendRequest('put','/api/admin/members',data)
            if(response.status==200){
                this.editMemberIndex=null
                this.sort()
            }
        },
        sort: function(){
            this.members.sort(function(a, b){
                if(a.lastname.toLowerCase().replace(' ','') < b.lastname.toLowerCase().replace(' ','')) { return -1; }
                if(a.lastname.toLowerCase().replace(' ','') > b.lastname.toLowerCase().replace(' ','')) { return 1; }
                return 0;
            })
        }
    },
    computed: {
        _componentConfiguration: function(){
            return this.$store.getters.componentConfigurations.members
        },
        _member: function(){
            return this.editMemberIndex!=null ? this.members[this.editMemberIndex] : null
        },
        _showDialog: function(){
            return this.editMemberIndex!=null
        }
    }
}
</script>

<style>

</style>