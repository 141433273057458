<template>
  <div>
    <div v-if="!ready">...loading...</div>
    <div v-else-if="!article">
      Article Not Found
    </div>
    <div v-else style="max-width: 800px; margin: 0 auto;">
      <Title class="news-title" :config="{title: article.content.title, align: {x: 'center'}}" :language="language" :title="article.content.title[language]" :subtitle="article.content.subtitle[language]"/>
      <div class="news-date" v-html="_date"/>
      <img class="news-image" :src="`${$store.getters.bucket}/${article.content.image}`" style="width: 100%; margin: 0 auto;"/>
      <div class="news-body" v-html="article.content.body[language]" style="text-align: left;"/>
      <div class="news-footer" v-html="article.content.footer[language]"/>
    </div>
  </div>
</template>

<script>
import Title from '@/components/Title/Title.vue'
export default {
  created: function(){
    this.getArticle()
  },
  components: {
    Title
  },
  props: {
    page: {
      type: Object,
      required: true
    },
    language: {
      type: String,
      required: true
    }
  },
  data: function(){
    return {
      ready: false,
      article: null
    }
  },
  methods: {
    getArticle: async function(){
      let articleId = this._articleId
      let response = await this.sendRequest('get',`/api/news/${articleId}`)
      this.article = null
      if(response.status==200){
        let article = response.data
        this.article = article
        let data = {
          type: article.type,
          id: article.id,
          topics: article.topics,
          path: this.$route.path,
          tool: 'website',
          log_key: article.content.title[this.language],
          section: 'Articles'
        }
        await this.sendRequest('put',`/api/tracker`,data)
      }
      this.ready = true
    }
  },
  computed: {
    _articleId: function(){
      return this.$route.params.articleId ? this.$route.params.articleId : null
    },
    _date: function(){
      let date = new Date(this.article.content.date)
      return date.toDateString()
    }
  }
}
</script>

<style scoped lang="scss">
.news-body{
  padding: 10px;
  margin: 10px;
}
.news-footer{
  padding: 15px;
  margin: 15px;
}
</style>