<template>
  <div>
    <div class="section-title-container" v-if="title">
      <div class="section-title-item decoration"></div>
      <h2 class="section-title-item text" :style="{'color':color ? color : ''}">
        {{title}}
      </h2>
      <div class="section-title-item decoration"></div>
    </div>

    <div class="section-subtitle" v-if="subtitle" v-html="subtitle"/>
  </div>
</template>

<script>
export default {
    props: {
      title: {
        type: String,
        required: false
      },
      subtitle: {
        type: String,
        required: false
      },
      color: {
        type: String,
        required: false
      }
        // params: {
        //   type: Object,
        //   required: false
        // }
    },
    computed: {
      // title: function(){
      //   if(this.$attrs.title){
      //     return this.$attrs.title
      //   }else if(this.params){
      //     if(this.params.title){
      //       return this.params.title
      //     }
      //   }
      // },
      // subtitle: function(){
      //   if(this.$attrs.subtitle){
      //     return this.$attrs.subtitle
      //   }else if(this.params){
      //     if(this.params.subtitle){
      //       return this.params.subtitle
      //     }
      //   }
      // }
    }
}
</script>

<style lang="scss">

</style>
