<template>
    <v-card>
        <v-card-title>
            Image Details
        </v-card-title>
        <v-card-text>
            <v-container>
                <v-row>
                    <v-col v-for="language in languages" :key="`video-form-${language}`">
                        {{language}}

                        <!-- <v-text-field v-model="form.title[language]" label="Title"/> -->
                        <v-text-field v-model="form.link[language]" label="Image Link" clearable/>
                        <Browser v-model="asset[language]"/>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>
        <v-card-actions>
            <slot name="actionButtons"></slot>            
            <!-- <v-btn :disabled="!_allowAddVideo" small @click="addItem">Add Video</v-btn> -->
        </v-card-actions>
    </v-card>
</template>

<script>
import Browser from '@/components/Assets/Browser'
export default {
    components: {
        Browser
    },
    props: {
        languages: {
            type: Array,
            required: true
        }
    },
    data: function(){
        return {
            asset: {}
        }
    },
    methods: {
    },
    computed: {
        form: function(){
            return this.$attrs.value
        },
        _allowAddVideo: function(){
            let languages = this.languages
            let form = this.form
            for(let i=0; i<languages.length; i++){
                let language=languages[i]
                for(let key in form){
                    let value = form[key][language]
                    if(!value || value.length==0){
                        return false
                    }
                }
            }
            return true
        }
    },
    watch: {
        asset: {
            deep: true,
            handler: function(){
                for(let key in this.asset){
                    let object = this.asset[key]
                    this.form.link[key] = object.Key
                }
            }
        }
    }
}
</script>

<style>

</style>