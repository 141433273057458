<template>
  <div>
    <v-card height="500" :class="[`resource-type-${resource.definition.type}`]">

        <div style="position: relative; width: 100%; height: 300px; background-size: cover; background-position: center;">
            <iframe :src="resource.definition.resource.asset[language]" style="width:100%; height:300px;" frameborder="0" allowfullscreen="allowfullscreen"/>
            <v-btn icon class="pin" :class="[{'pinned':pins.isPinned(pinKey, resource)}]" @click="pins.pin(pinKey, resource);$forceUpdate()">
                <v-icon color="white" large>mdi-pin</v-icon>
            </v-btn>
        </div>

        <v-card-text class="card-content">
            <div class="card-title">
                <span v-html="resource.definition.title[language]"/>
                <center>
                    <hr class="small"/>
                </center>
                <div v-if="resource.definition.subtitle[language] && resource.definition.subtitle[language].length>0" class="card-subtitle" v-html="resource.definition.subtitle[language]"/>
            </div>
            <!-- <div class="card-description" v-html="resource.definition.description[language]"/> -->
        </v-card-text>

        <v-card-actions>
            <v-spacer></v-spacer>
            <template>
                <v-btn v-if="resource.definition.embeded" @click="viewResource(resource.definition.resource.asset[language])" class="themed-button-dark" elevation="0">Watch Now</v-btn>
                <v-btn v-else :href="resource.definition.resource.asset[language]" target="_blank" class="themed-button-dark" elevation="0">Watch Now</v-btn>
            </template>
            <v-spacer></v-spacer>
        </v-card-actions>
    </v-card>

    <v-dialog v-model="ui.dialog" width="800">
        <v-card v-if="ui.dialog">
            <v-card-text style='padding:24px 24px 48px;'>
                <div v-if="video">
                    <iframe class='iframeVideo' :src="`${video}`" style="width:100%; height:600px;" frameborder="0" allowfullscreen />
                </div>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="ui.dialog=false;video=null">Close</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>
  </div>
</template>

<script>
import pinsController from '@/plugins/pins'
export default {
    props: {
        resource: {
            type: Object,
            required: true
        },
        language: {
            type: String,
            required: true
        },
        background: {
            type: String,
            required: false
        }
    },
    created: function(){
        // this.pins = new pinsController('resources', this)
        this.pins = this.$store.getters.controllers.pins
    },
    data: function(){
        return {
            ui: {
                dialog: false
            },
            pins: null,
            pinKey: 'resources',
            video: null
        }
    },
    methods: {
        downloadResource: function(resource){
            // console.log('downloadResource',{
            //     resource,
            //     bucket: this.$store.getters.bucket
            // })
            // window.open(`${this.$store.getters.bucket}/${resource}`);
            this.$emit('downloadResource',resource)
        },
        viewResource: function(resource){
            // console.log('viewResources',{
            //     resource,
            //     bucket: this.$store.getters.bucket
            // })
            // window.open(`${this.$store.getters.bucket}/${resource}`);
            // this.$emit('viewResource',resource)
            this.video = resource
            this.ui.dialog = true
        }
    },
    computed: {
        resourceType: function(){
            let resource = this.resource
            return resource.definition.type
        },
        _background: function(){
            let resource = this.resource
            let language = this.language
            if(this.background){
                return this.background
            }else if(resource.definition.resource.cardImage && resource.definition.resource.cardImage[language]){
                return `${this.$store.getters.bucket}/${resource.definition.resource.cardImage[language].Key}`
            }else{
                return 'https://cdn.vuetifyjs.com/images/cards/sunshine.jpg'
            }
            // return resource.definition.cardImage ? resource.definition.cardImage : this.background ? this.background : 'https://cdn.vuetifyjs.com/images/cards/sunshine.jpg'
        }
    }
}
</script>

<style scoped lang="scss">

/***********************
ResourceCard Component
Video Grid Configuration
************************/
.textblock-container {

    // VIDEOGRID CONFIG (just video)
    // ALL RATIOS
    &.section-ratio-1,
    &.section-ratio-1-1,
    &.section-ratio-1-2,
    &.section-ratio-2-1,
    &.section-ratio-1-3,
    &.section-ratio-3-1 {

        .resource-container {

            .video-grid {

                .video-grid-item {
                    min-width: initial;

                    .resource-type-video {
                        height: auto !important;
                        box-shadow: none;

                        // title under video
                        > div:nth-of-type(2) {
                            display: none;
                        }

                        // button under video
                        > div:nth-of-type(3) {
                            display: none;
                        }
                    }
                }
            }
        }
    }

    // VIDEOGRID CONFIG (just video)
    // ALL SIDE-BY-SIDES (excludes section-ratio-1)
    &.section-ratio-1-1,
    &.section-ratio-1-2,
    &.section-ratio-2-1,
    &.section-ratio-1-3,
    &.section-ratio-3-1 {

        .resource-container{

            .video-grid {

                .video-grid-item {
                    width: 100%;

                    .resource-type-video {
                        width: 100%;

                        // video container
                        > div:nth-of-type(1) {
                            height: fit-content !important;

                            iframe {
                                height: 530px !important;
                                max-width: initial !important;
                            }
                        }
                    }
                }
            }
        }
    }

    // VIDEOGRID CONFIG (just video)
    // ONLY section-ratio-1
    &.section-ratio-1 {

        .resource-container {

            .video-grid {

                .video-grid-item {
                    width: 33%;
                    min-width: 280px;
                }
            }
        }
    }
}


.pin{
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: $primary-1;
}

.pinned{
  background-color: $tc-red;
}

.resource-avatar{
    background-color: $tc-red;
    position: absolute;
    bottom: 10px;
    left: 10px;
}

.card-content{
    height: 130px;
    // border: 1px solid red;
    overflow: hidden;
}
.card-title{
    text-align: center;
    font-weight: bold;
    font-size: 1.1em;
    margin: 15px;
}
.card-subtitle{
    text-align: center;
    font-weight: normal;
    font-size: 0.8em;
    // margin: 10px;
    // height: 30px;
}

// .card-description{
//     height: 100px;
// }

.iframeVideo{
    @media(max-height: 600px){
        height: auto !important;
    }
}
</style>
