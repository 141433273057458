<template>
  <div class="banner" @click="pauseCycle">
    <div class="banner-body">
        <!-- <v-img v-if="slides[0].image[language]" :src="`${$store.getters.bucket}/${slides[0].image[language].replaceAll(' ','%20')}`" style="width: 100%;"/> -->

        <v-carousel
          v-model="slideIndex"
          hide-delimiters
          hide-delimiter-background
          show-arrows-on-hover
          :show-arrows="false"
          :cycle="cycle"
          :delay="delay"
          height="auto"
        >
          <v-carousel-item
            v-for="(item,i) in slides"
            :key="i"
            :eager="true"
            @click="slideClick(item, language)"
          >
            <!-- reverse-transition="fade-transition"
            transition="fade-transition" -->
            <v-sheet

              height="100%"
              tile
              >
                <!-- <div v-if="item.html[language]" v-html="item.html[language]"/>
                <v-img v-else-if="item.image[language]" :src="`${$store.getters.bucket}/${item.image[language].replaceAll(' ','%20')}`" style="max-width: 100%;"/> -->
              <v-row
                class="fill-height"
                align="center"
                justify="center"
                style='margin: 0;'
              >
                <div v-if="item.html[language]"  v-html="item.html[language]"/>
                <div v-else-if="item.image[language]" class='wrap_img'>
                  <img :src="`${$store.getters.bucket}/${item.image[language].replaceAll(' ','%20')}`"/>
                </div>
              </v-row>
            </v-sheet>
          </v-carousel-item>
        </v-carousel>
    </div>
    <SlideNav :language="language" :slides="slides" :slideIndex="slideIndex" @next="next" @prev="prev" @jump="jump"/>
  </div>
</template>

<script>
import SlideNav from './slideNav.vue'
export default {
  name: 'MainBanner',
  props: {
    config: {
      type: Object,
      required: true
    },
    language: {
      type: String,
      required: true
    }
  },
  components: {
    SlideNav
  },
  mounted: function(){
    this.emitTags(this.slides)
    this.cycle = this.config.cycle ? this.config.cycle.enabled : true
    this.delay = (this.config.cycle && this.config.cycle.delay) ? parseInt(this.config.cycle.delay,10) : 5
  },
  data: function(){
    return {
      slideIndex: 0,
      cycle: false,
      delay: 5,
      resetTimer: null
    }
  },
  methods: {
      next: function(){
        let slideIndex = this.slideIndex
        let slides = this.slides
        if(slideIndex<slides.length){
          this.slideIndex++
        }else{
          this.slideIndex=0
        }
      },
      prev: function(){
        let slideIndex = this.slideIndex
        let slides = this.slides
        if(slideIndex>0){
          this.slideIndex--
        }else{
          this.slideIndex=slides.length-1
        }
      },
      jump: function(index){
        this.slideIndex = index
      },
      emitTags: function(){
        let slides = this.slides
        let language = this.language
        for(let i=0; i<slides.length; i++){
          let slide = slides[i]
          let tags = (this.resolveTags(slide.image[language]))
          if(tags){
            this.$emit('tags',{component: this.$options.name, tags})
          }
        }
      },
      resolveTags(key){
        let assets = this.$store.getters.assets
        let ETag = assets.map[key]?.ETag
        let tags = assets.tags[ETag]

        return tags
      },
      slideClick(item, language){
        this.pauseCycle()
        item.click[language] ? this.$router.push({path:item.click[language]}) : false
      },
      pauseCycle(){
        let self = this
        self.cycle = false
        clearTimeout(this.resetTimer)
        this.resetTimer = setTimeout(function(){
          self.cycle = self.config.cycle ? self.config.cycle.enabled : true
        },6000)
      }
  },
  computed: {
      // language: function(){
      //   return this.$store.getters.language
      // },
      slides: function(){
        return this.config.slides
      }
  }
}
</script>

<style scoped lang="scss">
.banner{
    width: 100%;
    min-height: 200px;
    position: relative;
    padding-bottom: 15px;
    background-color: $primary-light-2;

    .banner-body{
        background-color: white;

        .wrap_img {
            width: 100%;
            display: flex;
            padding: 0 10%;
            height: 440px;
            @media(max-width: 959px){
                height: 304px;
            }
            @media(max-width: 599px){
                height: 224px;
                padding: 0 4%;
                width: 100%;
            }

            > img {
                max-width: 100%;
                object-fit: contain;
            }
        }
    }
}
</style>
