<template>
<div>
    <v-container fluid>
        <!-- <v-select class='langSelector' v-model="language" :items="[{text:'English', value:'en-ca'},{text:'French', value:'fr-ca'}]" label="Language"/> -->
        <div v-if="ready">

            <Title :title="'Clinical Guides'" style="margin: 50px;"/>
            <template v-if="!selectedGuide">
              <div style="margin: 50px auto; max-width: 1024px;">
                <p>The Thrombosis Canada clinical guides have been developed to assist clinicians with point of care decision making.&nbsp;They are not intended to be taken as guidelines. The Thrombosis Canada<sup>TM</sup> Clinical Guides are:</p>
                <ul>
                  <li>Developed voluntarily by Thrombosis Canada<sup>TM</sup>members, internationally recognized as experts</li>
                  <li>Peer reviewed by Thrombosis Canada clinical guide committee</li>
                  <li>Reviewed for applicability to primary care by members of the College of Family Physicians of Canada</li>
                  <li>Reflect recommendations of evidence-based clinical practice guidelines</li>
                  <li>Designed to be robust, yet concise enough to be applicable at the point of care</li>
                  <li>Updated regularly to remain current</li>
                  <li>Not supported financially by any external funders</li>
                </ul>
              </div>  
            </template>
            
            <div class='wrap_clinicalGuides' style="text-align: left;">

                <v-btn class='button_searchMenu' v-if="this.$store.getters.width < 959" @click="toggleSearchMenu">Search Menu</v-btn>

                <div class="search" v-if="this.$store.getters.width >= 959 || (this.$store.getters.width < 959 && this.searchMenuOpen == true)">
                    <Search :language="language" :guides="guideData[language].guides" :guideTopics="guideData[language].guideTopics" @ready="states.search.ready=true" @filter="searchFilter"/>
                </div>

                <div class="select" v-if="!selectedGuide">
                    <div v-for="guideID in guideIDs" :key="`GuideCard_${guideID}`">
                        <GuideCard v-if="_guides[guideID]" :language="language" :guideTopics="guideData[language].guideTopics" :guide="_guides[guideID]" @selectGuide="selectGuide" style="margin: 15px;"/>
                    </div>
                </div>

                <div v-else class="clinicalGuideViewer">
                    <GuideViewer :guide="selectedGuide" :guideTopics="guideData[language].guideTopics" @makePDF="makePDF"/>
                </div>

                <div class="searchMenuOpen_touchspace" v-if="this.$store.getters.width < 959 && this.searchMenuOpen == true" @click="toggleSearchMenu"></div>
            </div>
        </div>
    </v-container>

    <v-dialog width="150" v-model="loading">
        <v-card>
            <v-card-text>
                <div class="loader" style="height: 100px; text-align: center;">
                  <v-icon large style="margin-top: 50%;transform:translateY(-50%)">mdi-spin mdi-loading</v-icon>
                </div>
            </v-card-text>
        </v-card>
    </v-dialog>

    <v-snackbar v-model='ui.snackbar.display'>{{ui.snackbar.message}}</v-snackbar>

    <v-dialog v-model="test.generating" width="100">
        <v-card>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-avatar>
                    <v-btn large icon @click="test.generating=false">
                        <v-icon large>mdi-spin mdi-loading</v-icon>
                    </v-btn>
                </v-avatar>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>
</div>
</template>

<script>
import Title from '@/components/Section/title.vue'
import GuideViewer from './guideViewer'
import Search from './search.vue'
import GuideCard from './guideCard.vue'
import Flexsearch from 'flexsearch'

export default {
  name: "ClinicalGuides",
  components: {
    Title,
    GuideViewer,
    Search,
    GuideCard
  },
  created: function(){
    // this.language = this.$store.getters.language
    if(this.clinicalGuideData){
      this.init()
    }
  },
  props: {
    language: {
      type: String,
      required: true
    }
  },
  data: function(){
    return {
      // language: null,
      ui: {
        snackbar: {
          display: false,
          message: null
        }
      },
      states: {
        search: {
          ready: false
        }
      },
      ready: false,
      guideData: {},
      // objects: [],
      Bucket: null,
      panel: null,
      guideIDs: [],
      selectedGuide: null,
      searchMenuOpen: false,
      test: {
        pdf: false,
        guide: null,
        response: null,
        generating: false
      }
    }
  },
  methods: {
    init: async function(){
      // this.language = this.$store.getters.language
      window.openLinkedGuide = this.openLinkedGuide
      this.ready = false
      let response = await this.getGuides()
      if(response){
        this.guideData = response
        // this.flexSearchData = response.flexSearchData
        // this.objects = response.objects
        this.Bucket = this.$store.getters.bucket //response.Bucket
        let guideIDs = this.guideData[this.language].guides.map((object)=>object.id)
        this.guideIDs = guideIDs
      }
      this.ready = true
      this.displayGuide()
    },
    getGuides: async function(){
      return this.clinicalGuideData
      /* FlexSearch pre-loads this including HTML */
      // let response = await this.sendRequest('get','/api/clinical_guides')
      // return response.status==200 ? response.data.guideData : false
    },
    openLinkedGuide: function(guideId){
      let guides = this._guides
      let guide = guides[guideId]
      this.selectGuide(guide)
    },
    initFlexSearch: async function(){
      const { Index, Document, Worker } = Flexsearch
      let options = {}
      const worker = new Worker(options);
      const index = new Index(options);
      const document = new Document({
        document: {
          id: "id",
          index: ["name","objective","html"]
        }
      });

      // let response = await this.sendRequest('post','/api/admin/clinical_guides/buildFlexSearch')
      // console.log(response)

      let language = this.language
      let data = this.flexSearchData[language]
      let guides = data.guides
      for(let i=0; i<guides.length; i++){
        let guide = guides[i]
        document.add(guide)
      }

      let results = document.search([{field:"name",query:"Thrombophilia"},{field:"objective",query:"Thrombophilia"},{field:"html",query:"Thrombophilia"}])
      let collection = []
      for(let i=0; i<results.length; i++){
        let result = results[i].result
        for(let ii=0; ii<result.length; ii++){
          let guideID = result[ii]
          if(!collection.includes(guideID)){
            collection.push(guideID)
          }
        }
      }


      console.log('initFlexSearch',{ index, document, results, collection})
    },
    searchFilter: function(data){
      let unique = []
      for(let i=0; i<data.length; i++){
        let id = isNaN(data[i]) ? data[i] : parseInt(data[i], 10)
        if(!unique.includes(id)){
          unique.push(id)
        }
      }
      this.guideIDs = unique
      this.closeGuide()
    },
    selectGuide: function(data){
      this.$router.push({
        query: {
          language: this.language,
          guideID: data.id
        }
      })
      /*
      const routeData = this.$router.resolve({query: {language: this.language,guideID: data.id}})
      window.open(routeData.href, '_blank')
      */
      
    },
    displayGuide: function(){
      this.selectedGuide = null
      let self = this
      setTimeout(function(){
        let query = self.$route.query
        if(query.language!=undefined && query.guideID!=undefined){
          let guides = self._guides
          let guide = guides[query.guideID]
          if(guide){
            self.selectedGuide = guide
            window.scrollTo(0,0)

          }else{
            self.closeGuide()
            self.guideNotFound()
          }
        }
        self.updateMetaTags()
      },100)
    },
    closeGuide: function(){
      this.$router.push({query:{}})
    },
    guideNotFound: function(){
      this.ui.snackbar.display = true
      this.ui.snackbar.message = 'Guide Not Found'
    },
    makePDF: async function(guide){
      console.log('makePDF',guide)
      this.test.guide = guide
      this.test.generating = true

      let self = this
      async function generatePDF({html, id, version}){
        let response = await self.sendRequest('post','/api/admin/pdf',{html, id, version})
        return response
      }

      let response = await generatePDF({html: guide.html, id: guide.id, version: guide.version})
      this.test.response = response.data
      this.test.generating = false
      if(response.data.url){
        window.open(`${response.data.url}`);
      }else{
        console.error('generatePDF',response)
      }
    },
    toggleSearchMenu: function(){
        if(this.searchMenuOpen == false){
            this.searchMenuOpen = true;
        }
        else{
            this.searchMenuOpen = false;
        };
    },
    updateMetaTags: function(){
      let guide = this.selectedGuide
      let language = this.language
      let elem = {
        language: document.getElementsByTagName("html")[0],
        title: document.getElementsByTagName("title")[0],
        description: document.querySelectorAll("meta[name='description']")[0]
      }
      elem.language.setAttribute('lang',language)
      elem.title.innerHTML = 'Clinical Guides'
      elem.title.innerHTML += guide && guide.name ? ` - ${guide.name}` : ''
      elem.title.innerHTML += (elem.title.innerHTML.length>0 ? ' | Thrombosis Canada' : 'Thrombosis Canada')
      elem.description.setAttribute('content',guide.objective && guide.objective ? guide.objective : '')

    }
  },
  computed: {
    // language: function(){
    //   return this.$store.getters.language
    // },
    _guides: function(){
      let language = this.language
      let output = {}
      let guides = this.guideData[language].guides
      for(let i=0; i<guides.length; i++){
        let guide = guides[i]
        // guide.Key = guide.url.replace('guides/',`clinical_guides/${language}/`)
        guide.Key = guide.url.replace('guides/',`clinical_guides/${language}/guides/`)
        output[guide.id] = guide
      }

      return output
    },
    _query: function(){
      return this.$route.query
    },
    loading: function(){
      return !this.ready
    },
    clinicalGuideData: function(){
      return this.$store.getters.clinicalGuideData
    }
  },
  watch: {
    _query: {
      deep: true,
      handler: function(){
        this.displayGuide()
      }
    },
    clinicalGuideData: function(){
      if(this.clinicalGuideData){
        this.init()
      }
    },
    language: function(){
      this.selectedGuide = null
    }
  }
}
</script>

<style lang="scss">

.langSelector {
    max-width: 900px !important;
    margin: 0 auto;
}

.wrap_clinicalGuides {
    display: flex;
    position: relative;
    padding: 0 8%;
    @media(max-width: 1199px){
        padding: 0 4%;
    }
    @media(max-width: 959px){
        flex-flow: column;
        align-items: center;
        padding: 0;
    }

    .button_searchMenu {
        width: fit-content;
        text-transform: none;
        background-color: $primary-1 !important;
        color: white !important;
        margin-top: 16px;
        align-self: flex-start;
        z-index: 2;
    }

    .search{
        width: 25%;
        padding: 12px;
        background-color: white;
        border: 1px solid lightgrey;
        margin-top: 16px;
        @media(max-width: 959px){
            position: absolute;
            z-index: 1;
            top: 68px;
            left: -2px;
            min-width: 300px;
            margin-top: initial;
        }

        .listbox {

            .v-list-item {

                > div > div { // v-list-item__title
                    white-space: initial;
                }
            }
        }
    }

    .select {
        width: 70%;
        margin-left: 24px;
        @media(max-width: 959px){
            margin-left: 0;
            width: 100%;
        }

        > div > div {
            margin: 16px 0 !important;

            > div > div {
                padding: 0 !important;
            }
        }
    }

    .clinicalGuideViewer{
        display: inline-block;
        vertical-align: top;
        width: 70%;
        margin: 0 auto;
        padding: 15px;
        margin: 0 auto;
        text-align: left;
        @media(max-width: 959px){
            width: 100%;
        }
    }

    .searchMenuOpen_touchspace {
        position: absolute;
        top: 0;
        left: -15px;
        width: calc(100% + 30px);
        height: 100%;
        background-color: transparent;
    }
}

</style>
