<template>
<div class="find-us" style="margin-top:-10px;">
    <v-container class="pt-1">
    <v-row no-gutters>
        <v-card :dark="dark" color="transparent" flat>
            <v-card-title class="d-flex pt-0">
                <div class="align-self-start"><v-btn icon @click="showOnGMap()"><v-img :src="require('@/assets/map-marker.svg')" class="ma-2" contain/></v-btn></div>
                <div class="mt-2 pl-2">
                    <p class="mb-1" style="font-size:1.5em; font-weight:bold;">Location</p>
                    <div style="font-size:14px; line-height:1.5;">
                        <p class="mb-0">{{$store.getters.contact.address.street}}</p>
                        <p class="mb-0">{{$store.getters.contact.address.city}}, {{$store.getters.contact.address.province}}</p>
                        <p class="mb-0">{{$store.getters.contact.address.postal_code}}, {{$store.getters.contact.address.country}}</p>
                    </div>
                </div>
            </v-card-title>

            <v-card-title class="pl-1">
                <div class="align-self-start"><v-img :src="require('@/assets/phone.svg')" class="ma-2" contain/></div>
                <div class="mt-5 pl-0">
                    <p class="mb-1" style="font-size:1.5em; font-weight:bold;">Contact Us</p>
                    <div style="font-size:14px; line-height:1.5;">
                        <p class="mb-0">Telephone: {{$store.getters.contact.phone}}</p>
                    </div>
                </div>
            </v-card-title>
        </v-card>
    </v-row>        
    </v-container>   

    <v-list :dark="dark" style="background-color: transparent;">
        <v-list-item>
            <v-list-item-action class="icon">
                <v-icon x-large>
                    mdi-charity
                </v-icon>
            </v-list-item-action>
            <v-list-item-content>
                <v-list-item-title>
                    Registered Charity #{{ $store.getters.contact.charity_number }}
                </v-list-item-title>
            </v-list-item-content>
        </v-list-item>
        <v-list-item>
            <v-list-item-action class="icon">
                <v-icon x-large>
                    mdi-email
                </v-icon>
            </v-list-item-action>
            <v-list-item-content>
                <v-list-item-title>
                    To be added to our contact list for updates send an e-mail to: <a :href='`mailto:${$store.getters.contact.email}`'>{{$store.getters.contact.email}}</a>
                </v-list-item-title>
            </v-list-item-content>
        </v-list-item>
    </v-list>
    
    
</div>
</template>

<script>

export default {
    name: "find-us",
    props: {
        language: {
            type: String,
            required: false,
            default: "en-ca"
        },
        dark: {
            type: Boolean,
            required: false,
            default: ()=>{return true}
        },
        small: {
            type: Boolean,
            required: false,
            default: ()=>{return false}
        }
    },
    created: function(){},
    methods: {
        showOnGMap: function(){
            window.open(this.gMapUrl, '_blank', 'noreferrer');
        }
    },
    computed: {
        gMapUrl(){
            return this.$store.getters.contact.address.gmap
        }
    }
}
</script>

<style lang="scss" scoped>
.icon{
    margin: 10px;
    color: white;
}
</style>