<template>
  <div class='FederationBox' style="cursor: pointer;" :style="{'border-color':borderColor}">
    <div class="logo">
        <v-icon :style="{'color':iconColor}">
            {{icon}}
        </v-icon>
    </div>
    <div :style="{'background-color':bgColor}" class="company" v-html="text"/>
  </div>
</template>

<script>
export default {
    props: {
        bgColor: {
            type: String,
            required: false,
            default: 'white'
        },
        icon: {
            type: String,
            require: true
        },
        text: {
            type: String,
            required: true
        },
        borderColor: {
            type: String
        },
        iconColor: {
            type: String
        }
    }
}
</script>

<style scoped lang="scss">

.FederationBox {
    display: flex;
    width: 240px;
    height: 48px;
    border-width: 2px;
    border-style: solid;

    .logo,
    .company{
        display: inline-block;
        color: white;
    }

    .logo{
        text-align: center;
        width: 56px;
        padding: 8px 0;
    }

    .company{
        width: 100%;
        font-size: 14px;
        padding: 12px 16px 12px 24px;
    }
}

</style>
