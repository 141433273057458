<template>
  <div style="text-align:left;" v-if="ready">
    <div v-if="!ui.edit_page.display">
      <Title :title="'Page Manager'"/>
      <v-btn text @click="ui.restore = true">Restore Deleted Page</v-btn>
      <!-- <v-btn icon @click="newPage()"><v-icon>mdi-new-box</v-icon></v-btn> | <v-btn icon @click="editPage()"><v-icon>mdi-pencil-outline</v-icon></v-btn> -->
      <!-- activatable -->
      <v-treeview v-if="ready" hoverable v-model="ui.tree" :items="pageTree" :open-on-click="false" item-key="shortCutKey" :open-all="true" :open="['home']">
        <template v-slot:prepend="{ item, open }">
          <v-icon>
            {{ open && item.children.length>0 ? 'mdi-folder-open' : 'mdi-web' }}
          </v-icon>
        </template>

        <template v-slot:label="{item}">
          <span :class="[{public:item.public}, {notPublic:!item.public}]" v-html="item.label.replace('/home/','/')"/>
        </template>

        <template v-slot:append="{ item }"><!-- , open -->
          <v-btn icon @click="newPage(item)"><v-icon>mdi-new-box</v-icon></v-btn> | 
          <v-btn icon @click="$router.push({query:{edit:item.shortCutKey}})"><v-icon>mdi-pencil-outline</v-icon></v-btn> | 
          <v-btn icon @click="deletePage(item)" color="red"><v-icon>mdi-delete</v-icon></v-btn> | 
          <v-btn @click="selectVersion(item)" text>Version</v-btn> | 
          <!-- <v-btn icon @click="$router.push({path:`${item.path.replace('/home','')}`})"><v-icon>mdi-link-box-variant-outline</v-icon></v-btn> -->
          <v-btn icon :href="`${item.path.replace('/home','')}`" target="_blank"><v-icon>mdi-link-box-variant-outline</v-icon></v-btn>
        </template>
      </v-treeview>
      <SiteMap v-show="false" :site="$store.getters.site"/>
    </div>

    <div v-else>
      <v-btn small class="themed-button-dark" @click="ui.edit_page.display=false">
        Back
      </v-btn>      
      <v-card style="text-align: left;">
        <v-card-title>
          {{getPath()}}
        </v-card-title>
        <v-card-subtitle>
          <v-btn class="themed-button-dark" small link target="_blank" :href="`${getPath()}`">
            Open
            <v-icon right>mdi-link</v-icon>
          </v-btn>          
        </v-card-subtitle>
        <template>
          <v-tabs v-model="ui.tab">
            <v-tab>ROUTE</v-tab>
            <v-tab>PAGE</v-tab>
          </v-tabs>

          <v-tabs-items v-model="ui.tab">
            <v-tab-item>
              <RouteEditor v-model="forms.edit_page" :structure="temp" :level="_level" @close="ui.edit_page.display=false" @save="save"/>            
            </v-tab-item>

            <v-tab-item>
              <v-container fluid>
                <v-row>
                  <v-col cols="12">
                    <PageEditor v-if="temp.page.definition" v-model="temp.page" :site="site" :structure="temp" :level="_level" @save="save"/>
                    <v-btn v-else @click="createDefinition">Create Definition</v-btn>
                  </v-col>                  
                </v-row>
              </v-container>
            </v-tab-item>
          </v-tabs-items>
        </template>

        <v-card-actions>
          <v-btn small style="position: fixed; bottom: 15px; left: 15px;" @click="ui.edit_page.display=false">
            Cancel
          </v-btn>

          <v-spacer></v-spacer>

          <v-btn fab small color="red" style="position: fixed; bottom: 80px; right: 15px;" dark @click="save">
            <v-icon>mdi-content-save</v-icon>
          </v-btn>

          <!-- <v-spacer></v-spacer>

          <v-btn text color="success" @click="ui.preview=true">
            Preview
          </v-btn> -->
        </v-card-actions>        
      </v-card>      
    </div>
    


    <v-dialog v-model="ui.preview" fullscreen>
      <v-card>
        <v-card-title>
          PREVIEW
        </v-card-title>
        <v-card-subtitle>
          <span v-html="temp.parent ? temp.parent.name : ''"/>/{{forms.edit_page.inputs.path}}
        </v-card-subtitle>
        <v-card-text>
          <Preview v-if="ui.preview" :site="site" :preview="temp.page" style="border: 1px solid red;"/>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="ui.preview=false">
            Close Preview
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="ui.restore">
      <v-card>
        <v-card-title>
          Restore
        </v-card-title>
        <v-card-text>
          <v-list dense>
            <v-list-item v-for="item in deleted" :key="`deleted-${item.key}`">
              <v-list-item-content>
                <v-list-item-title>
                  {{item.key.replaceAll('.','/')}}
                </v-list-item-title>
              </v-list-item-content>
                <v-list-item-action>
                  <v-btn @click="restorePage(item)" text>
                    Restore
                  </v-btn>
                </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-btn text @click="ui.restore=false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="ui.version">
      <v-card>
        <v-card-title>
          Version used will be the latest active version
        </v-card-title>
        <v-card-text>
          <v-list dense>
            <v-list-item v-for="item in versions" :key="`versions-${item.id}`" :class="[{'active-version':item.active=='Y'}]">
              <v-list-item-content>
                <v-list-item-title>
                  {{item.key.replaceAll('.','/')}} - {{item.definition.meta.label[language]}} | {{item.active == 'Y' ? 'ACTIVE' : 'inactive'}}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{new Date(item.created)}}
                </v-list-item-subtitle>
              </v-list-item-content>
                <v-list-item-action>
                  <v-btn @click="setVersion(item)" text>
                    Use
                  </v-btn>
                </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-btn text @click="ui.version=false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Title from '@/components/Section/title.vue'
import RouteEditor from './pageEditor/routeForm.vue'
import PageEditor from './pageEditor/pageEditor.vue'
import EditHideGlobalComponents from './pageEditor/editHideGlobalComponents'
import Preview from '@/views/Render.vue'
import SiteMap from '@/components/SiteMap/SiteMap'
export default {
  name: "CMS",
  components: {
    SiteMap,
    Title,
    PageEditor,
    EditHideGlobalComponents,
    RouteEditor,
    Preview
  },
  created: function(){
    this.init()
  },
  data: function(){
    return {
      presave: null,
      ready: false,
      ui: {
        tab: 0,
        tree: [],
        edit_page: {
          display: false
        },
        preview: false,
        restore: false,
        version: false
      },
      site: {},
      pageTree: null,
      temp: {
        parent: null,
        site: null
      },  
      deleted: {},
      versions: [],
      forms: {
        edit_page: {
          valid: false,
          mode: 'new',
          inputs: null
        }
      }
    }
  },
  methods: {
    init: async function(){
      // this.ready = false
      this.presave = this.cache.storage.local.get('presave')
      let response = await this.sendRequest('get','/api/getSite')
      this.$store.commit('site', response.data.site)
      this.site = response.data.site
      delete this.site.banner
      delete this.site.navBar
      delete this.site.navDrops

      let deleted = await this.sendRequest('get','/api/admin/pages/inactivePages')
      if(deleted.status==200){
        this.deleted = deleted.data
      }


      this.pageTree = this.buildTree(this.site)
      this.forms.edit_page.inputs = this.pageTemplate()
      await this.$root.init()
      this.ready = true
      this.edit()

      // if(this.presave){
      //   if(confirm('Retry save?')){
      //     this.save()
      //   }else{
      //     this.presave = null
      //   }
      // }
    },
    getPath: function(){
      let temp = this.temp
      let forms = this.forms
      return `${temp.parent ? temp.parent.name.replace('/home','') : ''}/${forms.edit_page.inputs.path ? forms.edit_page.inputs.path.replace('home','') : ''}`
    },
    buildTree: function(pages){
      let language = this.language
      let items = generatePagesArray(pages)

      function makeShortCutKey(tracer){
        let parts = tracer.substring(1).split('/')
        let output = ''
        for(let i=0; i<parts.length; i++){
          let node = parts[i]
          if(node!='home'){
            output += `['_pages']['${node}']`
          }else{
            output += node
          }
        }

        // console.log({tracer, parts, output})
        return output
      }


      function generatePagesArray(pages, trace=null){
        let items = []

        for(let p in pages){
          let page = pages[p]
          let tracer = trace ? `${trace}/${p}` : `/${p}`
          let item = {
            label: page.meta && page.meta.label && page.meta.label[language] ? '<b>'+page.meta.label[language]+'</b>'+': '+tracer : tracer,
            name: tracer,
            path: tracer,
            // shortCutKey: tracer ? tracer.substring(1).replaceAll('/','._pages.') : '',
            shortCutKey: makeShortCutKey(tracer),
            children: generatePagesArray(page._pages ? page._pages : [], tracer),
            public: page.meta.public
          }
          items.push(item)
        }

        items.sort((a, b) => {
            let fa = a.shortCutKey!=null && a.shortCutKey!=undefined && a.shortCutKey.length>0 ? a.shortCutKey.toLowerCase() : '',
                fb = b.shortCutKey!=null && b.shortCutKey!=undefined && b.shortCutKey.length>0 ? b.shortCutKey.toLowerCase() : ''

            if (fa < fb) {
                return -1;
            }
            if (fa > fb) {
                return 1;
            }
            return 0;
        });

        return items
      }

      return items
    },
    pageTemplate: function(){
      return {
            path: null,
            meta: {
              public: false,
              allowed: '*',
              label: {}
            },
            hideGlobalComponents: [],
            _pages: {}
          }
    },
    newPage: function(item){
      this.forms.edit_page.mode='new'
      this.temp.parent = item
      this.temp.page = this.pageTemplate()
      this.forms.edit_page.inputs = this.pageTemplate()
      this.ui.tab=0
      this.ui.edit_page.display = true
    },
    editPage: function(item){
      this.forms.edit_page.mode='edit'
      // let parentKey = item.shortCutKey.substr(0,item.shortCutKey.lastIndexOf('._pages'))
      let parentKey = item.shortCutKey.substr(0,item.shortCutKey.lastIndexOf("['_pages']"))
      this.temp.parent = this.searchTree(this.pageTree, parentKey)
      this.temp.page = item ? eval(`this.site.${item.shortCutKey}`) : this.site
      this.forms.edit_page.inputs.path = item.name.split('/')[item.name.split('/').length-1]
      this.forms.edit_page.inputs.name = this.temp.page.name
      this.forms.edit_page.inputs.hideGlobalComponents = this.temp.page.hideGlobalComponents ? this.temp.page.hideGlobalComponents : []
      if(this.temp.page.meta){
        this.forms.edit_page.inputs.meta = this.temp.page.meta
      }

      
      if(this.$route.query.edit && this.temp.page.definition){
        this.ui.tab=1
      }     
      this.ui.edit_page.display = true      
    },
    save: async function(){
      function makeShortCutKey(path){
        let parts = path.split('/')
        let output = ''
        for(let i=0; i<parts.length; i++){
          let node = parts[i]
          if(node.length>0){
            if(node!='home'){
              output += `['_pages']['${node}']`
            }else{
              output += node
            }
          }
        }

        // console.log({tracer, parts, output})
        return output
      }
      let self = this
      
      let data = {
        // page: this.forms.edit_page.inputs,
        page: {
          definition: this.temp.page.definition,
          meta: this.forms.edit_page.inputs.meta,
          enableFrench: this.temp.page.enableFrench
        },
        parent: this.temp.parent ? this.temp.parent.name : false
      }
      // data.page.definition = this.temp.page.definition

      let temp = this.temp
      let key = `${(temp.parent ? temp.parent.name : '')}/${this.forms.edit_page.inputs.path}`
      data.key = key.replace('/home','home').replaceAll('/','.')
      this.cache.storage.local.set('presave',data)
      let path = key.replace('/home/','/')
      if(path=='/home'){
        path = '/'
      }
      console.log('save',{data, key, path, shortCutKey: makeShortCutKey(key)})

      let response = await this.sendRequest(this.forms.edit_page.mode=='new' ? 'post' : 'patch',`/api/admin/pages`, data)

      if(response.status==200){
        await this.init()
        this.cache.storage.local.clear('presave')
        if(confirm('Jump to saved page?')){
          setTimeout(() => {
            console.log('navigating to',path)
            self.$router.push({path})
          }, 1000);
        }else{
          this.ui.edit_page.display = false
        }


      }else{
        console.error(response)
        alert('Error saving')
      }


    },
    deletePage: async function(item){
      let key = item.path = item.path.replace('/home','home').replaceAll('/','.')
      console.log('deletePage',{key, item})
      if(confirm(`Confirm deletion of: \r\n\r\n${item.name}\r\n\r\nThis page has ${item.children.length} children that will be removed`)){
        await this.sendRequest('delete','/api/admin/pages',{key})
        await this.init()
      }
    },
    restorePage: async function(item){
      let response = await this.sendRequest('post','/api/admin/pages/restore',item)
      if(response.status==200){
        await this.init()
        this.ui.restore = false
      }
    },
    selectVersion: async function(item){
      let response = await this.sendRequest('post','/api/admin/pages/version',{key: item.path.replaceAll('/home','home').replaceAll('/','.')})
      if(response.status==200){        
        this.ui.version = true
        this.versions = response.data
      }
    },
    setVersion: async function(item){
      let response = await this.sendRequest('put','/api/admin/pages/version',{record: item})
      if(response.status==200){
        // await this.init()
        this.ui.version = false
      }
    },
    searchTree: function(pageTree, parentKey){
      function search(pageTree, parentKey){
        for(let i=0; i<pageTree.length; i++){
          let branch = pageTree[i]

          if(branch.shortCutKey == parentKey){
            return branch
          }else if(branch.children){
            let subSearch = search(branch.children, parentKey)
            if(subSearch){
              return subSearch
            }
          }
        }
        return false
      }

      return search(pageTree, parentKey)

    },
    createDefinition: function(){
      function definitionTemplate(){
        return []
      }
      this.temp.page.definition = definitionTemplate()
      this.$forceUpdate()
    },
    edit: function(){
        let target = this._query.edit
        if(target){
          let tree = this.pageTree
  
          function findTarget(node,target){
            if(node.children && node.children.length>0){
              for(let i=0; i<node.children.length; i++){
                let result = findTarget(node.children[i],target)
                if(result){
                  return result
                }
              }
            }
            if(node.shortCutKey==target){
              return node
            }else{
              return false
            }
          }
  
          let item = findTarget(tree[0], target)
          this.editPage(item)
        }else{
          this.ui.edit_page.display = false
        }
    }
  },
  computed: {
    _level: function(){
        return this.temp.parent && this.temp.parent.name ? this.temp.parent.name.split('/').length-1 : 0
    },
    _query: function(){
      return this.$route.query
    },
    _editing: function(){
      return this.ui.edit_page.display
    },
    language: function(){
      return this.$store.getters.language
    }
  },
  watch: {
    _editing: function(){
      if(!this._editing){
        this.$router.push({query:{}})
      }
    },
    _query: {
      deep: true,
      handler: function(){
        this.edit()
      }
    }
  }
}
</script>

<style scoped lang="scss">
.active-version .v-list-item__title{
  color: green;
  font-weight: bold;
}

.public{
  color: green;
}
.notPublic{
  color: red;
}
</style>