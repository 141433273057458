import cache from './cache.js'

function pins(key, component){
    let self = this
    
    let data = {}

    this.get = async function(){
        let response = await component.sendRequest('get','/api/pins')
        if(response.status==200){
            return response.data
        }else{
            console.error(response)
        }
    }

    this.pin = async function(item){
        // if(!data){
        //     await self.get()
        // }
        let pins = cache.storage.local.get('pins')
        if(pins===null){
            pins = {}
        }
        if(!pins[key]){
            pins[key] = {}
        }
        pins[key][item.id] = !self.isPinned(item)
        cache.storage.local.set('pins',pins)
        if(component){
            component.$forceUpdate()
        }
    }

    this.isPinned = async function(item){
        // if(!data){
            // await self.get()
            let pins = cache.storage.local.get('pins')      
            if(pins && pins[key]){
                return pins[key][item.id] === true
            }else{
                return false
            }
        // }
        // return false
    }   

    async function init(){
        if(!component.$store.getters.pins){
            data = await self.get()
            component.$store.commit('pins',data)
            console.log('Committing PINS', data)
        }
    }

    // init()
}

export default pins