<template>
    <div class='icon'>
        <svg :data-src="`${$store.getters.bucket}${config.icon}`"/>
    </div>  
</template>

<script>
export default {
    props: {
        config: {
            type: Object,
            required: true
        }
    }
}
</script>

<style scoped lang="scss">
.icon{
    display: inline-block;
    border-radius: 100%;
    background-color: $primary;
    width: 120px;
    height: 120px;
    padding: 5px;
    cursor: pointer;
    margin-bottom: 15px;
    text-align: center;
}

.icon svg{
    position: relative;
    top: 50%;
    transform: translate(0, -50%);
    fill: white;
    width: 60%;
}
</style>