<template>
<div class="contact-us">
    <v-container>
        <v-row>
            <v-col>
                <v-form ref="form" @submit.prevent="false">
                    <v-row no-gutters>
                        <v-text-field :rules="rules.required" v-model="form.first_name" label="First Name:" class="mandatory d-inline-block" background-color="white" style="margin: 5px;" outlined/>
                        <v-text-field :rules="rules.required" v-model="form.last_name" label="Last Name:" class="mandatory d-inline-block" background-color="white" style="margin: 5px;" outlined/>
                    </v-row>
                    <v-row no-gutters>
                        <v-text-field :rules="rules.validEmail" v-model="form.email" label="E-mail Address:" class="mandatory" background-color="white" style="width: 96%; margin: 5px;" outlined/>                    
                    </v-row>
                    <v-row no-gutters>
                        <v-text-field :rules="rules.required" v-model="form.phone_number" label="Phone Number:" class="mandatory d-inline-block" background-color="white" style="margin: 5px;" outlined/>
                        <v-text-field v-model="form.organization" label="Organization:" class="d-inline-block" background-color="white" style="margin: 5px;" outlined/>
                    </v-row>
                    <v-row no-gutters>
                        <v-textarea :rules="rules.required" v-model="form.message_content" label="Ask a Question:" class="mandatory" background-color="white" clearable clear-icon="mdi-broom" style="margin: 5px;" rows="3" outlined/>
                    </v-row>
                    <v-row no-gutters id="consent-wrapper">
                        <v-checkbox :rules="rules.required" v-model="form.consent" true-value="yes" false-value="no" label="I understand that by submitting my contact information, I am providing Thrombosis Canada with express consent
to communicate with me via email and/or telephone" style="margin: 5px;"></v-checkbox>                           
                    </v-row>
                    <v-row no-gutters>
                        <template>
                            <vue-recaptcha ref="recaptcha" @verify="onVerify" :sitekey="recaptchaSiteKey" style="margin: 5px;"></vue-recaptcha>
                        </template>
                    </v-row>
                    <v-row no-gutters class="mt-5">
                        <v-btn :disabled="!allowSubmission()" class="themed-button-dark" elevation="0" @click="submit" style="margin: 5px;">Submit</v-btn>
                    </v-row>
                </v-form>            
            </v-col>    
        </v-row>
    </v-container>
    
    <v-snackbar v-model="snackbar.display" rounded="pill">
        <span v-html="snackbar.message"/>
    </v-snackbar>    
</div>
</template>

<script>
import validations from '@/plugins/validations.js'
import { VueRecaptcha } from 'vue-recaptcha';
export default {
    name: "contact-us",
    props: {
        language: {
            type: String,
            required: false,
            default: "en-ca"
        }
    },
    components: { 
        VueRecaptcha
    },
    created: function(){
        this.validations = new validations.methods(this)
        this.rules = new validations.rules(this)
    },
    data: function(){
        return {
            form: {
                email: null,
                first_name: null,
                last_name: null,
                phone_number: null,
                organization: null,
                message_content: null,
                consent: 'no',
                robot: false
            },
            snackbar: {
                display: false,
                message: null
            }
        }
    },
    methods: {
        onVerify: function (response) {
            if (response) this.form.robot = true
        },
        submit: async function(){
            let data = this.form
            data['username'] = this.$store.getters.user!==undefined ? this.$store.getters.user.username : null
            this.$refs.recaptcha.reset();
            let response = await this.sendRequest('post','/api/contact_us/',data)
            if (response.data.affectedRows && response.data.insertId) {
                this.snackbar.message = this.$store.getters.labels.contact_us.success.sent._text[this.language]+" "+this.$store.getters.labels.contact_us.success.response_time._text[this.language]
                this.snackbar.display = true
                this.$refs.form.reset()
            }
            else {
                this.snackbar.message = "There was a technical issue delivering your message. Please try again later."
                this.snackbar.display = true
                this.$refs.form.reset()
            }
        },        
        allowSubmission: function(){
            let form = this.form
            let tests = {
                validEmail: form.email!==null && this.validations.validEmail(form.email),
                first_name: form.first_name!==null && form.first_name.length>=1,
                last_name: form.last_name!==null && form.last_name.length>=1,
                phone_number: form.phone_number!==null && form.phone_number.length>=7,
                message_content: form.message_content!==null && form.message_content.length>=1,
                consent: form.consent==='yes',
                robot: form.robot                
            }
            function allTrue(object){
                for(let key in object){
                    if(!object[key]){
                        return false
                    }
                }
                return true
            }
            
            return allTrue(tests)
        },
    },
    computed: {
        recaptchaSiteKey: function(){ 
            return process.env.VUE_APP_GOOGLE_RECAPTCHA_SITE_KEY
        }
    }
}
</script>

<style lang="scss" scoped>
::v-deep .mandatory {
    label::before {
        content: "*  ";
        color: red;
    }
}

::v-deep #consent-wrapper {
    .v-input--selection-controls__input {
        align-self: flex-start;    
    }
}
</style>