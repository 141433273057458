<template>
  <div>
    <div v-if="ready">
        <v-text-field label="Path to stories page" v-model="config.storiesPath"/>
        <v-switch v-model="config.cycle" :true-value="true" :false-value="false" label="cycle"/>
        <v-switch v-model="config.flow" true-value="reverse" false-value="default" label="Reverse"/>
        <v-select v-model="config.theme" :items="['darkBlue','lightBlue','white','red']" label="Theme"/>
        <v-container fluid>
            <v-row>
                <v-col>
                    Select stories for carousel
                    <v-checkbox v-for="(record, index) in stories" :key="`story-checkbox-${index}`" v-model="config.stories" :value="record.id">
                        <template v-slot:label>
                            <div>
                                {{record.story.title[language]}}
                                <br>
                                <sup>{{record.story.subtitle[language]}}</sup>
                            </div>
                        </template>
                    </v-checkbox>
                </v-col>
                <v-col cols="7">
                    Drag to reorder
                        <v-list dense>
                        <draggable v-model="config.stories">
                            <v-list-item v-for="(recordID) in config.stories" :key="`story-item-${recordID}`" :class="[{'published':_stories[recordID].publish=='Y'},{'unpublished':_stories[recordID].publish=='N'}]" style="border-bottom: 1px solid grey;">
                                <v-list-item-action>
                                    <v-icon>
                                        mdi-menu
                                    </v-icon>
                                </v-list-item-action>
                                <v-list-item-title>
                                    {{_stories[recordID].story.title[language]}}
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                    {{_stories[recordID].story.subtitle[language]}}
                                </v-list-item-subtitle>
                            </v-list-item>
                        </draggable>
                        </v-list>
                </v-col>
            </v-row>
        </v-container>
        <center>
            <v-btn @click="save">Save</v-btn>
        </center>
    </div>
    <div v-else>
        ...loading...
    </div>
    
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import TinyEditor from '@tinymce/tinymce-vue'
import Browser from '@/components/Assets/Manager'
export default {
    name: 'StoriesEditor',    
    created: async function(){
        this.tinyConfig.apiKey = process.env.VUE_APP_TINYMCE_API_KEY
        let config = JSON.stringify(this._config)
        config = JSON.parse(config)
        if(!config){
            config = {
                stories: [],
                cycle: true,
                interval: 6000,
                storiesPath: null
            }
        }
        this.stories = await this.getStories()
        this.config = config
        let self = this
        setTimeout(() => {
            self.ready = true
        }, 800);
    },
    components: {
        draggable,
        TinyEditor,
        Browser
    },
    data: function(){
        return {
            ready: false,
            tinyConfig: {
                init: {
                    selector: 'textarea',
                    plugins: 'code fullscreen anchor autolink charmap codesample emoticons image media link lists searchreplace table visualblocks wordcount',
                    toolbar: 'customExitButton | undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat | fullscreen | code',
                    height: "800"
                },
                apiKey: null
            },
            ui: {
                activeTab: 0,
                selectImage: false
            },
            config: null,
            editing: {
                index: null,
                story: null
            },
            stories: []
        }
    },
    methods: {
        getStories: async function(){
            let response = await this.sendRequest('get','/api/stories')
            if(response.status==200){
                return response.data
            }else{
                console.error('getStories',response)
                return []
            }
        },
        selectImage: function(data){
            navigator.clipboard.writeText(`${this.$store.getters.bucket}/${data.Key}`);
            setTimeout(function(){
                alert('Copied to clipboard')
            },200)            
        },
        closeEditor: function(){
            this.editing = {
                index: null,
                story: null
            }
        },
        storyTemplate: function(){
            let languages = this.languages
            function blank(languages){
                let output = {}
                for(let i=0; i<languages.length; i++){
                    let language = languages[i]
                    output[language] = null
                }
                return output
            }
            return {
                title: blank(languages),
                subtitle: blank(languages),
                content: blank(languages),
                quote: blank(languages),
                image: blank(languages),
                flow: 'default',
                theme: 'darkBlue'
            }
        },
        addStory: function(){
            let story = this.storyTemplate()
            this.config.stories.push(story)
        },
        removeStory: function(index){
            if(confirm('Confirm Delete')){
                if(this.editing.index == index){
                    this.closeEditor()
                }
                this.config.stories.splice(index,1)
            }
        },
        editStory: function(index){
            // let story = JSON.stringify(this.config.stories[index])
            // story = JSON.parse(story)
            // this.editing.story = JSON.parse(story)
            let story = this.config.stories[index]
            this.editing.story = story
            this.editing.index = index
        },
        save: async function(){
            let config = this.config
            let response = await this.sendRequest('put','/api/componentConfigurations',config)
        }
    },
    computed: {
        language: function(){
            return this.$store.getters.language
        },
        languages: function(){
            return this.$store.getters.languages
        },
        _config: function(){
            return this.$attrs.value
        },
        _stories: function(){
            let output = {}
            let stories = this.stories
            for(let i=0; i<stories.length; i++){
                let story = stories[i]
                output[story.id] = story
            }
            return output
        }
    },
    watch: {
        config: {
            deep: true,
            handler: function(){
                let ready = this.ready
                if(ready){
                    let config =  this.config 
                    this.$emit('input',config)
                }

            }
        }
    }    
}
</script>

<style scope lang="scss">
.published{
  background-color: none;
}
.unpublished{
  background-color: lightgray;
}
.unpublished .v-list-item__title, .unpublished .v-list-item__subtitle{
  color: grey !important;
}
</style>