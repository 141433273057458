<template>
  <div class="page-banner">
    <v-img :src="`${resolveBackground()}`" style="width: 100%;" v-if="ready"/>
    <!-- <div class='page-banner-content' :style="{'background-image':`URL('${resolveBackground()}')`}">
        <div class="page-name" v-if="page && page.name" v-html="page?.name[language] ? page.name[language] : 'Missing Page Name'"/>
        <div class="page-name">
            <str :index="$route.name"/>
        </div>
    </div> -->
    <div class="page-crumbs">
        <template v-for="(crumb, routeIndex) in crumbs">
            <span :key="`crumb_${routeIndex}`">
                <v-icon v-if="crumb.label!='Home'">mdi-arrow-right-thin</v-icon> 
                <v-btn text @click="nav(crumb.route)" :class="{'selected':routeIndex==crumbs.length-1}">
                    {{crumb.label}}
                </v-btn>
            </span>
        </template>
    </div>        
  </div>
</template>

<script>
// import about_us from '../assets/about_us.png'
// import hcp from '../assets/hcp.png'
// import patients_and_caregivers from '../assets/patients_and_caregivers.png'
// import news from '../assets/news.png'
// import get_involved from '../assets/get_involved.png'

export default {
    name: 'PageBanner',
    props: {
        language: {
            type: String,
            required: true
        },
        page: {
            type: Object,
            required: true
        },
        config: {
            type: Object,
            required: true
        },
        site: {
            type: Object,
            required: true
        }
    },
    mounted: function(){
        this.$emit('tags',{component: this.$options.name, tags: {}})
        this.ready = true
    },
    data: function(){
        return {
            ready: false
        }
    },
    methods: {
        nav: function(route){
            // console.log('routing to',{route, target: {path:route.path.length>0 ? route.path : '/'}})
            if(route.path=='/404'){
                this.$router.push({path:'/'})
            }else{
                this.$router.push({path:route.path.length>0 ? route.path : '/'})
            }
        },
        resolveBackground: function(){
            // let paths = this.$route.path.split('/')
            // for(let i=0; i<paths.length; i++){
            //     let path = paths[i]
            //     if(this.images[path]){
            //         return this.images[path]
            //     }
            // }
            // return null
            return `${this.$store.getters.bucket}/${this._config.background[this.language].replaceAll(' ','%20')}`
        }
    },
    computed: {
        // images: function(){
        //     return {
        //         about_us,
        //         hcp,
        //         news,
        //         patients_and_caregivers,
        //         get_involved
        //     }
        // },
        crumbs: function(){
            let language = this.language
            let matched = this.$route.matched
            let output = [
                {
                    label: 'Home',
                    route: this.$router.getRoutes()[0]
                }
            ]
            for(let i=0; i<matched.length; i++){
                let route = matched[i]
                if(route.path.indexOf(':')<0){
                    let path = route.path.split('/')
                    let length = path.length
                    let label = route.meta.label ? route.meta.label[language] ? route.meta.label[language] : route.meta.label['en-ca'] ? route.meta.label['en-ca'] : path[length-1] : path[length-1]
                    output.push({
                        label,
                        route
                    })
                }
            }
            return output
        },
        _config: function(){
            function buildString(array){
                let string = ''
                for(let f=0; f<array.length; f++){
                    let value = array[f]
                    if(value.length>0){
                        string+=`['${value}']`
                    }
                }
                return string
            }

            let matched = this.$route.matched
            let isAdmin = matched.filter((route)=>{return route.name=='Admin'}).length>0
            if(isAdmin){
                return this.config
            }else{
                let site = this.site
                let page
                let tree = []
                for(let i=0; i<matched.length; i++){
                    let path = matched[i].path
                    let trace = this.$route.name=='Admin' ? '.'+this.$route.query.edit : '.home'+path.replaceAll('/', this.$route.path=='/' ? '' : '._pages.')
                    let fallbackArray = trace.split('._pages')
                    let fallback = trace.indexOf(':')<0 && fallbackArray[1] ? `.home._pages${fallbackArray[1]}` : '.home'


                    let traceTemp = trace.split('.')
                    let traceString = buildString(traceTemp)

                    let fallbackTemp = fallback.split('.')
                    let fallbackString = buildString(fallbackTemp)
                    
                    page = trace.indexOf(':')<0 ? eval(`site${traceString}`) : eval(`site${fallbackString}`)
                    
                    if(!page){
                        page = site.home
                    }else{
                        tree.push(page)
                        let loop = page.definition ? page.definition.length : 0
                        for(let c=0; c<loop; c++){
                            let definition = page.definition[c]
                            if(definition.component == this.$options.name && (!definition.config || definition.config?.inherit)){
                                let inheretFromPage = tree[i>0?i-1:0]
                                let searchResults = inheretFromPage.definition.filter((item)=>{return item.component==definition.component})
                                page.definition[c] = searchResults[0]
                            }
                        }
                    }
                }
                // let results = page.definition.filter((item)=>{return item.component==this.$options.name})

                
                let configs = []
                for(let i=0; i<tree.length; i++){
                    let branch = tree[i]
                    let config = branch.definition.filter((item)=>{return item.component==this.$options.name})
                    if(config[0]){
                        configs.push(config[0])
                    }
                }
                for(let i=configs.length-1; i>=0; i--){
                    let pageBanner = configs[i]
                    if(!pageBanner.config.inherit){
                        return pageBanner.config
                    }
                }
                let results = tree[0].definition.filter((item)=>{return item.component==this.$options.name})
                if(results[0]){
                    return results[0].config
                }else{
                    console.error('Missing config for: '+this.$options.name,{results})
                }
                // console.error('Missing config for: '+this.$options.name,{configs, tree})
            }

        } 
    },
    watch: {
        _config: {
            deep: true,
            handler: function(){                
                let self = this
                self.ready = false
                clearTimeout(self.refresh)
                self.refresh = setTimeout(function(){
                    self.ready = true
                },200)
            }
        }
    }
}
</script>

<style scoped lang="scss">
.page-banner{
    border: 1px solid lightgrey;
    background-color: whitesmoke;
}
.page-banner-content{
    width: 100%;
    // height: 600px;
    position: relative;
    background-size: cover;
    background-position: center;
    color: $primary;
    padding-left: 20%;
}

.page-name{
    text-align: left;
    text-transform: uppercase;
    font-size: 3em;
    font-weight: bold;
}

.page-crumbs{
    // position: absolute;
    bottom: 0px;
    width: 100%;
    text-align: left;
    padding-left: 15%;
}
.page-crumbs button{
    font-size: 0.6em;
}

.selected{
    text-decoration: underline;
    text-decoration-thickness: 3px;
    text-underline-offset: 6px;
    text-decoration-color: $tc-red;
}
</style>