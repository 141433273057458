<template>
  <div class="sitemap">
    <v-card>
        <v-card-title>
            Index
        </v-card-title>
        <v-card-text>
            <!-- <json-viewer :value="siteMap"/> -->
            <pre v-if="xml" v-text="xml"/>

            <v-btn v-if="xml" @click="save">Save</v-btn>
        </v-card-text>
        <v-card-actions>
            <v-btn @click="generateSiteMap">Generate Site Map</v-btn>
        </v-card-actions>
    </v-card>
    
    <json-viewer v-if="siteMap.json" :value="{json: siteMap.json, config}"/>
  </div>
</template>

<script>
import Page from './page.vue'
import convert from "xml-js"

export default {
    props: {
        site: {
            type: Object,
            required: true
        }
    },
    created: function(){
        this.generateSiteMap()
    },
    components: {
        Page
    },
    data: function(){
        return {
            ready: false,
            xml: null,
            siteMap: {}
        }
    },
    methods: {
        allowed: function(meta){
            if(meta.blockSiteMap!=undefined && meta.blockSiteMap===true){
                return false
            }            
            let user = this.$store.getters.user
            let groups = user ? user.signInUserSession.accessToken.payload['cognito:groups'] ? user.signInUserSession.accessToken.payload['cognito:groups'] : [] : []
            let allowed = meta.allowed
            for(let i=0; i<groups.length; i++){
                let group = groups[i]
                if(allowed.includes(group)){
                    return true
                }
            }
            return meta.public
        },
        generateSiteMap: function(){
            let self = this
            let site = this.site
            let config = this.config
            function makeMonth(num){
                let month = num+1
                if(month<10){
                    return `0${month}`
                }else{
                    return month
                }
            }
            function getPages(page, page_key, elements){
                for(let key in page._pages){
                    let path = `${page_key}/${key}`
                    let allowed = self.allowed(page._pages[key].meta)
                    if(allowed){
                        let lastmod = new Date(page.meta.lastmod)
                        elements.push({
                            type: "element",
                            name: "url",
                            elements: [
                                {
                                    type: "element",
                                    name: "loc",
                                    elements: [
                                        {
                                            type: "text",
                                            text: `${config.base}${path}`
                                        }
                                    ]
                                },
                                {
                                    type: "element",
                                    name: "lastmod",
                                    elements: [
                                        {
                                            type: "text",
                                            text: `${lastmod.getFullYear()}-${makeMonth(lastmod.getMonth())}-${lastmod.getDate()<10 ? '0'+lastmod.getDate() : lastmod.getDate()}`
                                        }
                                    ]
                                }
                            ]
                        })
                        getPages(page._pages[key], path, elements)
                    }
                }
            }

            let json = {
                declaration: {
                    attributes: {
                        version: "1.0",
                        encoding: "UTF-8"
                    }
                },
                elements: [
                    {                        
                        type: "element",
                        name: "urlset",
                        attributes: {
                            xmlns: "http://www.sitemaps.org/schemas/sitemap/0.9"
                        },
                        elements: []
                    }
                ]
            }

            // getPages(site.home, '', json.elements[0].elements[0].elements)
            getPages(site.home, '', json.elements[0].elements)

            for(let lang in config.clinical_guides.data){
                let guides = config.clinical_guides.data[lang]
                for(let i=0; i<guides.length; i++){
                    let guide = guides[i]
                    let path = `${config.base}${config.clinical_guides.path}?language=${lang}&guideID=${guide.id}`
                    let d = new Date(guide.dateUpdated)
                    let lastmod = `${d.getFullYear()}-${makeMonth(d.getMonth())}-${d.getDate()<10 ? '0'+d.getDate() : d.getDate()}`
                    json.elements[0].elements.push({
                            type: "element",
                            name: "url",
                            elements: [
                                {
                                    type: "element",
                                    name: "loc",
                                    elements: [
                                        {
                                            type: "text",
                                            text: path
                                        }
                                    ]
                                },
                                {
                                    type: "element",
                                    name: "lastmod",
                                    elements: [
                                        {
                                            type: "text",
                                            text: lastmod
                                        }
                                    ]
                                }
                            ]
                        })
                }
            }

            let options = {compact: false, ignoreComment: true, spaces: 4}
            let xml = convert.js2xml(json, options)
            this.xml = xml
            this.siteMap = {
                json,
                xml
            }
            this.save()
        },
        save: async function(){
            let xml = this.xml//.replaceAll('&amp;','&')
            let data = {xml}
            let response = await this.sendRequest('post',`/api/admin/sitemap`, data)
            console.log('sitemap save', response)
        }
    },
    computed: {
        config: function(){
            return {
                base: 'https://thrombosiscanada.ca',
                articles: {
                    path: '/news_and_events/article'
                },
                clinical_guides: {
                    path: '/hcp/practice/clinical_guides',
                    data: {
                        'en-ca': this.$store.getters.clinicalGuideData ? this.$store.getters.clinicalGuideData['en-ca'].guides : [],
                        'fr-ca': this.$store.getters.clinicalGuideData ? this.$store.getters.clinicalGuideData['fr-ca'].guides : []
                    }
                }
            }
        },
        language: function(){
            return this.$store.getters.language
        },
        _tree: function(){
            let tree = []

            function objectLength(object){
                let length = 0
                for(let key in object){
                    length++
                }
                return length
            }

            function buildTree(tree, site){
                for(let pageName in site){
                    let page = site[pageName]
                    tree.push({
                        name: pageName,
                        definition: page.definition,
                        meta: page.meta,
                        children: []
                    })

                    if(objectLength(page._pages)>0){
                        buildTree(tree[tree.length-1].children, page._pages)
                    }
                }
            }

            buildTree(tree, this.site)

            return tree
        }
    },
    watch: {
        site: {
            deep: true,
            handler: function(){
                console.log('site updated')
                this.save()
            }
        }
    }
}
</script>

<style scoped lang="scss">
.sitemap{
    text-align: left;

    .container{
        display: flex;
        flex-flow: column;
        column-width: 200px;
        font-size: 1em;
        padding: 0 0 16px;
    }
}

</style>

<style lang="scss">

.sitemap ul{
    list-style: none;
}
.sitemap ul li::before {
  content: "\2022";
  color: $tc-red;
  font-weight: bold;
  display: inline;
  width: 1em;
  margin-left: -1em;
  font-size: 1.2em;
  position: relative;
  top: 2px;
}

.private{
    color: red
}
</style>
