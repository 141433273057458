<template>
  <v-card style="text-align: left;" flat>
    <v-card-actions>
      <v-btn text @click="getAssets">
        <v-icon>mdi-reload</v-icon> Refresh
      </v-btn>      
    </v-card-actions>

    <v-card-text class="pa-0">
      <template v-if="ready">
        <Gallery :search="search" :assetTree="assetTree" :assets="assets" @selectItem="selectItem" @setPath="setPath" :initPath="path"/>
        <template v-if="duplicates.length>0">
          <h3>Duplicate Assets Detected</h3>
          <v-switch v-model="ui.showDuplicates" :true-value="true" :false-value="false" label="Show Duplicates"/>

          <div v-if="ui.showDuplicates">
            <v-btn v-if="ui.duplicateFocus!=null" small @click="ui.duplicateFocus=null">Show all duplicates</v-btn>
            <v-list>
              <v-list-item v-for="(item, index) in duplicates" :key="index" v-show="ui.duplicateFocus===null || ui.duplicateFocus==index">
                  <v-list-item-content>
                    <v-list-item-title>
                      Uploaded {{item.counter}} times
                    </v-list-item-title>
                    <ul>
                      <li v-for="(itemKey, itemIndex) in item.file" :key="itemIndex">
                        <v-btn x-small text @click="duplicateSearch(itemKey, index)">{{ itemKey }}</v-btn>
                      </li>
                    </ul>
                  </v-list-item-content>
                </v-list-item>
            </v-list>
          </div>

        </template>
      </template>
      <div v-else>...loading...</div>
    </v-card-text>
    
  </v-card>
</template>

<script>
import Gallery from './Gallery.vue'
export default {
  components: {
    Gallery
  },
  created: function(){
    this.getAssets()
    let path = JSON.stringify(this.initPath)
    this.path = JSON.parse(path)
  },
  props: {
    search: {
      type: String,
      required: false
    },
    openAll: {
      type: Boolean,
      required: false,
      default: true
    },
    base: {
      type: String,
      required: false,
      default: 'uploads'
    },
    endPoint: {
      type: String,
      required: false,
      default: '/api/admin/assets'
    },
    initPath: {
      type: Array,
      required: false,
      default: ()=>{return [0]}
    }
  },
  data: function(){
    return {
      bucket: null,
      assets: [],
      assetTree: [],
      caseSensitive: false,
      ready: false,
      path: [0],
      duplicates: [],
      usedDuplicates: {},
      ui: {
        showDuplicates: false,
        duplicateFocus: null
      }
    }
  },
  methods: {
    getSiteDefinition: async function(){
      let response = await this.sendRequest('get','/api/admin/getSiteDefinition')
      let duplicates = this.duplicates
      let output = {}
      if(response.status==200){
        let definitions = response.data
        for(let i=0; i<duplicates.length; i++){
          let record = duplicates[i]
          for(let ii=0; ii<record.file.length; ii++){
            let file = record.file[ii]
            let pages = []
            for(let p=0; p<definitions.length; p++){
              let page = definitions[p]
              let string = JSON.stringify(definitions[p])
              if(string.indexOf(file)>=0){
                pages.push(page)
              }
            }
            if(pages.length>0){
              output[file] = pages
            }
          }
        }
      }
      this.usedDuplicates = output
    },
    getAssets: async function(){
        this.ready = false
        let response = await this.sendRequest('get',this.endPoint)
        if(response.status==200){
            this.assets = response.data.contents
            this.bucket = response.data.bucket
            this.$emit('setBucket',this.bucket)
            this.rebuildTree()            
        }
        this.ready = true
    },
    rebuildTree: function(){
      this.assetTree = this.buildTree(this.assets, 'Key', '/' ,'children')
    },
    buildTree: function(flatArray, keyField, delimiter, childField)  {
      let tree = [
        {
          Key: this.base,
          children: []
        }
      ]
      
      function insertAsset(tree, node, keyField, delimiter, childField, debug){
        let stack = node.Key.replace(/\/$/, "").split(delimiter)
        let isFile = node.Size>0        

        function branchPointer(array){
          let output = ""
          for(let i=0; i<array.length; i++){
            let value = array[i]
            output += `[${value}]['${childField}']`
          }
          return output
        }

        function assetPointer(array){
          let output = ""
          for(let i=0; i<array.length; i++){
            let value = array[i]
            if(i==array.length-1){
              output += `[${value}]`
            }else{
              output += `[${value}]['${childField}']`
            }
          }
          return output
        }

        function tracePointer(array, Key, trace){
          let index = array.findIndex((item)=>{return item.Key==Key})
          
          if(index<0){
            let record = {}
            record[keyField] = Key,
            record[childField] = []
            array.push(record)
            index = array.findIndex((item)=>{return item.Key==Key})
          }
          
          trace.push(index)
        }

        let trace = []
        for(let i=0; i<stack.length; i++){
          let Key = stack[i]
          let pointerString = branchPointer(trace)
          let branch = eval(`tree${pointerString}`)

          tracePointer(branch, Key, trace)
          if(i==stack.length-1){
            pointerString = assetPointer(trace)
            let target = eval(`tree${pointerString}`)
            target.asset = node
          }
        }
      }






      let index = {}
      //Add each S3 asset
      for(let i=0; i<flatArray.length; i++){
        let node = flatArray[i]

        let debugThis = [
          'uploads/hcp/education/cpd/Header-Program-940x126.jpg'
        ]
        let debug = debugThis.includes(node.Key)

        let isFile = node.Size && node.Key.indexOf('.')>=0
        
        if(node.Key.indexOf('.')>=0){
          if(typeof index[node.ETag] == 'undefined'){
            index[node.ETag] = {
              counter: 1,
              file: []
            }
          }else{
            index[node.ETag].counter++
          }
          index[node.ETag].file.push(node.Key)
        }

        insertAsset(tree, node, keyField, delimiter, childField, debug)
      }


      let duplicates = []
      for(let tag in index){
        let item = index[tag]
        if(item.counter>1){
          duplicates.push(item)
        }
      }
      this.duplicates = duplicates



      return tree

    },
    buildTree_og: function(flatArray, keyField, delimiter, childField)  {
      let tree = [
        {
          Key: this.base,
          children: []
        }
      ]
      
      function insertAsset(tree, node, keyField, delimiter, childField){
        let debugThis = [
          'uploads/Divider Banner (4).png',
          'uploads/images/Divider Banner (4).png'
        ]
        let debug = debugThis.includes(node.Key)
        
        
        function searchTree(tree, key){
          let match = tree.filter((item)=>{return item[keyField]==key})

          if(match.length==0){
            for(let i=0; i<tree.length; i++){
              let branch = tree[i]
              let result = searchTree(branch[childField], key)
              if(result){
                return result
              }
            }
          }

          if(match.length==1){
            return match[0]
          }

          return false

        }

        let keyString = node[keyField].replace(/\/$/, "")
        let stack = keyString.split(delimiter)

        let trace = []
        for(let depth=0; depth<stack.length; depth++){
          let key = stack[depth]
          let result = searchTree(tree, key, keyField)

          if(result){
            trace.push(result)
          }else{
            let record = {}
            record[keyField] = key
            record[childField] = []
            if(depth==stack.length-1){
              record.asset = node
            }
            trace[trace.length-1][childField].push(record)

            insertAsset(tree, node, keyField, delimiter, childField)
            break;
          }
        }




      }

      function findNode(array, node, keyField, delimiter, childField, search=null, trace=null){
        let keyString = node[keyField].replace(/\/$/, "")
        let searchStack
        if(!search){
          searchStack = keyString.split(delimiter)
        }else{
          searchStack = search.searchStack
        }

        let debugThis = [
          'uploads/Divider Banner (4).png',
          'uploads/images/Divider Banner (4).png'
        ]
        let debug = false //debugThis.includes(node.Key)

        let result
        for(let stackDepth = search ? search.stackDepth+1 : 0; stackDepth<searchStack.length; stackDepth++){
          let key = searchStack[stackDepth]   

          if(key.length>0){
            for(let i=0; i<array.length; i++){
              let record = array[i]
              let recordKey = record[keyField]
              if(recordKey == key){
                if(stackDepth==searchStack.length-1){
                  return record
                }else if(record.children){
                  result = findNode(record.children, node, keyField, delimiter, childField, {searchStack, stackDepth}, trace!=null ? `${trace}[${i}]` : `[${i}]`)
                }else{
                  return false
                }
              }
            }
          }
        }


    
        return result
      }

      let index = {}
      //Add each S3 asset
      for(let i=0; i<flatArray.length; i++){
        let node = flatArray[i]
        let result = findNode(tree, node, keyField, delimiter, childField)
        let isFile = node.Size && node.Key.indexOf('.')>=0
        
        if(node.Key.indexOf('.')>=0){
          if(typeof index[node.ETag] == 'undefined'){
            index[node.ETag] = {
              counter: 1,
              file: {}
            }
            index[node.ETag].file[node.Key] = result
          }else{
            index[node.ETag].counter++
            index[node.ETag].file[node.Key] = result
          }      
        }


        
        
        if(!result || isFile){

          insertAsset(tree, node, keyField, delimiter, childField)
        }
      }


      let duplicates = []
      for(let tag in index){
        let item = index[tag]
        if(item.counter>1){
          duplicates.push(item)
        }
      }     



      return tree

    },
    selectItem: function(item){
      // console.log('selectAsset',item)
      if(item.asset){
        this.$emit('selectAsset',item.asset)
        this.$emit('input',item.asset)
      }else{
        this.$emit('selectAsset',item)
        this.$emit('input',item)

      }
    },
    setPath(data){
        // console.log('Browser > setPath', data)
        this.$emit('setPath',data)
    },
    duplicateSearch: function(key, duplicateIndex){
      console.log('duplicateSearch',{key, duplicateIndex})
      this.ui.duplicateFocus = duplicateIndex
      this.$emit('duplicateSearch', key)
      
    }
  },
  computed: {
    // _assets: function(){
    //     function template(){
    //         return {
    //             _folders: {},
    //             _assets: []
    //         }
    //     }
    //     let stats = {
    //         folders: 0,
    //         files: 0,
    //         size: 0,
    //         types: {}
    //     }
    //     let output = {
    //         uploads: template()
    //     }
    //     let assets = this.assets

    //     console.log('_assets',assets)
        
    //     for(let i=0; i<assets.length; i++){
    //         let asset = assets[i]
    //         let trace = asset.Key.split('/')
    //         let isFolder = trace[trace.length-1]==''
    //         if(isFolder){
    //             stats.folders++
    //             let path = ''
    //             for(let t=0; t<trace.length-1; t++){
    //                 let value = trace[t]
    //                 let container = path+'.'+value
    //                 let target = eval("output"+container)
    //                 path += '.'+value+'._folders'
    //                 if(target==undefined){
    //                     console.log('creating container', container)
    //                     eval("output"+container+"=template()")
    //                 }
    //             }
    //         }
    //     }

        
    //     for(let i=0; i<assets.length; i++){
    //         let asset = assets[i]
    //         let trace = asset.Key.split('/')
    //         let isFolder = trace[trace.length-1]==''
    //         if(!isFolder){
    //             stats.files++
    //             stats.size+=asset.Size
    //             let parts = trace[trace.length-1].split('.')
    //             let type = parts[parts.length-1].toUpperCase()
    //             if(!stats.types[type]){
    //                 stats.types[type] = 0
    //             }else{
    //                 stats.types[type]++
    //             }
    //             let path = ''
    //             for(let t=0; t<trace.length-1; t++){
    //                 let value = trace[t]
    //                 let container = path+'.'+value
    //                 let target = eval("output"+container)
    //                 path += '.'+value+'._folders'
    //                 if(!target){
    //                     eval("output"+container+"=template()")
    //                     target = eval("output"+container)
    //                 }
    //                 target._assets.push(asset)
    //             }
    //         }
    //     }

    //     return {
    //         stats,
    //         assets: output
    //     }
    // },
    _duplicates: function(){
        let output = {}
        let assets = this.assets
        for(let i=0; i<assets.length; i++){
            let asset = assets[i]
            let trace = asset.Key.split('/')
            let isFolder = trace[trace.length-1]==''
            if(!isFolder && !output[asset.ETag]){
                let matches = assets.filter((item)=>{return item.ETag==asset.ETag})
                if(matches.length>1){
                    output[asset.ETag] = matches
                }
            }
        }

        return output
    },
    _filter: function(){
        return this.caseSensitive
        ? (item, search, textKey) => item[textKey].indexOf(search) > -1
        : undefined
    }
  }
}
</script>

<style>

</style>