import Vue from 'vue'
// import VueMeta from 'vue-meta'
import VueRouter from 'vue-router'
import { Auth } from 'aws-amplify';
let cache = require('@/plugins/cache.js')
import Render from '../views/Render.vue'

Vue.use(VueRouter)
// Vue.use(VueMeta)

let routes = [
  {
    path: '*',
    name: '404',
    component: () => import('../views/404.vue'),
    meta: {
      public: true,
      allowed: '*'
    }
  },
  {
    path: '/404',
    name: '404',
    component: () => import('../views/404.vue'),
    meta: {
      public: true,
      allowed: '*'
    }
  },
  {
    path: '/',
    name: 'Home',
    component: Render,
    meta: {
      public: true,
      allowed: '*'
    }
  },
  {
    path: '/verify',
    meta: {
      public: true,
      allowed: '*'
    },
    component: () => import('../components/Authorizer/verification.vue'),
    children: [
      {
        name: 'Verify',
        path: '/verify/:code',
        meta: {
          public: true,
          allowed: '*'
        }
      }
    ]
  },,
  {
    path: '/resource',
    component: () => import('../views/Resource.vue'),
    name: 'Get Resource',
    meta: {
      label: {'en-ca':'Get Resource','fr-ca':'Get Resource'},
      public: true,
      allowed: '*'
    },
    children: [
      {
        path: '/resource/:resourceId',
        meta: {
          label: {'en-ca':'Get Resource','fr-ca':'Get Resource'},
          public: true,
          allowed: '*'
        },
        children: [
          {
            path: '/resource/:resourceId/:language',
            meta: {
              label: {'en-ca':'Get Resource','fr-ca':'Get Resource'},
              public: true,
              allowed: '*'
            }
          }
        ]
      }
    ]
  }
  // {
  //   path: '/stories',
  //   name: 'Stories',
  //   component: Render,
  //   meta: {
  //     public: true,
  //     allowed: '*'
  //   },
  //   children: [
  //     {
  //       path: '/stories/:story_id',
  //       component: Render,
  //       meta: {
  //         public: true,
  //         allowed: '*'
  //       }
  //     }
  //   ]
  // }, 
  // {
  //   path: '/hcp',
  //   component: Render,
  //   meta: {
  //     public: true,
  //     allowed: '*'
  //   },
  //   children: [
  //     {
  //       path: '/hcp/practice',
  //       meta: {
  //         public: true,
  //         allowed: '*'
  //       },
  //       children: [
  //         {
  //           path: '/hcp/practice/clinical_guides',
  //           name: 'ClinicalGuidesSelection',
  //           meta: {
  //             public: true,
  //             allowed: '*'
  //           },
  //           children: [
  //             {
  //               path: '/hcp/practice/clinical_guides/:language',
  //               name: 'ClinicalGuidesLanguageSelection',
  //               meta: {
  //                 public: true,
  //                 allowed: '*'
  //               },
  //               children: [
  //                 {
  //                   path: '/hcp/practice/clinical_guides/:language/:id',
  //                   name: 'ClinicalGuide',
  //                   meta: {
  //                     public: true,
  //                     allowed: '*'
  //                   },
  //                   children: []
  //                 }              
  //               ]
  //             }          
  //           ]
  //         }              
  //       ]
  //     }          
  //   ]
  // },
  // {
  //   path: '/about_us',
  //   name: 'AboutUs',
  //   component: () => import('../views/Render.vue'),
  //   children: [
  //     {
  //       path: '/about_us/:child',
  //       component: () => import('../views/Render.vue'),
  //       meta: {
  //         public: true,
  //         allowed: '*'
  //       }
  //     }
  //   ],
  //   meta: {
  //     public: true,
  //     allowed: '*'
  //   }
  // },
  // {
  //   path: '/hcp',
  //   name: 'Health Care Professionals',
  //   component: () => import('../views/Render.vue'),
  //   meta: {
  //     public: true,
  //     allowed: '*'
  //   }
  // },
  // {
  //   path: '/patients_and_caregivers',
  //   name: 'Patients & Caregivers',
  //   component: () => import('../views/Render.vue'),
  //   meta: {
  //     public: true,
  //     allowed: '*'
  //   }
  // },
  // {
  //   path: '/news',
  //   name: 'News',
  //   component: () => import('../views/Render.vue'),
  //   meta: {
  //     public: true,
  //     allowed: '*'
  //   }
  // },
  // {
  //   path: '/get_involved',
  //   name: 'Get Involved',
  //   component: () => import('../views/Render.vue'),
  //   meta: {
  //     public: true,
  //     allowed: '*'
  //   }
  // },
  // {
  //   path: '/labelAdmin',
  //   component: () => import('../views/LabelAdmin.vue'),
  //   meta: {
  //     public: false,
  //     allowed: ['staff','developer','SuperAdmin','tester']
  //   },
  //   children: [
  //     {
  //       path: '/labelAdmin/edit/:index',
  //       name: 'editLabel',
  //       meta: {
  //         public: false,
  //         allowed: ['staff','developer','SuperAdmin','tester']
  //       }
  //     },
  //     {
  //       path: '/labelAdmin/new',
  //       name: 'newLabel',
  //       meta: {
  //         public: false,
  //         allowed: ['staff','developer','SuperAdmin','tester']
  //       }
  //     }
  //   ]
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next)=>{
  if(to.meta.public){
    next()
  }else{
    let findUser = async function(){
      try{
        const user = await Auth.currentAuthenticatedUser()
        let allowed = to.meta.allowed
        let groups = user.signInUserSession.accessToken.payload['cognito:groups']
        let allow = allowed.includes('*')

        if(typeof groups=='object' && groups.length!=undefined){
          for(let i=0; i<groups.length; i++){
            let group = groups[i]
            if( to.meta.allowed.includes(group) ){
              allow = true
              break;
            }
          }
        }
        // console.log('route guard',{allow, user, groups, allowed})
        if(allow){
          next()
        }else{
          next('/restricted')
        }
      }catch(err){
        cache.storage.local.set('pathAfterLogin',to.fullPath)
        next('/sign_in')
      }
    }

    findUser()
  }

})

export default router
