<template>
  <div>
        <div class="calcDescription atrialFibrillationManagement" id="atrialFibrillationManagement-header">
            <h2>{{getLocale(notations.tool_title)}}</h2>
        </div>
        <div class="atrialFibrillationManagement calcItem groupItem">
            <template v-if="debug">
                <v-btn small @click="completeMe">completeMe</v-btn>
                <v-switch v-model="ui.showKT" :true-value="true" :false-value="false" label="Show KT"/>

                <v-card style="position: fixed; bottom: 0px; left: 0px;">
                    <v-card-title>Context</v-card-title>
                    <v-card-text style="max-height: 800px; overflow-y: auto;">
                        <json-viewer :value="context"/>
                    </v-card-text>
                </v-card>
                
                <v-card style="position: fixed; bottom: 0px; right: 0px;">
                    <v-card-title>form</v-card-title>
                    <v-card-text style="max-height: 800px; overflow-y: auto;">
                        <json-viewer :value="form"/>
                    </v-card-text>
                </v-card>        
            </template>


            <div id="atrialFibrillationManagement">
                <v-form ref="atrialFibrillationManagement" v-model="validForm">
                    <template v-for="question in questions">
                        <div v-if="question.display" :key="`question-${question.id}`" :id="`atrialFibrillationManagement-question-${question.id}`" class="question">
                            <div class="question-body font-weight-medium">
                                <span class="debug" v-if="debug">{{ question.id }} - </span>
                                <span v-html="getLocale(question.body)"/>
                            </div>
                            <div class="question-subtitle" v-if="question.subtitle && question.subtitle[language] && question.subtitle[language].length>0">
                                {{getLocale(question.subtitle)}}
                            </div>
                            <div class="question-input">

                                <v-text-field outline v-if="question.type=='textbox'" v-model="form[question.id]" required :rules="rules.answered"/>

                                <v-radio-group dense v-if="question.type=='radio'" v-model="form[question.id]" required :rules="rules.answered">
                                    <v-radio v-for="(choice, choiceIndex) in question.choices" :key="`question-${question.id}-choice-${choiceIndex}`" :value="choice.value">
                                        <template v-slot:label>
                                            <span v-html="getLocale(choice.label)"/>
                                        </template>
                                    </v-radio>
                                </v-radio-group>

                                <div v-if="question.type=='checkbox'">
                                    <v-checkbox dense v-for="(choice, choiceIndex) in question.choices" :key="`question-${question.id}-choice-${choiceIndex}`" :value="choice.value" v-model="form[question.id]" @click="checkboxLogic({question,choice})"  required :rules="rules.answered" :hide-details="choiceIndex!=question.choices.length-1">
                                        <template v-slot:label>
                                            <span v-html="getLocale(choice.label)"/>
                                        </template>
                                    </v-checkbox>
                                </div>
                            </div>
                        </div>                    
                    </template>
                                        
                    <template v-for="popup in popups">
                        <div v-if="context.popups[popup.id].display" :key="`popup-${popup.id}`" :id="`atrialFibrillationManagement-popup-${popup.id}`" class="question popup-wrapper v-card">
                            <span class="debug" v-if="debug">{{getLocale(popup.identifier)}} :: {{popup.context}}</span>                            
                            <v-row v-if="getLocale(popup.body_prt1)"><v-col v-html="getLocale(popup.body_prt1)"></v-col></v-row>
                            <v-row :id="`popup_${popup.id}_flow_chart`" style="display:none">
                                <v-col class="text-center">
                                    <v-icon 
                                        style="position: absolute; right: 15px; background-color: #ecf0f2;"
                                        @click="toggleFlowChartVisibility(`popup_${popup.id}_flow_chart`)">mdi-close
                                    </v-icon>                                    
                                    <img :src="getLocale(popup_flow_chart.img)" width='100%'>
                                </v-col>
                            </v-row>
                            <v-row v-if="getLocale(popup.body_prt2)" class="mt-1"><v-col v-html="getLocale(popup.body_prt2)"></v-col></v-row>                            
                            <v-row v-if="getLocale(popup.resource)" class="mt-1 flex-column">
                                <v-card-title class="text-capitalize px-2 pb-0 font-weight-bold">{{getLocale(notations.resource)}}:</v-card-title>
                                <v-card-text class="subtitle-1 pl-4 pb-3" v-html="getLocale(popup.resource)"/>
                            </v-row>
                        </div>
                    </template>
                </v-form>

                <template v-if="validForm || ui.showKT">
                    <v-card id="atrialFibrillationManagement-recommendations">
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <template v-if="false && !isMobile"> <!-- force to skip since iPad Pro device not detected -->
                                <v-btn depressed @click="download" dark color="#293172" alt="getLocale(notations.download_summary)" title="getLocale(notations.download_summary)">
                                    {{getLocale(notations.download_summary)}}<v-icon right>mdi-download-circle-outline</v-icon>
                                </v-btn>
                            </template>
                            <template v-else> <!-- for Mobile browsers PDF served as TTL from AWS S3 bucket-->
                                <v-btn v-if="!pdfDownload.pdfLink.url || debug" alt="getLocale(notations.generate_pdf)" title="getLocale(notations.generate_pdf)"
                                    depressed  
                                    @click="getPDF('recommendations-summary')"
                                    :loading="pdfDownload.generatingPDF"
                                    style="float:right;margin: 5px; border:1px solid #dcdcdc; text-transform: capitalize;">
                                        {{getLocale(notations.generate_pdf)}}<v-icon right>mdi-cog-clockwise</v-icon>
                                </v-btn>
                                <v-btn v-if="pdfDownload.pdfLink.url || debug" alt="getLocale(notations.download_pdf)" title="getLocale(notations.download_pdf)"
                                    depressed dark color="#293172"
                                    target="_blank" 
                                    :href="pdfDownload.pdfLink.url">
                                        {{getLocale(notations.download_pdf)}}<v-icon right>mdi-cloud-download</v-icon>
                                </v-btn>
                            </template>
                        </v-card-actions>
                        <vue-html2pdf id="recommendations-summary"
                            :show-layout="true"
                            :float-layout="false"
                            :enable-download="true"
                            :html-to-pdf-options="{enableLinks:true,filename:'recommendation'}"
                            :preview-modal="false"
                            :paginate-elements-by-height="1200"
                            :pdf-quality="2"
                            :manual-pagination="false"
                            pdf-format="letter"
                            pdf-orientation="portrait"
                            pdf-content-width="100%"
    
                            @progress="onProgress($event)"
                            @hasStartedGeneration="hasStartedGeneration()"
                            @hasGenerated="hasGenerated($event)"
                            ref="html2Pdf_recommendations"
                            >
                            <div style="padding: 15px;" class="recommendations" slot="pdf-content">
                                <div class="text-center">
                                    <h3>{{getLocale(notations.summary_sectional_title)}}</h3>
                                    <h4>Date: {{Intl.DateTimeFormat(language, {dateStyle: 'medium', timeStyle: 'long'}).format(new Date())}}</h4>
                                    <h4>{{getLocale(notations.summary_title)}}</h4>
                                </div>
                                   
                                <hr>

                                <h3 class="text-capitalize">{{getLocale(notations.questionnaire)}}</h3>
                                <v-list dense>
                                    <template v-for="question in questions">
                                        <v-list-item style="border: 1px solid lightgrey; border-radius: 3px; margin: 5px;" :three-line="true" class="userAnswer" v-if="form[question.id]!=null && form[question.id].length>0" :key="`summary-q-${question.id}`">
                                            <v-list-item-content>
                                                <v-list-item-title v-html="getLocale(question.body)" class="font-weight-bold"/>
                                                <div style="font-size: 0.7em; color: #3d3d3d;" v-if="question.subtitle && question.subtitle[language]">
                                                    {{ getLocale(question.subtitle) }}
                                                </div>
                                                <div class="myAnswers" style="font-size: 0.9em; margin: 5px 0px 5px 15px;" v-html="myAnswers(question.id)"/>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </template>
                                </v-list>

                                <v-card outlined>                                                
                                <v-card-title class="text-capitalize pb-1">{{getLocale(notations.recommendations)}}</v-card-title>
                                <template v-for="(recommendation,index) in recommendations">
                                    <div v-if="recommendation.visibility===true" :key="`recommendation-${index}`">
                                        <span class="debug" v-if="debug">Recommendation-{{index}}</span>                            
                                        <v-col class="d-flex recommendations" v-html="getLocale(recommendation.content)"></v-col>
                                    </div>
                                </template>
                                </v-card>
                                <v-spacer  class="my-3"/>
                                <v-card v-if="moreInformation.length || debug" outlined>
                                    <v-card-title class="text-capitalize pb-1" v-html="getLocale(notations.more_info)"/>
                                    <v-list flat>                                       
                                        <v-list-item-group>
                                            <template v-for="(info_item, index) in moreInformation">                                                 
                                            <v-list-item :key="`info_item${index}`">
                                                <template v-slot:default="{ active }">
                                                  <v-list-item-content>
                                                    <v-list-item-title v-html="getLocale(info_item.reference)"/>
                                                    <v-list-item-subtitle v-html="getLocale(info_item.link)"/>
                                                  </v-list-item-content>                                                  
                                                </template>
                                            </v-list-item>
                                            <v-divider
                                              v-if="index < moreInformation.length - 1"
                                              :key="index"
                                            ></v-divider>                                                
                                            </template>
                                        </v-list-item-group>
                                    </v-list>
                                </v-card>
                                
                                <section v-html="css"/>    
                            </div>
                        </vue-html2pdf>    
                    </v-card>
                </template>
            </div>
        </div>

        <v-dialog v-model="ui.snackbar.display" persistent transition="dialog-bottom-transition">
            <v-card>
                <v-snackbar v-model="ui.snackbar.display" :timeout="ui.snackbar.timeout" centered>
                    <span v-html="ui.snackbar.message"/>
                    <template v-slot:action="{ attrs }">
                        <v-btn x-small v-bind="attrs" @click="closeSnackbarPopup('snackbar-close-btn')" dark><v-icon small>mdi-close</v-icon></v-btn>
                    </template>
                </v-snackbar>
            </v-card>
        </v-dialog>
  </div>
</template>

<script>
import VueHtml2pdf from 'vue-html2pdf'
import AF_ToolFigureEnglish from '../assets/AF_Tool_Figure_EN.png'
import AF_ToolFigureFrench from '../assets/AF_Tool_Figure_FR.png'
export default {
    name: "AtrialFibrillationManagement",
    props: {
        language: {
            type: String,
            required: false,
            default: "en-ca"
        }
    },
    components: {
        VueHtml2pdf
    },
    created(){
        this.init()
    },
    data(){
        return {
            ui: {
                snackbar: {
                    display: false,
                    message: 'Generating PDF',
                    timeout: -1,
                    
                },
                showKT: false
            },
            validForm: false,
            ready: false,
            form: null,
            rules: {
                answered: [
                    v => !!v || 'Required',
                    v => !!v && v.length >0 || 'Required',
                ]
            },
            timer: null,
            notations: {
                default: {"en-ca": "", "fr-ca": null},
                generate_pdf: {"en-ca": "Generating PDF...", "fr-ca": "Génération du PDF..."},
                ready_valid_form: {
                    "en-ca": "Your personalized Atrial Fibrillation information summary is ready",
                    "fr-ca": "Votre résumé personnalisé d'informations sur la fibrillation auriculaire est prêt.",
                },
                open_chads_calculator: {
                    "en-ca": "It seems you may need more information CHADS<sub>2</sub>. Please click <a href='https://thrombosiscanada.ca/hcp/practice/clinical_tools?calc=chads2' target='_blank'><u>HERE</u></a> to open CHADS<sub>2</sub> calculator tool for assistance.",
                    "fr-ca": "Il semble que vous ayez besoin de plus d'informations sur CHADS<sub>2</sub>. Veuillez cliquer <a href='https://thrombosiscanada.ca/hcp/practice/clinical_tools?calc=chads2' target='_blank'><u>ICI</u> pour ouvrir le calculateur CHADS<sub>2</sub> pour plus d'assistance.",
                },
                complete_questionnaire: {"en-ca": "Complete the questionnaire for Atrial Fibrillation information summary", "fr-ca": "Remplissez le questionnaire pour obtenir un résumé des informations sur la fibrillation auriculaire"},
                resource: {"en-ca": "Resource", "fr-ca": "Ressource"},
                recommendations: {"en-ca": "Recommendations", "fr-ca": "Recommandations"},
                questionnaire: {"en-ca": "Questionnaire", "fr-ca": "Questionnaire"},
                percentage_complete: {"en-ca": "% complete", "fr-ca": " % terminé"},
                more_info: {"en-ca": "For more information", "fr-ca": "Pour de plus amples renseignements"},
                tool_title: {"en-ca": "Atrial Fibrillation (AF) Interactive Algorithm", "fr-ca": "Algorithme interactif sur la fibrillation auriculaire (FA)"},
                summary_sectional_title: {"en-ca": "PATIENT PROFILE AND TREATMENT RECOMMENDATIONS", "fr-ca":"PROFIL DU PATIENT ET RECOMMANDATIONS EN MATIÈRE DE TRAITEMENT"},
                summary_title: {"en-ca": "Patient Assessment and Status", "fr-ca":"Évaluation et état du patient"},
                download_summary: {"en-ca": "Download Summary", "fr-ca": "TÉLÉCHARGER LE RÉSUMÉ"},
                generate_pdf: {"en-ca": "Generate PDF", "fr-ca": "Générer un PDF"},
                download_pdf: {"en-ca": "Download Summary", "fr-ca": "Télécharger le PDF"},
            },
            popup_flow_chart: { img: null },
            pdfDownload: {
                generatingPDF: false,
                pdfLink: {},
            },
        }
    },
    methods: {
        completeMe(){
            this.form = {"1":"Brian","2":"no","3":"yes > post-op afib","4":["lungs"],"5":["surgery","trauma"],"6":["warfarin","abixaban"],"7":[4,6]}
        },
        init(){
            this.form = {popups:{}}

            let questions = this.questions
            for(let i=0; i<questions.length; i++){
                let question = questions[i]
                i==0 && console.log('question-0', question)
                this.$set(this.form,question.id,question.type=='checkbox' ? [] : null)
            }
            
            for(let i=0; i<this.popups.length; i++){
                let popup = this.popups[i]
                this.$set(this.form['popups'],popup.id,{display: false})
            }
            
            this.popup_flow_chart.img = this.images.popup_flow_chart
            this.ready = true
            
            let self = this
            setTimeout(() => {
                self.scrollTo('atrialFibrillationManagement-header')
            }, 1200)
        },
        checkboxLogic({question, choice}){
            let checked = this.form[question.id].includes(choice.value)

            if(checked){
                if(choice.exclusive){
                    this.form[question.id] = [choice.value]
                }else{
                    let selections = this.form[question.id]
                    let choices = question.choices
                    for(let i=0; i<selections.length; i++){
                        let value = selections[i]
                        let index = choices.findIndex((choice)=>{return choice.value==value})
                        let choice = choices[index]
                        if(choice.exclusive){
                            this.form[question.id].splice(i,1)
                        }
                    }
                }

            }
        },
        myAnswers(qid, type){
            let language = this.language
            let questions = this.questions
            let form = this.form

            let index = questions.findIndex((question)=>{return question.id==qid})
            let question = questions[index]
            
            console.log({
                qid: qid, 
                type: type
            })

            if(question.type=='checkbox'){
                let selections = form[qid]
                let choices = question.choices
                let no_bullets_list = selections.length===1 ? 'style="list-style-type:none;"' : ''
                let output = type=='string' ? '' : `<ul ${no_bullets_list}>`
    
                for(let i=0; i<selections.length; i++){
                    let value = selections[i]
                    let choiceIndex = choices.findIndex((choice)=>{return choice.value==value})
                    if(type=='string'){
                        if(output.length>0){
                            output += ', '
                        }
                        output += choices[choiceIndex].label[language]
                    }else{
                        output += `<li>${choices[choiceIndex].label[language]}</li>`
                    }
                }
                output+=`</ul>`
                
                return output
            }

            if(question.type=='radio'){
                let value = form[qid]
                let choices = question.choices
                let choiceIndex = choices.findIndex((choice)=>{return choice.value==value})
                let output = choices[choiceIndex].label[language]
                
                return output
            }
            return form[qid]
        },
        download(){
            let self = this
            this.ui.snackbar.message = this.getLocale(this.notations.generate_pdf)
            this.ui.snackbar.display = true
            clearTimeout(self.timer)
            self.timer = setTimeout(() => {
                self.$refs.html2Pdf_recommendations.generatePdf()
            }, 800);
        },
        onProgress(e){
            this.ui.snackbar.display = true
            this.ui.snackbar.message = e + this.getLocale(this.notations.percentage_complete)
        },
        hasStartedGeneration(){
            console.log('hasStartedGeneration')
        },
        hasGenerated(e){
            console.log('hasGenerated',e)
        },
        hasSelected(targets, answers){
            if(!targets || !answers || answers.length==0){
                return undefined
            }

            for(let i=0; i<targets.length; i++){
                let value = targets[i]
                if(answers.includes(value)){
                    return true
                }
            }

            return false
        },
        scrollTo(targetElemId){
            return document.querySelector('#'+targetElemId).scrollIntoView({behavior: 'smooth'});
        },
        getLocale(source){
            if (source){
                return source[this.language]===undefined || !source[this.language] ? source["en-ca"] : source[this.language]
            }
            return null
        },
        closeSnackbarPopup(event=null){
            this.ui.snackbar.display = false
            if (this.form[5] && this.form[5]==='CHADS2 score unknown'){
                this.form[5]=null
            }
            else if (event==='snackbar-close-btn' &&  this.validForm){
                this.scrollTo('atrialFibrillationManagement-recommendations')
            }
        },
        toggleFlowChartVisibility(targetElemId){
            return document.querySelector('#'+targetElemId).style.display = 'none'
        },
        getPDF: function(nodeId=null){
            if (nodeId===null){
                return
            }
            let self = this
            self.pdfDownload.generatingPDF = true
            // self.linkImages()

            setTimeout(function(){
                let html = document.getElementById(nodeId).innerHTML
                self.sendRequestMakePDF('post', self.makePDF_API+'/pdf/make/tctools',{
                    html
                }).then(function(response){
                    self.pdfDownload.generatingPDF = false
                    console.log(response)
                    if (response.status===200){
                        self.pdfDownload.pdfLink = response.data
                        window.open(self.pdfDownload.pdfLink.url,'Summary')
                    }
                })
            },1000)
        },                
    },
    computed: {
        makePDF_API(){ 
            return process.env.VUE_APP_PDF_MAKE_API
        },        
        isMobile(){
            return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent) && !window.MSStream
        },
        debug(){
            return this.$store.getters.debug
        },
        popups(){
            function makePopup(id=null, identifier=null, context=null, body_prt1=null, body_prt2=null, resource=null){
                return {
                    id, identifier, context, body_prt1, body_prt2, resource
                }
            }
            let list_fixed_portion = {
                "en-ca": `
                    <li><b>cardioversion</b></li>
                    <li>anticoagulation for 4 weeks post-cardioversion</li>
                    <li>long-term anticoagulation based on the “CCS algorithm” (“CHADS-65”)</li>        
                `,
                "fr-ca": `
                    <li><b>Cardioversion</b></li>
                    <li>Anticoagulothérapie pendant 4 semaines après la cardioversion</li>
                    <li>Anticoagulothérapie à long terme suivant l’algorithme de la SCC (« CHADS-65 »)</li>
                `
            }
            let body_prt2 = {
                "en-ca": '<p class="mb-1">For long-term anticoagulation recommendations, go to the <a href="https://thrombosiscanada.ca/tools/?calc=antithromboticAlgorithm" target="_blank">Anticoagulant Dosing in AF tool</a></p>',
                "fr-ca": '<p class="mb-1">Pour les recommandations sur l’anticoagulothérapie à long terme, consultez l’outil <a href="https://thrombosiscanada.ca/tools/?calc=antithromboticAlgorithm" target="_blank">Posologie des anticoagulants en cas de FA</a></p>'
            }
            let resource = {
                "en-ca": '<a href="https://www.onlinecjc.ca/article/S0828-282X(18)31062-6/pdf" target="_blank" style="word-break: break-word;">2018 Focused Update of the CCS Guidelines for the Management of Atrial Fibrillation</a>',
                "fr-ca": '<a href="https://www.onlinecjc.ca/article/S0828-282X(18)31062-6/pdf" target="_blank" style="word-break: break-word;">Mise à jour pertinente de 2018 des lignes directrices de la Société canadienne de cardiologie sur la prise en charge de la fibrillation auriculaire</a>'
            }
            return [
                makePopup(
                    1, 
                    {"en-ca":"POPUP 1: Unstable patient"},
                    "Unstable patient",
                    {
                        "en-ca": `
                            <p class="mb-1">In patients with hemodynamically unstable acute AF, the CCS guidelines for the management of AF recommend:</p>
                            <ol>
                                <li><b>initiation of an oral anticoagulant</b> as soon as possible (preferably prior to cardioversion)</li>
                                ${list_fixed_portion['en-ca']}
                            </ol>
                            <p class="mb-1">Click <a href='#' onclick="event.preventDefault(); document.getElementById('popup_1_flow_chart').style.display = 'flex';">here</a> to see these recommendations in a flow chart.</p>
                        `,
                        "fr-ca": `
                            <p class="mb-1">Pour les patients atteints de FA aiguë dont l’état hémodynamique est instable, les lignes directrices de la SCC sur la prise en charge de la FA recommandent :</p>
                            <ol>
                                <li><b>Instauration d’une anticoagulothérapie orale</b> le plus tôt possible (de préférence avant la cardioversion)</li>
                                ${list_fixed_portion['fr-ca']}
                            </ol>
                            <p class="mb-1">Cliquez <a href='#' onclick="event.preventDefault(); document.getElementById('popup_1_flow_chart').style.display = 'flex';">ici</a> pour voir ces recommandations sous forme d’organigramme.</p>
                        `
                    },
                    body_prt2,
                    resource
                ),
                makePopup(
                    2,
                    {"en-ca":"POPUP 2: Valvular AF"},
                    "Patients with valvular AF",
                    {
                        "en-ca": `
                            <p class="mb-1">In patients with valvular AF, the CCS guidelines for the management of AF recommend:</p>
                            <ol>
                                <li><b>therapeutic oral anticoagulation</b> for at least <b>3 weeks</b> before cardioversion OR a transesophageal echocardiogram to exclude a left atrial thrombus</li>
                                ${list_fixed_portion['en-ca']}
                            </ol>
                            <p class="mb-1">Click <a href='#' onclick="event.preventDefault(); document.getElementById('popup_2_flow_chart').style.display = 'flex';">here</a> to see these recommendations in a flow chart.</p>
                        `, 
                        "fr-ca": `
                            <p class="mb-1">Pour les patients atteints de FA valvulaire, les lignes directrices de la SCC sur la prise en charge de la FA recommandent :</p>
                            <ol>
                                <li><b>Anticoagulothérapie orale aux doses thérapeutiques</b> pendant au moins <b>3 semaines</b> avant la cardioversion OU Une échocardiographie transœsophagienne pour écarter le thrombus artériel</li>
                                ${list_fixed_portion['fr-ca']}
                            </ol>
                            <p class="mb-1">Cliquez <a href='#' onclick="event.preventDefault(); document.getElementById('popup_2_flow_chart').style.display = 'flex';">ici</a> pour voir ces recommandations sous forme d’organigramme.</p>
                        `
                    },
                    body_prt2,
                    resource
                ),
                makePopup(
                    3,
                    {"en-ca":"POPUP 3: non-valvular AF"},
                    "Patients with non-valvular AF: <12 hours + recent stroke/TIA OR 12-48 hours + CHADS2 ≥2 OR >48 hours",
                    {
                        "en-ca": `
                            <p class="mb-1">In patients with non-valvular AF</p>
                            <ul>
                                <li>for a duration of <12 hours with a recent stroke or TIA, or</li>
                                <li>for a duration of 12-48 hours with a CHADS<sub>2</sub> score of ≥2, or</li>
                                <li>for a duration of >48 hours,</li>
                            </ul>
                            <p class="mb-1 pt-2">the CCS guidelines for the management of AF recommend:</p>
                            <ol>
                                <li><b>therapeutic oral anticoagulation</b> for at least <b>3 weeks</b> before cardioversion OR a transesophageal echocardiogram to exclude a left atrial thrombus</li>
                                ${list_fixed_portion['en-ca']}
                            </ol>
                            <p class="mb-1">Click <a href='#' onclick="event.preventDefault(); document.getElementById('popup_3_flow_chart').style.display = 'flex';">here</a> to see these recommendations in a flow chart.</p>
                        `, 
                        "fr-ca": `
                            <p class="mb-1">Chez les patients atteints de FA non valvulaire</p>
                            <ul>
                                <li>depuis < 12 heures, et ayant récemment subi un AVC ou un AIT, ou</li>
                                <li>pendant 12-48 heures et un score CHADS<sub>2</sub> ≥ 2, ou</li>
                                <li>pendant > 48 heures,</li>
                            </ul>
                            <p class="mb-1 pt-2">les lignes directrices de la SCC sur la prise en charge de la FA recommandent :</p>
                            <ol>
                                <li><b>Anticoagulothérapie orale aux doses thérapeutiques</b> pendant au moins <b>3 semaines</b> avant la cardioversion OU Une échocardiographie transœsophagienne pour écarter le thrombus artériel</li>
                                ${list_fixed_portion['fr-ca']}
                            </ol>
                            <p class="mb-1">Cliquez <a href='#' onclick="event.preventDefault(); document.getElementById('popup_3_flow_chart').style.display = 'flex';">ici</a> pour voir ces recommandations sous forme d’organigramme.</p>
                        `
                    },
                    body_prt2,
                    resource
                ),
                makePopup(
                    4,
                    {"en-ca":"POPUP 4: with non-valvular AF"},
                    "Patients with non-valvular AF:  <12 hours + no recent stroke/TIA OR 12-48 hours + CHADS2 <2",                                    
                    {
                        "en-ca": `
                            <p class="mb-1">In patients with non-valvular AF</p>
                            <ul>
                                <li>for a duration of less than 12 hours with no recent stroke or TIA, or</li>
                                <li>for a duration of 12-48 hours with a CHADS<sub>2</sub> score of <2</li>
                            </ul>
                            <p class="mb-1 pt-2">the CCS guidelines for the management of AF recommend:</p>
                            <ol>
                                <li><b>initiation of an oral anticoagulant</b> as soon as possible (preferably prior to cardioversion)</li>
                                ${list_fixed_portion['en-ca']}
                            </ol>
                            <p class="mb-1">Click <a href='#' onclick="event.preventDefault(); document.getElementById('popup_4_flow_chart').style.display = 'flex';">here</a> to see these recommendations in a flow chart.</p>
                        `, 
                        "fr-ca": `
                            <p class="mb-1">Chez les patients atteints de FA non valvulaire</p>
                            <ul>
                                <li>depuis < 12 heures, et aucun antécédent récent d’AVC ou d’AIT, ou</li>
                                <li>pendant 12-48 heures et un score CHADS<sub>2</sub> < 2,</li>
                            </ul>
                            <p class="mb-1 pt-2">les lignes directrices de la SCC sur la prise en charge de la FA recommandent :</p>
                            <ol>
                                <li><b>Instauration d’une anticoagulothérapie orale</b> le plus tôt possible (de préférence avant la cardioversion)</li>
                                ${list_fixed_portion['fr-ca']}
                            </ol>
                            <p class="mb-1">Cliquez <a href='#' onclick="event.preventDefault(); document.getElementById('popup_4_flow_chart').style.display = 'flex';">ici</a> pour voir ces recommandations sous forme d’organigramme.</p>
                        `
                    },
                    body_prt2,
                    resource
                ),
            ]
            
        },
        questions(){
            function makeTextbox({id, display, valid, body, subtitle, footer}){
                let output = {
                    display,
                    valid,
                    id,
                    type: 'textbox',
                    form: null,
                    body, subtitle, footer
                }

                return output
            }
            function makeCheckbox({id, display, valid, body, subtitle, footer, choices}){
                let output = {
                    display,
                    valid,
                    id,
                    type: 'checkbox',
                    form: [],
                    body, subtitle, footer,
                    choices
                }

                return output
            }
            function makeRadio({id, display, valid, body, subtitle, footer, choices}){
                let output = {
                    display,
                    valid,
                    id,
                    type: 'radio',
                    form: null,
                    body, subtitle, footer,
                    choices
                }

                return output
            }
            let context = this.context
            let form = this.form
            return [
                makeCheckbox({
                    id: 1, 
                    valid:  (form && form[1]!=null && form[1].length>0),
                    display: true,
                    optional: false,
                    body: {
                        "en-ca": "Is the patient hemodynamically stable or unstable?",
                        "fr-ca": "L’état hémodynamique du patient est-il stable ou instable?"
                    },
                    choices:[
                        {
                            label: {
                                "en-ca": "Stable",
                                "fr-ca": "Stable"
                            },
                            value: "stable",
                            exclusive: true
                        },
                        {
                            label: {
                                "en-ca": "Unstable  — acute AF causing persistent hypotension",
                                "fr-ca": "Instable – FA aiguë provoquant une hypotension persistante",
                            },
                            value: "persistent hypotension",
                            exclusive: false
                        },
                        {
                            label: {
                                "en-ca": "Unstable  — acute AF causing cardiac ischemia",
                                "fr-ca": "Instable – FA aiguë provoquant une ischémie cardiaque",
                            },
                            value: "cardiac ischemia",
                            exclusive: false
                        },
                        {
                            label: {
                                "en-ca": "Unstable  — acute AF causing pulmonary edema",
                                "fr-ca": "Instable – FA aiguë provoquant un œdème pulmonaire",
                            },
                            value: "pulmonary edema",
                            exclusive: false
                        },
                    ],
                }),
                makeRadio({
                    id: 2, 
                    valid: (form && form[2]!=null && form[2].length>0),
                    display: context.patientHemodynamicallyStable===true,
                    optional: false,
                    body: {
                        "en-ca": "Does the patient have valvular or non-valvular AF?",
                        "fr-ca": "Le patient présente-t-il une FA valvulaire ou non valvulaire?"
                    },
                    subtitle: { "en-ca": null },
                    choices:[
                        {
                            label:{
                                "en-ca": "Valvular",
                                "fr-ca": "Valvulaire"
                            },
                            value: "valvular"
                        },
                        {
                            label:{
                                "en-ca": "Non-valvular",
                                "fr-ca": "Non valvulaire"
                            },
                            value:"non-valvular"
                        }
                    ],
                }),
                makeRadio({
                    id: 3, 
                    valid: (form && form[3]!=null),
                    display: context.atrialFibrillationTypeNonValvular===true,
                    optional: false,
                    body: {
                        "en-ca": "For how long has the patient had non-valvular AF?",
                        "fr-ca": "Depuis combien de temps le patient est-il atteint de FA non valvulaire?"
                    },
                    subtitle: { "en-ca": null },
                    choices:[
                        {
                            label: {
                                "en-ca": "Less than 12 hours",
                                "fr-ca": "Moins de 12 heures"
                            },
                            value: "non-valvular > less than 12 hours"
                        },
                        {
                            label: {
                                "en-ca": "12 to 48 hours",
                                "fr-ca": "12 à 48 heures"
                            },
                            value: "non-valvular > 12 to 48 hours"
                        },
                        {
                            label: {
                                "en-ca": "More than 48 hours",
                                "fr-ca": "Plus de 48 heures"
                            },
                            value: "non-valvular > more than 48 hours"
                        },
                    ]
                }),
                makeRadio({
                    id: 4, 
                    valid:  (form && form[4]!=null && form[4].length>0),
                    display: context.nonvalvularLess12hrs===true,
                    optional: false,
                    body: {
                        "en-ca": "Has the patient had a recent stroke or TIA?",
                        "fr-ca": "Le patient a-t-il récemment été victime d’un AVC ou d’un AIT?"
                    },
                    choices:[
                        {
                            label: {
                                "en-ca": "Yes",
                                "fr-ca": "Oui"
                            },
                            value: "stroke-tia > yes",
                        },
                        {
                            label: {
                                "en-ca": "No",
                                "fr-ca": "Non"
                            },
                            value: "stroke-tia > no",
                        },
                    ]
                }),
                makeRadio({
                    id: 5, 
                    valid: (form && form[5]!=null && form[5].length>0),
                    display: context.nonvalvular12_to_48hrs===true,
                    optional: false,
                    body: {
                        "en-ca": "Is the patient’s CHADS<sub>2</sub> score at least 2?",
                        "fr-ca": "Le score CHADS<sub>2</sub> du patient est-il d’au moins 2?"
                    },
                    choices:[
                        {
                            label: {
                                "en-ca": "Yes (CHADS<sub>2</sub> score ≥2)",
                                "fr-ca": "Oui (score CHADS<sub>2</sub> ≥ 2)"
                            },
                            value: "CHADS2 score more than 2",
                        },
                        {
                            label: {
                                "en-ca": "No (CHADS<sub>2</sub> score 0 or 1)",
                                "fr-ca": "Non (score CHADS<sub>2</sub> de 0 ou 1)"
                            },
                            value: "CHADS2 score 0 or 1",
                        },
                        {
                            label: {
                                "en-ca": "Unknown CHADS<sub>2</sub> score",
                                "fr-ca": "Score CHADS<sub>2</sub> inconnu"
                            },
                            value: "CHADS2 score unknown",
                        },
                    ]
                }),
            ]
        },
        questionMap(){
            let output = {}
            let questions = this.questions
            for(let i=0; i<questions.length; i++){
                let question = questions[i]
                output[question.id] = question
            }

            return output
        },
        context(){
            if(this.ready){
                let form = this.form
                let patientHemodynamicallyStable = form[1].length ? form[1].filter((elem)=>['stable'].includes(elem)).length ? true : false : undefined
                let atrialFibrillationTypeNonValvular = form[2] !== null ? form[2] === 'non-valvular' : undefined
                let nonvalvularLess12hrs = form[3]!==null ? form[3]==='non-valvular > less than 12 hours' : undefined
                let nonvalvular12_to_48hrs = form[3]!==null ? form[3]==='non-valvular > 12 to 48 hours' : undefined
                let nonvalvularMore48hrs = form[3]!==null ? form[3]==='non-valvular > more than 48 hours' : undefined
                let hadRecentStrokeTia = form[4]!==null ? form[4]==='stroke-tia > yes' : undefined
                let hasChad2ScoreGreaterThanTwo = form[5] ? form[5]==='CHADS2 score more than 2' : undefined
                let unknownChadScoreAlert = form[5] ? form[5]==='CHADS2 score unknown' : undefined
    
                return {
                    patientHemodynamicallyStable,
                    atrialFibrillationTypeNonValvular,
                    nonvalvularLess12hrs,
                    nonvalvular12_to_48hrs,
                    nonvalvularMore48hrs,
                    hadRecentStrokeTia,
                    hasChad2ScoreGreaterThanTwo,
                    unknownChadScoreAlert,
                    popups: {
                        1: {display: patientHemodynamicallyStable!==undefined && patientHemodynamicallyStable===false},
                        2: {display: atrialFibrillationTypeNonValvular!==undefined && atrialFibrillationTypeNonValvular===false},
                        3: {display: (nonvalvularMore48hrs!==undefined && nonvalvularMore48hrs===true) 
                                    || (hadRecentStrokeTia!==undefined && hadRecentStrokeTia===true) 
                                    || (hasChad2ScoreGreaterThanTwo!==undefined && hasChad2ScoreGreaterThanTwo===true)},
                        4: {display: (hadRecentStrokeTia!==undefined && hadRecentStrokeTia===false) || (hasChad2ScoreGreaterThanTwo!==undefined && hasChad2ScoreGreaterThanTwo===false && unknownChadScoreAlert===false)},
                    },
                }
            }else{
                return {}
            }

        },
        recommendations(){
            let fixed_portion = {
                "en-ca": `
                    <li><b>Cardioversion</b></li>
                    <li>Anticoagulation for 4 weeks post-cardioversion</li>
                    <li>Long-term anticoagulation based on the “CCS algorithm” (“CHADS-65”)</li>        
                `,
                "fr-ca": `
                    <li><b>Cardioversion</b></li>
                    <li>Anticoagulothérapie pendant 4 semaines après la cardioversion</li>
                    <li>Anticoagulothérapie à long terme suivant l’algorithme de la SCC (« CHADS-65 »)</li>
                `
            }
            return {
                1: {
                    visibility: this.context.popups[1].display===true || this.context.popups[4].display===true ? true: false,
                    content: {
                        "en-ca": `
                            <ol>
                                <li><b>Initiation of an oral anticoagulant</b> as soon as possible (preferably prior to cardioversion)</li>
                                ${fixed_portion['en-ca']}
                            </ol>
                        `,
                        "fr-ca": `
                            <ol>
                                <li><b>Instauration d’une anticoagulothérapie orale</b> le plus tôt possible (de préférence avant la cardioversion)</li>
                                ${fixed_portion['fr-ca']}
                            </ol>
                        `,
                    }
                },
                2: {
                    visibility: this.context.popups[2].display===true || this.context.popups[3].display===true ? true: false,
                    content: {
                        "en-ca": `
                            <ol>
                                <li><b>Therapeutic oral anticoagulation</b> for at least <b>3 weeks</b> before cardioversion OR a transesophageal echocardiogram to exclude a left atrial thrombus</li>
                                ${fixed_portion['en-ca']}
                            </ol>
                        `,
                        "fr-ca": `
                            <ol>
                                <li><b>Anticoagulothérapie orale aux doses thérapeutiques</b> pendant au moins <b>3 semaines</b> avant la cardioversion OU Une échocardiographie transœsophagienne pour écarter le thrombus artériel</li>
                                ${fixed_portion['fr-ca']}
                            </ol>
                        `,
                    }
                },
            }
        },
        moreInformation(){
            return [
                {
                    reference: {
                        "en-ca": "2018 Focused Update of the Canadian Cardiovascular Society Guidelines for the Management of Atrial Fibrillation",
                        "fr-ca": "Mise à jour pertinente de 2018 des lignes directrices de la Société canadienne de cardiologie sur la prise en charge de la fibrillation auriculaire",
                    },
                    link: {
                        "en-ca": "<a href='https://www.onlinecjc.ca/article/S0828-282X(18)31062-6/pdf' target='_blank'>https://www.onlinecjc.ca/article/S0828-282X(18)31062-6/pdf</a>",
                        "fr-ca": "<a href='https://www.onlinecjc.ca/article/S0828-282X(18)31062-6/pdf' target='_blank'>https://www.onlinecjc.ca/article/S0828-282X(18)31062-6/pdf</a>",
                    },
                },
                {
                    reference: {
                        "en-ca": "Thrombosis Canada Interactive Algorithms and Calculators",
                        "fr-ca": "Algorithmes interactifs et calculateurs de Thrombose Canada",
                    },
                    link: {
                        "en-ca": "<a href='https://thrombosiscanada.ca/tools' target='_blank'>https://thrombosiscanada.ca/tools</a>",
                        "fr-ca": "<a href='https://thrombosiscanada.ca/tools' target='_blank'>https://thrombosiscanada.ca/tools</a>",
                    },
                },
                {
                    reference: {
                        "en-ca": "Thrombosis Canada Clinical Guides",
                        "fr-ca": "Guides cliniques de Thrombose Canada",
                    },
                    link: {
                        "en-ca": "<a href='https://thrombosiscanada.ca/clinicalguides' target='_blank'>https://thrombosiscanada.ca/clinicalguides</a>",
                        "fr-ca": "<a href='https://thrombosiscanada.ca/clinicalguides' target='_blank'>https://thrombosiscanada.ca/clinicalguides</a>",
                    },
                },               
            ]
        },
        css(){
            return `
            <style type="text/css">
                ul{
                    margin: 0px 0px 0px 25px;
                }
                li ul{
                    margin: 0px 0px 0px 25px;
                }
                p{
                    margin: 15px 0px 15px 0px;
                }
                .recommendations{
                    font-family: Roboto,sans-serif;
                    font-size: 1rem;
                    padding: 6px 25px 12px 25px;
                    margin: 8px;
                    width: auto;
                }

                .kt-prompt{
                    padding: 8px;
                }

                .section{
                    margin-top: 25px;
                    font-weight: bold;
                    font-size: 1.2em;
                }
                .myAnswers ul{
                    margin: 0px 0px 0px 0px;
                    padding-left: 2px;
                }
                .myAnswers li ul{
                    margin: 0px 0px 0px 0px;
                }
            </style>
            `
        },
        required(){
            let invalid = []
            let questions = this.questions
            for(let i=0; i<questions.length; i++){
                let question = questions[i]
                if(question.display && !question.valid){
                    invalid.push(question)
                }
            }
            return invalid
        },
        images: function(){
            return {
                popup_flow_chart: {"en-ca": AF_ToolFigureEnglish, "fr-ca": AF_ToolFigureFrench}
            }
        }        
    },
    watch: {
        validForm(after, before){
            let self = this
            setTimeout(() => {
                if(self.ready && self.validForm && !self.context.unknownChadScoreAlert){
                    self.ui.snackbar.message = self.getLocale(self.notations.ready_valid_form)
                    self.ui.snackbar.display = true
                }
                else if(after===false && before===true){  // force to initialize pdfLink on Reset event click
                    return self.pdfDownload.pdfLink = {}
                }    
            }, 0)
        },
        questions:{
            deep: true,
            handler(after, before){
                let questions = this.questions
                let form = this.form
                for(let i=0; i<questions.length; i++){
                    let question = questions[i]
                    let qBefore = before.find((item)=>{return item.id==question.id})
                    if(!question.display && qBefore.display){
                        if(question.type=='checkbox'){                            
                            form[question.id] = question.type=='checkbox' ? [] : null
                        }else{
                            form[question.id] = question.type=='checkbox' ? [] : null
                        }
                    }
                }
            }
        },
        context:{
            deep: true,
            handler(after, before){
                if (after.unknownChadScoreAlert!==before.unknownChadScoreAlert){
                    if(after.unknownChadScoreAlert===true){
                        this.ui.snackbar.message = this.getLocale(this.notations.open_chads_calculator)
                        this.ui.snackbar.timeout = -1
                        this.ui.snackbar.display = true
                    }else{
                        this.ui.snackbar.display = false
                    }
                }
            }
        },
        language(after, before){
            let self = this
            setTimeout(() => {
                self.scrollTo('atrialFibrillationManagement-header')
            }, 1200)
        }
    }
}
</script>

<style lang="scss" scoped>
.debug{
    color: red;
    font-size: 0.7em;
}
.question{
    border: 1px solid lightgray;
    border-radius: 5px;
    margin: 15px;
    padding: 15px;
    font-size: 1.2em;
}
.question-subtitle{
    color: gray;
    font-size: 0.7em;
    font-style: italic;
    line-height: 1em;
    margin-top: 5px;
}

.question-input{
    min-height: 50px;
    width: 100%;
    padding: 5px;
    margin: 5px;
}

::v-deep .v-snack__wrapper {
    border: 3px solid whitesmoke !important; 
}
.popup-wrapper {
    background-color: $secondary-blue-light-2;
}
.v-list-item__subtitle, .v-list-item__title {
    white-space: normal;
}
</style>

<style>
.calcItem #atrialFibrillationManagement input[type="text"],
.calcItem #atrialFibrillationManagement  input[type="number"]{
    all: unset;
}
.question .question-input sup{
    font-size: 0.6em;
}
.calcItem #atrialFibrillationManagement .popup-wrapper a {
    color: blue;
    text-decoration: underline;
    cursor: pointer;    
}
</style>