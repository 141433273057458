<template>
    <div>
        <v-snackbar v-model="showSnackbar" :color="snackbarType" timeout="3000" top>
            {{ snackbarMessage }}
        </v-snackbar>
        <v-overlay :value="loading">
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
        </v-overlay>
        <!-- Notifications Manager Card -->
        <v-card>
            <v-card-title>
                Notifications Manager
            </v-card-title>
            <v-card-actions>
                <v-btn class="themed-button-dark" @click="showCreateModal()">New Notification</v-btn>
            </v-card-actions>
            <v-card-text>
                <!-- Notification List -->
                <v-list>
                    <v-list-item v-for="(notification, index) in notifications" :key="'notification_' + notification.id"
                        :class="[{ 'enabled': notification.active }, { 'disabled': !notification.active }]">
                        <v-list-item-avatar>
                            {{ index + 1 }}
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title>{{ notification.title }}</v-list-item-title>
                            <v-list-item-subtitle>
                                Message: {{ notification.message }} <br>
                                Link Type: {{ notification.link_type || '--' }} <br>
                                Link: {{ notification.link || '--' }}<br>
                                {{ notification.created_at.split('T')[0] }}
                            </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <div>
                                <v-btn color="gray" text small @click="editNotification(notification)">Edit</v-btn>
                                <v-btn color="green" text small v-if="notification.active == true"
                                    @click="toggleNotification(notification)">Disable</v-btn>
                                <v-btn color="red" text small v-else
                                    @click="toggleNotification(notification)">Enable</v-btn>
                                <!-- <v-btn color="red" text small @click="confirmDelete(notification)">Delete</v-btn> -->
                            </div>
                        </v-list-item-action>
                    </v-list-item>
                </v-list>

                <!-- Modal for creating notifications -->
                <v-dialog v-model="showModal" content-class="notification-modal">
                    <v-card>
                        <v-card-title>Create Notification</v-card-title>
                        <v-card-text>
                            <v-form @submit.prevent="createNotification">
                                <v-text-field v-model="notificationData.title" label="Title" required
                                    :error-messages="titleError"></v-text-field>
                                <v-textarea v-model="notificationData.message" label="Message" required
                                    :error-messages="messageError"></v-textarea>
                                <v-select v-model="notificationData.link_type" :items="linkTypes" label="Link Type"
                                    @change="updateLinkPlaceholder(notificationData)"></v-select>
                                <v-text-field v-model="notificationData.link" :label="linkPlaceholder"
                                    v-if="notificationData.link_type !== 'None'"
                                    :error-messages="linkError"></v-text-field>
                                <v-spacer></v-spacer>
                                <v-btn color="red" @click="cancelCreate" class="mr-3">Cancel</v-btn>
                                <v-btn type="submit" color="primary" :disabled="loading">Send Notification</v-btn>
                            </v-form>
                        </v-card-text>
                    </v-card>
                </v-dialog>

                <!-- Modal for edit notifications -->
                <v-dialog v-model="showEditModal" content-class="notification-modal">
                    <v-card>
                        <v-card-title>Edit Notification</v-card-title>
                        <v-card-text>
                            <v-form @submit.prevent="updateNotification">
                                <v-text-field v-model="editNotificationData.title" label="Title" required
                                    :error-messages="titleError"></v-text-field>
                                <v-textarea v-model="editNotificationData.message" label="Message" required
                                    :error-messages="messageError"></v-textarea>
                                <v-select v-model="editNotificationData.link_type" :items="linkTypes" label="Link Type"
                                    @change="updateLinkPlaceholder(editNotificationData)"></v-select>
                                <v-text-field v-model="editNotificationData.link" :label="linkPlaceholder"
                                    v-if="editNotificationData.link_type !== 'None'"
                                    :error-messages="linkError"></v-text-field>
                                <v-spacer></v-spacer>
                                <v-btn color="red" @click="cancelEdit" class="mr-3">Cancel</v-btn>
                                <v-btn type="submit" color="primary">Save</v-btn>
                            </v-form>
                        </v-card-text>
                    </v-card>
                </v-dialog>

                <!-- Modal for delete confirmation -->
                <v-dialog v-model="showDeleteConfirmation" max-width="400">
                    <v-card>
                        <v-card-title>Delete Notification</v-card-title>
                        <v-card-text>
                            Are you sure you want to delete this notification?
                        </v-card-text>
                        <v-card-actions>
                            <v-btn color="red darken-1" text @click="cancelDelete">Cancel</v-btn>
                            <v-btn color="red darken-1" text @click="performDelete">Delete</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

            </v-card-text>
        </v-card>
    </div>
</template>

<script>
export default {
    data() {
        return {
            showModal: false,
            showEditModal: false,
            notificationData: {
                title: '',
                message: '',
                link_type: 'None',
                link: '',
                active: true,
            },
            editNotificationData: {
                title: '',
                message: '',
                link_type: 'None',
                link: '',
                active: true,
            },
            notifications: [],
            linkTypes: ['None', 'App Link', 'External URL'],
            linkPlaceholder: 'App Link',
            titleError: '',
            messageError: '',
            linkError: '',
            showDeleteConfirmation: false,
            notificationToDelete: null,
            showSnackbar: false,
            snackbarMessage: '',
            snackbarType: 'success',
            loading: false,
        };
    },
    methods: {
        async fetchNotifications() {
            try {
                let response = await this.sendRequest('get', '/api/admin/notifications');
                this.notifications = response.data;
                this.notifications.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
            } catch (error) {
                console.error('Error fetching notifications:', error);
            }
        },
        async createNotification() {
            this.clearErrors();
            const isValid = this.validateForm(this.notificationData);
            if (!isValid) return;

            try {
                const data = { ...this.notificationData };
                this.loading = true;
                let pushNotificationResponse = await this.sendRequest('post', '/api/admin/send-push-notification', data);

                if (pushNotificationResponse.data.success) {
                    let addNotificationResponse = await this.sendRequest('post', '/api/admin/notifications', data);
                    this.showModal = false;
                    this.clearNotificationData();
                    this.fetchNotifications();
                }

                if (pushNotificationResponse.data.success) {
                    this.showToast('Notification created successfully');
                } else {
                    this.showToast('Failed to send push notification', 'error');
                }
            } catch (error) {
                console.error('Error creating notification:', error);
                this.showToast('An error occurred while creating notification', 'error');
            } finally {
                this.loading = false;
            }
        },
        editNotification(notification) {
            this.editNotificationData = { ...notification };
            this.showEditModal = true;
        },
        async updateNotification() {
            this.clearErrors();
            const isValid = this.validateForm(this.editNotificationData);
            if (!isValid) return;

            if (this.editNotificationData.link_type === 'None') {
                this.editNotificationData.link = '';
            }

            try {
                const data = { ...this.editNotificationData };
                let response = await this.sendRequest('put', `/api/admin/notifications/${this.editNotificationData.id}`, data);
                this.showEditModal = false;
                this.clearNotificationData();
                this.fetchNotifications();
            } catch (error) {
                console.error('Error updating notification:', error);
            }
        },
        async performDelete() {
            if (this.notificationToDelete) {
                try {
                    let response = await this.sendRequest('delete', `/api/admin/notifications/${this.notificationToDelete.id}`);
                    this.showDeleteConfirmation = false;
                    this.fetchNotifications();
                } catch (error) {
                    console.error('Error deleting notification:', error);
                }
            }
        },
        async toggleNotification(notification) {
            try {
                let response = await this.sendRequest('put', `/api/admin/notifications/toggle/${notification.id}`);
                notification.active = !notification.active;
            } catch (error) {
                console.error('Error toggling notification:', error);
            }
        },
        validateForm(data) {

            if (!data.title) {
                this.titleError = 'Title is required.';
            }
            if (!data.message) {
                this.messageError = 'Message is required.';
            }
            if (data.link_type !== 'None' && !data.link) {
                this.linkError = 'Link is required.';
            }

            if (!data.title || !data.message || (data.link_type !== 'None' && !data.link)) {
                // Check if any required fields are empty
                return false;
            }

            return true;
        },
        showCreateModal() {
            this.showModal = true;
        },
        updateLinkPlaceholder(notification) {
            // Update placeholder text based on the selected link type
            if (notification.link_type === 'App Link') {
                this.linkPlaceholder = 'App Link';
            } else {
                this.linkPlaceholder = 'External URL';
            }
        },
        clearErrors() {
            this.titleError = '';
            this.messageError = '';
            this.linkError = '';
        },
        clearNotificationData() {
            this.notificationData = {
                title: '',
                message: '',
                link_type: 'None',
                link: '',
                active: true,
            };
            this.editNotificationData = {};
        },
        cancelCreate() {
            this.clearErrors();
            this.clearNotificationData();
            this.showModal = false;
        },
        cancelEdit() {
            this.clearErrors();
            this.editNotificationData = {};
            this.showEditModal = false;
        },
        confirmDelete(notification) {
            this.notificationToDelete = notification;
            this.showDeleteConfirmation = true;
        },
        cancelDelete() {
            this.notificationToDelete = null;
            this.showDeleteConfirmation = false;
        },
        showToast(message, type = 'success') {
            this.snackbarMessage = message;
            this.showSnackbar = true;
            this.snackbarType = type === 'success' ? 'success' : 'error';
        },
    },
    mounted() {
        this.fetchNotifications();
    },
};
</script>

<style scoped>
.notification-modal .v-dialog__content {
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.disabled {
    background-color: #f5f5f5;
}

.v-snack {
    top: 16px !important;
    bottom: initial !important;
}
</style>
