<template>
<div class='SearchComponent'>

    <div class="wrap_searchBar">
        <p>Search results for:</p>
        <div class="cardForSearch">
            <p><span v-if="query.length>0"></span>{{query}}<span v-if="query.length>0"></span></p>
            <SearchBar class='SearchBar' @query="search" :incoming="$store.getters.searchBar ? $store.getters.searchBar : ''"/>
        </div>
    </div>

    <div class='blueDividingLine'></div>

    <div class="flex_filtersAndResults">

        <v-list dense class='categoryFilters'>
            <p>Category Filters:</p>
            <v-list-item v-for="category in _searchCategories" :key="`search_category-${category.value}`">
                <v-checkbox dense v-model="searchCategories" :label="category.text" :value="category.value"></v-checkbox>
            </v-list-item>
        </v-list>

        <div class="results">
            <v-card v-if="results.guides && results.guides.length>0">
                <v-card-title>
                    Clinical Guide(s)
                </v-card-title>
                <v-card-text>
                    <v-list dense>
                        <template v-for="(guideID, recordIndex) in compileResults(results.guides)">
                            <v-list-item :key="`guides-search-result-${recordIndex}`" @click="$router.push({path:'/hcp/practice/clinical_guides',query:{language,guideID}})">
                                <v-list-item-content>
                                    <v-list-item-title>
                                        <div v-html="getGuideByID(guideID).name"/>
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-divider :key="`guides-search-result-divider-${recordIndex}`"/>
                        </template>
                    </v-list>
                </v-card-text>
            </v-card>

            <v-card v-if="results.site && results.site.length>0">
                <v-card-title>
                    Site Page(s)
                </v-card-title>
                <v-card-text>
                    <v-list dense>
                        <template v-for="(path, recordIndex) in compileResults(results.site)">
                            <template v-if="getPathName(path)">
                                <v-list-item :key="`site-search-result-${recordIndex}`" @click="$router.push({path})">
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            {{getPathName(path).meta.label[language]}}
                                        </v-list-item-title>
                                        <v-list-item-subtitle>
                                            {{path}}
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-divider :key="`site-search-result-divider-${recordIndex}`"/>
                            </template>
                        </template>
                    </v-list>
                </v-card-text>
            </v-card>

            <v-card v-if="results.articles && results.articles.length>0">
                <v-card-title>
                    Article(s)
                </v-card-title>
                <v-card-text>
                    <v-list dense>
                        <template v-for="(pageName, recordIndex) in compileResults(results.articles)">
                            <v-list-item :key="`guides-search-result-${recordIndex}`" @click="loadArticle(pageName)">
                                <v-list-item-content>
                                    <v-list-item-title>
                                        {{getArticleByPageName(pageName).content.title[language]}}
                                    </v-list-item-title>
                                    <v-list-item-subtitle>
                                        {{getArticleByPageName(pageName).content.subtitle[language]}}
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-divider :key="`site-search-result-divider-${recordIndex}`"/>
                        </template>
                    </v-list>
                </v-card-text>
            </v-card>

            <v-card v-if="results.resources && results.resources.length>0">
                <v-card-title>
                    Resource(s)
                </v-card-title>
                <v-card-text>
                    <div class="resource-card-container">
                        <template v-for="(resourceID, resourceIndex) in compileResults(results.resources)">
                         <ResourceCard class="resource-card" :key="`resourceCard-${resourceID}-${resourceIndex}`" :resource="_resourceMap[resourceID]" :language="language" @downloadResource="downloadResource"/>
                        </template>
                    </div>                    
                    <!-- <v-list dense>
                        <template v-for="(resourceID, recordIndex) in compileResults(results.resources)">
                            <v-list-item :key="`guides-search-result-${recordIndex}`" @click="$router.push({path:`/resources/${pageName}`})">
                                <v-list-item-content>
                                    <v-list-item-title>
                                        {{getResourceByID(resourceID).definition.description[language]}}
                                    </v-list-item-title>
                                    <v-list-item-subtitle>
                                        {{getResourceByID(resourceID).definition.type}} | {{resourceID}}
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-divider :key="`site-search-result-divider-${recordIndex}`"/>
                        </template>
                    </v-list> -->
                </v-card-text>
            </v-card>

            <v-card v-if="results.stories && results.stories.length>0">
                <v-card-title>
                    Stories
                </v-card-title>
                <v-card-text>
                    <v-list dense>
                        <template v-for="(pageName, recordIndex) in compileResults(results.stories)">
                            <v-list-item :key="`guides-search-result-${recordIndex}`" @click="$router.push({path:`/patients_and_caregivers/contribute/stories/${pageName}`})">
                                <v-list-item-content>
                                    <v-list-item-title>
                                        {{getStoryById(pageName).story.title[language]}}
                                    </v-list-item-title>
                                    <v-list-item-subtitle>
                                        {{getStoryById(pageName).story.subtitle[language]}}
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-divider :key="`story-search-result-divider-${recordIndex}`"/>
                        </template>
                    </v-list>
                </v-card-text>
            </v-card>
        </div>
    </div>
    <!-- END flex_filtersAndResults -->

</div>
</template>

<script>
import SearchBar from './searchBar'
import ResourceCard from '@/components/Resources/ResourceCard.vue'

export default {
    components: {
        SearchBar,
        ResourceCard
    },
    created: function(){
        if(this.query){
            this.search()
        }
    },
    data: function(){
        return {
            searchCategories: [],
            results: {},
            config: {
                articles: {
                    path: '/news_and_events/article'
                }
            }
        }
    },
    methods: {
        loadArticle: function(data){
            let path = `${this.config.articles.path}/${data}`
            this.$router.push({path})
        },
        search: function(data){
            let searchCategories = this.searchCategories
            let siteDocument = this.siteDocument
            let guidesDocument = this.guidesDocument
            let articlesDocument = this.articlesDocument
            let resourcesDocument = this.resourcesDocument
            let storiesDocument = this.storiesDocument

            var startTime = performance.now()
            var endTime = performance.now()
            let ms = endTime-startTime
            let query = data ? data : this.query
            // console.log('search',{data, query, siteDocument})
            let results = {
                site: (searchCategories.length==0 || searchCategories.includes('site')) && siteDocument ? siteDocument.search([{field:"text",query}]) : null,
                guides: (searchCategories.length==0 || searchCategories.includes('guides')) && guidesDocument ? guidesDocument.search([{field:"name",query},{field:"objective",query},{field:"html",query}]) : null,
                articles: (searchCategories.length==0 || searchCategories.includes('articles')) && articlesDocument ? articlesDocument.search([{field:"topics",query}, {field:"type",query}, {field:"title",query}, {field:"subtitle",query}, {field:"body",query}]) : null,
                resources: (searchCategories.length==0 || searchCategories.includes('resources')) && resourcesDocument ? resourcesDocument.search([{field:"title",query}, {field:"subtitle",query}, {field:"type",query}, {field:"category",query}, {field:"description",query}, {field:"link",query}, {field:"asset",query}]) : null,
                stories: (searchCategories.length==0 || searchCategories.includes('stories')) && storiesDocument ? storiesDocument.search([{field:"title",query}, {field:"subtitle",query}, {field:"body",query}]) : null,
            }

            // console.log({
            //     results,
            //     ms,
            //     query
            // })

            this.results = results
        },
        compileResults: function(results){
            let collection = []
            for(let i=0; i<results.length; i++){
                let record = results[i]
                for(let ii=0; ii<record.result.length; ii++){
                    let id = record.result[ii]
                    if(!collection.includes(id)){
                        collection.push(id)
                    }
                }
            }
            return collection
        },
        getPathName: function(path){
            // let site = this.$root.site.home
            let site = this.$store.getters.site.home
            let stack = path.split('/')
            let target = ''
            for(let i=0; i<stack.length; i++){
                let record = stack[i]
                if(record && record.length>0){
                    target += `['_pages']['${record}']`
                }
            }
            let resolution = eval(`site${target}`)
            return resolution
        },
        getGuideByID: function(guideID){
            let language = this.language
            let guides = this.$store.getters.clinicalGuideData[language].guides
            let results = guides.filter((guide)=>{return guide.id==guideID})
            return results[0]
        },
        getArticleByPageName: function(pageName){
            let articles = this.$store.getters.articles
            let results = articles.filter((article)=>{return article.pageName==pageName})
            return results[0]
        },
        getResourceByID: function(id){
            let array = this.$store.getters.resources
            let results = array.filter((record)=>{return record.id==id})
            return results[0]
        },
        getStoryById: function(id){
            let array = this.$store.getters.stories
            let results = array.filter((record)=>{return record.id==id})
            return results[0]
        },
        downloadResource: function(resource){
            window.open(`${this.$store.getters.bucket}/${resource}`);
        }
    },
    computed: {
        language: function(){
            return this.$store.getters.language
        },
        query: function(){
            return this.$store.getters.searchBar
        },
        siteDocument: function(){
            return this.$store.getters.siteDocument
        },
        guidesDocument: function(){
            return this.$store.getters.guidesDocument
        },
        articlesDocument: function(){
            return this.$store.getters.articlesDocument
        },
        resourcesDocument: function(){
            return this.$store.getters.resourcesDocument
        },
        storiesDocument: function(){
            return this.$store.getters.storiesDocument
        },
        _searchCategories: function(){
            return [
                {text: 'Clinical Guides', value: 'guides'},
                {text: 'Pages', value: 'site'},
                {text: 'Articles', value: 'articles'},
                {text: 'Resources', value: 'resources'},
                {text: 'Stories', value: 'stories'},
            ]
        },
        _resourceMap: function(){
            let resources = this.$store.getters.resources
            let output = {}
            for(let i=0; i<resources.length; i++){
                let resource = resources[i]
                output[resource.id] = resource
            }

            return output
        }
    },
    watch: {
        query: function(){
            if(this.query){
                this.search()
            }
        },
        searchCategories: function(){
            this.search()
        }

    }
}
</script>

<style scoped lang='scss'>

.SearchComponent {
    // border: 2px solid red;

    width: 100%;
    margin: 0 auto;

    .wrap_searchBar {
        background-color: $secondary-light-2;
        padding: 24px 0;

        // "Search results for:"
        > p {
            font-size: 40px;
            font-weight: bold;
            color: $primary;
            margin-left: 10%;

            @media(max-width:768px){
                font-size: 32px;
                margin-left: 6%;
            }
            @media(max-width: 576px){
                font-size: 24px;
            }
            @media(max-width:515px){
                font-size: 18px;
            }
        }

        .cardForSearch {
            width: 80%;
            margin: 0 auto;
            background-color: white;
            box-shadow: 1px 1px 4px 0px rgba(0,0,0,0.2);
            border-radius: 2px;
            padding: 32px 48px 24px;

            @media(max-width: 1200px){
                padding: 28px 36px 24px;
                width: 88%;
            }
            @media(max-width: 992px){
                padding: 24px 32px 20px;
            }
            @media(max-width: 768px){
                padding: 20px 28px 16px;
            }
            @media(max-width: 576px){
                padding: 16px 24px 12px;
            }

            > p {
                text-align: center;
                font-size: 32px;
                word-break: break-all;

                @media(max-width:576px){
                    font-size: 20px;
                }

                > span {
                    display: inline-block;
                    position: relative;
                    top: -8px;
                    height: 4px;
                    width: 56px;
                    background-color: red;
                    text-align: center;

                    @media(max-width:576px){
                        width: 40px;
                        top: -4px;
                    }

                    &:nth-of-type(1){
                        left: -16px;
                    }
                    &:nth-of-type(2){
                        left: 16px;
                    }
                }
            }

            .search {
                width: 100%;
                margin: 0 auto;

                form {

                    .v-input__slot {
                        height: 80px;
                        font-size: 20px;

                        @media(max-width:768px){
                            height: 56px;
                        }
                        @media(max-width: 576px){
                            font-size: 16px;
                        }

                        label {
                            font-size: 24px;

                            @media(max-width:768px){
                                font-size: 20px;
                            }
                        }

                        .v-input__append-inner {
                            margin-right: 8px !important;

                            @media(max-width: 576px){
                                margin-right: initial !important;
                            }
                        }

                        .v-icon {
                            font-size: 40px !important;
                            margin-top: 48px;

                            @media(max-width:768px){
                                font-size: 32px !important;
                                margin-top: 28px;
                            }
                        }
                    }
                }
            }
        }
    }

    .blueDividingLine {
        height: 32px;
        background-color: $primary;
    }

    .flex_filtersAndResults {
        display: flex;
        width: 80%;
        margin: 0 auto;

        @media(max-width:1200px){
            width: 88%;
        }
        @media(max-width:768px){
            flex-flow: column;
        }

        .categoryFilters {
            width: 20%;
            height: fit-content;
            background-color: $secondary-light-2;
            margin-top: 16px;
            margin-right: 16px;
            padding: 16px;
            border: 1px solid lightgrey;

            @media(max-width:992px){
                width: 30%;
            }
            @media(max-width:768px){
                width:100%;
                display: flex;
                flex: none;
                flex-wrap: wrap;
            }

            > p {
                font-size: 18px;
                font-weight: bolder;
                color: $primary;
                width: 100%;
            }
            > div {
                padding-left: 0;
            }
        }

        .results {
            width: 80%;

            @media(max-width:992px){
                width: 70%;
            }
            @media(max-width:768px){
                width: 100%;
            }

            .v-card {
                border-radius: 0;
                margin: 16px 0 24px;

                .v-card__title {
                    background-color: $primary;
                    color: white;
                }
            }
        }
    }
}

.resource-card-container{
   display: flex; 
   flex-wrap: wrap;
   justify-content: center;
   gap: 5px;
}

.resource-card{
    width: 24%;
    min-width: 250px;
}
</style>
