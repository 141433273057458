<template>
  <v-app class="app">
    <Authorizer @ready="readyAuthorizer=true"/>
    <div v-if="$store.getters.siteBlocker===false && ready && readyAuthorizer">
      <v-main class="app-main">
        <router-view :site="site" @pageUpdate="pageUpdate"/> 
      </v-main>
      <FlexSearch/>
    </div>
    <!-- <siteBlocker/> -->
    <AllowCookies/>
    <Disclaimer/>
    <Pins/>
  </v-app>
</template>

<script>
import AllowCookies from '@/components/AllowCookies/AllowCookies'
import Disclaimer from '@/components/Disclaimer/Disclaimer'
import SVGLoader from 'external-svg-loader'
import Vue from "vue";
import axios from "axios";
import Header from '@/components/Header/Header'
// import NavBar from '@/components/Nav/NavBar/NavBar'
// import PageNav from '@/components/Nav/pageNav/pageNav'

import Footer from '@/components/Footer/Footer'
import siteBlocker from '@/components/siteBlocker/siteBlocker'
import FlexSearch from '@/components/Search/FlexSearch'
import Authorizer from '@/components/Authorizer/Authorizer'
import Pins from '@/Controllers/Pins'
export default {
  name: 'App',
  components: {
    Authorizer,
    Pins,
    Header,
    // NavBar,
    // PageNav,
    Footer,
    FlexSearch,
    siteBlocker,
    AllowCookies,
    Disclaimer
  },
  // metaInfo: {
  //   htmlAttrs: {
  //     lang: 'en-ca'
  //   },
  //   title: 'Thrombosis Canada',
  //   titleTemplate: `%s | Main Wrapper`
  // }, 
  created: async function(){
    let self = this
    Vue.prototype.sendRequest = self.sendRequest
    Vue.prototype.sendRequestMakePDF = self.sendRequestMakePDF

      var hidden, visibilityChange;
      if (typeof document.hidden !== "undefined") { // Opera 12.10 and Firefox 18 and later support
        hidden = "hidden";
        visibilityChange = "visibilitychange";
      } else if (typeof document.msHidden !== "undefined") {
        hidden = "msHidden";
        visibilityChange = "msvisibilitychange";
      } else if (typeof document.webkitHidden !== "undefined") {
        hidden = "webkitHidden";
        visibilityChange = "webkitvisibilitychange";
      }
      document.addEventListener(visibilityChange, function (event) {
          if (document[hidden]) {
              self.$store.commit('appVisible',false)
          } else {
              self.$store.commit('appVisible',true)
          }
      });

      window.addEventListener('resize', this.handleResize);    
      // this.$store.commit('componentConfigurations',this.$root.componentConfigurations)
      // this.$store.commit('resources',this.$root.resources)
      // this.getStories()
      await this.initLabels()
      await this.getAssets()
      this.handleResize()
      this.queryHandler()
      this.ready = true
 
  },
  destroyed() {
      window.removeEventListener('resize', this.handleResize);
  },
  data: function(){
    return {
      ready: false,
      readyAuthorizer: false,
      authenticatorReady: false,
      ui: {
        contact_us: false,
        exposeLabels: false
      },
      session: {
        expires: null,
        time_left: null,
        timer: null,
        inactivity_timer: null
      },
      tags: {}
    }
  },
  methods: {
    sendRequest: function(method='get', url, data=null){
      let self = this
      return new Promise((resolve, reject)=>{
        //&& url.indexOf('/admin/')>0
        let removeAuthHeader = url.search("https://s3")==0
        if(self.$store.getters.user && !removeAuthHeader){
          axios.defaults.headers['Authorization'] = `Bearer ${self.$store.getters.user.signInUserSession.idToken.jwtToken}`
        }else{
          delete axios.defaults.headers['Authorization']
        }
        if(process.env.NODE_ENV!='development'){
          // console.log('sendRequest',{url,data,env: process.env})
          url = url.replace('/api',process.env.VUE_APP_API)
        }
        if(method=='upload'){
          axios.defaults.headers['Content-Type'] = 'multipart/form-data'
          method = 'put'
        }else{
          delete axios.defaults.headers['Content-Type']
        }
        // console.log('sendRequest',{headers: axios.defaults.headers})
        axios({
          method,
          url,
          data
        }).then((response)=>{
          if(response.headers['content-type']=="text/calendar; charset=utf-8"){
            let filename = "meeting.ics"
            if(filename){
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', filename);
              link.setAttribute('id','download-link')
              document.body.appendChild(link);
              link.click();
              link.remove();
            }

          }
          resolve(response)
        },(error)=>{
          resolve(error)
        })
      });
    },
    initLabels: async function(){
      let labels = await this.sendRequest('get','/api/label/downloadAll')
      this.$store.commit('labels',labels.data)
    },
    getAssets: async function(){
        let response = await this.sendRequest('get','/api/assets')
        if(response.status==200){
            this.$store.commit('assets',{contents: response.data.contents, assets: response.data.assets})
            // this.$store.commit('bucket',response.data.bucket)
            this.$store.commit('bucket','https://thrombosiscanada.ca')
        }
    },
    getStories: async function(){
        let response = await this.sendRequest('get','/api/stories')
        if(response.status==200){
            return this.$store.commit('stories', response.data)
        }
    },
    handleResize: function(){
      this.$store.commit('width', window.innerWidth)
      this.$store.commit('height', window.innerHeight)
    },
    processTags: function(data){
      let tags = this.tags
      let language = this.language
      if(!tags[data.component]){
        tags[data.component] = data.tags
      }else{
        for(let tagType in data.tags){
          let tagSet = data.tags[tagType]
          for(let lang in tagSet){
            let tagData = tagSet[lang]
            if(typeof tagData=='string'){
              if(!tags[data.component][lang]){
                tags[data.component][lang] = ''
              }
              // tags[data.component][lang] += ''
            }
          }
        }
      }
    },
    pageUpdate: function(page){
      // console.log('pageUpdate',page)
      this.updateMetaTags(page)
      // document.getElementsByClassName('render-body-part-component')[0].scrollIntoView({behavior:'smooth'})
    },
    updateMetaTags: function(page){
      let language = page.language //this.language
      let elem = {
        language: document.getElementsByTagName("html")[0],
        title: document.getElementsByTagName("title")[0],
        description: document.querySelectorAll("meta[name='description']")[0]
      }
      elem.language.setAttribute('lang',language)
      elem.title.innerHTML = page.title && page.title[language] ? page.title[language] : ''
      elem.title.innerHTML += (elem.title.innerHTML.length>0 ? ' | Thrombosis Canada' : 'Thrombosis Canada')
      elem.description.setAttribute('content',page.description && page.description[language] ? page.description[language] : '')

    },
    queryHandler: function(){
      let page_id = this.query.page_id
      if(page_id){
        if(page_id=='18'){
          this.$router.push({path: '/hcp/practice/clinical_guides'})
        }
        if(page_id=='502'){
          this.$router.push({path: '/hcp/practice/clinical_tools'})          
        }
      }
    },
    sendRequestMakePDF: function(type='get',url=null,data=null){
      return new Promise((resolve, reject)=>{
        axios.defaults.baseURL = url ? url : '//localhost:3000'
        axios.defaults.headers['x-api-key'] = process.env.VUE_APP_PDF_MAKE_API_KEY ? process.env.VUE_APP_PDF_MAKE_API_KEY : null
        switch(type){
          default:
            axios.get(url,data).then((response)=>{
              resolve(response)
            },(response)=>{
              console.error({
                type,
                data,
                response
              });
              reject()
            })
          break;

          case"post":
            axios.post(url,data).then((response)=>{
              resolve(response)
            },(error)=>{
              switch(error.response.status){
                case 400:
                  alert('Error')
                  resolve(error.response)
                break;
              }
              console.error({
                type,
                data,
                error
              });
              reject()
            })
          break;
        }
      });
    }
  },
  computed: {
    language: function(){
      return this.$store.getters.language
    },
    signedIn: function(){
      return this.$store.getters.signedIn
    },
    debug: function(){
      return this.$store.getters.debug
    },
    site: function(){
      return this.$store.getters.site
    },
    path: function(){
      return this.$route.path
    },
    query: function(){
      return this.$route.query
    }    
    // pageNav: function(){
    //   if(this.$route.matched[0].path!=''){
    //     let site = this.site
    //     let path = this.$route.matched[0].path
    //     let trace = '.home'+path.replaceAll('/', this.$route.path=='/' ? '' : '._pages.')
    //     let page = eval(`site${trace}`)
    //     let pageNav = page.definition ? page.definition.filter((item)=>{return item.component=='pageNav'}) : undefined
    //     return pageNav
    //   }else{
    //     return false
    //   }
    // }
  },
  watch: {
    path: function(after, before){
      if(after!=before){
        window.scrollTo(0,0)
      }
    },
    query: function(){
      this.queryHandler()
    }
  }
};
</script>


<style lang="scss">
.app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;
  max-width: 1600px;
  margin: 0 auto;
}
body{
  background-color: whitesmoke;
}
.v-application--wrap{
  min-height: 100px !important;
}

.themed-button-dark{
  background-color: $primary-1 !important;
  color: white !important;
  font-weight: bold;
  font-size: 1.2em;
}

a.themed-button.red{
  border-radius: 5px;
  text-decoration: none;
  background-color: $tc-red !important;
  color: white !important;
  font-weight: bold;
  font-size: 1.2em;
  padding: 12px;
}
  a.themed-button.red:hover{
    opacity: 0.9;    
  }

.spacer-light{
  width: 100%;
  height: 15px;
  background-color: $primary-light-2;
}

.separator{
  height: 3px;
  background-color: $accent-light-1;
}

.text-primary-1{
  color: $primary-1;
}

hr{
  border: 2px solid $tc-red;
  margin: 15px 0px 15px 0px;
}

hr.small{
  max-width: 60px;
}
</style>
