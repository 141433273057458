<template>
  <div style="max-width: 1024px; margin: 0 auto;">
    <grid-layout :layout.sync="config.layout"
                 :col-num="12"
                 :row-height="30"
                 :is-draggable="false"
                 :is-resizable="false"
                 :responsive="true"
                 :vertical-compact="true"
                 :use-css-transforms="true"
    >
        <grid-item v-for="(item, itemIndex) in config.layout" style="border: none; border-color: whitesmoke;"
            :static="item.static"
            :x="item.x"
            :y="item.y"
            :w="item.w"
            :h="item.h"
            :i="item.i"
            :key="`grid-item-${itemIndex}`"
        >
            <v-img :src="`${$store.getters.bucket}/${item.content.link[language]}`"/>
        </grid-item>
    </grid-layout>
  </div>
</template>

<script>
import { GridLayout, GridItem } from "vue-grid-layout"
export default {
    components: {
        GridLayout,
        GridItem
    },
    props: {
        config: {
            type: Object,
            required: true
        },
        language: {
            type: String,
            required: true
        }
    }
}
</script>

<style scoped>
.vue-grid-layout {
    /* background: #eee; */
}

.vue-grid-item:not(.vue-grid-placeholder) {
    /* background: #ccc; */
    border: 1px solid black;
}

.vue-grid-item .resizing {
    opacity: 0.9;
}

.vue-grid-item .static {
    /* background: #cce; */
}

.vue-grid-item .text {
    font-size: 24px;
    text-align: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: 100%;
    width: 100%;
}

.vue-grid-item .no-drag {
    height: 100%;
    width: 100%;
}

.vue-grid-item .minMax {
    font-size: 12px;
}

.vue-grid-item .add {
    cursor: pointer;
}

.vue-draggable-handle {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 0;
    left: 0;
    background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='10'><circle cx='5' cy='5' r='5' fill='#999999'/></svg>") no-repeat;
    background-position: bottom right;
    padding: 0 8px 8px 0;
    background-repeat: no-repeat;
    background-origin: content-box;
    box-sizing: border-box;
    cursor: pointer;
}

.columns {
    -moz-columns: 120px;
    -webkit-columns: 120px;
    columns: 120px;
}
</style>

<style lang="scss">
.youtubeVideo-title{
    font-weight: bold;
    font-size: 0.8em;
}
.youtubeVideo{
    border: 1px solid whitesmoke;
    width: 80%;
    height: 150px;
}
</style>