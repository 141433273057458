<template>
  <v-card  class="news" style="width:380px; height: 590px;" rounded="0">
      <div class="header">
        <v-img src="https://cdn.vuetifyjs.com/images/cards/sunshine.jpg" height="300px"></v-img>
        <v-btn icon class="pin" :class="[{'pinned':isPinned()}]" @click="pin">
          <v-icon color="white" large>mdi-pin</v-icon>
        </v-btn>
        <v-avatar class="document">
          <v-icon color="white" large>mdi-file-document-outline</v-icon>
        </v-avatar>
      </div>

      <v-card-text>
        <div class="content">
          <div v-html="params.article.title[language]" class="title"/>
          <hr class="themed-line">
          <div v-html="params.article.subtitle[language]" class="subtitle"/>
        </div>
      </v-card-text>
      
        <v-btn class="themed-button-dark" elevation="0">
          Read More
        </v-btn>
  </v-card>
</template>

<script>
export default {
  props: {
    params: {
      type: Object,
      required: true
    },
    language: {
      type: String,
      required: true
    }
  },
  methods: {    
    pin: function(){
      let article = this._item
      let category = this._category
      let pins = this.cache.storage.local.get('pins')
      if(pins===null){
        pins = {}
      }
      if(!pins[category]){
        pins[category] = {}
      }
      console.log('pinning',{
        article,
        category,
        pins,
        isPinned: this.isPinned()
      })
      pins[category][article.id] = !this.isPinned()
      this.cache.storage.local.set('pins',pins)
      this.$emit('pin',{article, pinned: pins[category][article.id]})
      this.$forceUpdate()
    },
    isPinned: function(){
      let article = this._item
      let category = this._category
      let pins = this.cache.storage.local.get('pins')      
      if(pins && pins[category]){
        return pins[category][article.id] === true
      }else{
        return false
      }
    }    
  },
  computed: {
    _item: function(){
      return this.params.article
    },
    _category: function(){
      return this.params.category
    }
  }
}
</script>

<style scoped lang="scss">
.header{
  position: relative;
}

.pin{
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: $primary-1;
}

.pinned{
  background-color: $tc-red;
}

.header .v-avatar.document{
  position: absolute;
  bottom: 20px;
  left: 20px;
  background-color: $accent-light-1;
}

.news .content{
  color: $primary-1;
  padding: 25px 25px 0px 25px;
}

.title{
  line-height: 22px !important;
  font-weight: bold;
  text-transform: uppercase;

  display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;  
}

.themed-line{
  border-color: $accent-light-1;
  width: 100px;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 20px;
}

.news button.themed-button-dark{
    position: absolute;
    bottom: 25px;
    left: 50%;
    transform: translate(-50%,0);
    font-weight: bold;
    font-size: 1.2em;
}
</style>