<template>
  <div class='Profile'>
    <v-container class='myProfileForm'>
        <v-row>
            <v-col>
                <div class="flexFields">
                    <div>
                        <p class='fieldLabel'>First Name:</p>
                        <v-text-field v-model="form.profile.given_name" outlined />
                    </div>
                    <div>
                        <p class='fieldLabel'>Last Name:</p>
                        <v-text-field v-model="form.profile.family_name" outlined />
                    </div>
                </div>

                <!-- Healthcare Professional + Country Wrap -->
                <div class="hcp_specialty_country_wrap">
                    <div class='hcp_specialty_wrap'>
                        <!-- HCP -->
                        <div>
                            <p class='fieldLabel'>I am a Healthcare Professional</p>
                            <v-radio-group row v-model="form.profile['custom:hcp']">
                                <v-radio value="Y" label="Yes"/> <v-radio value="N" label="No"/>
                            </v-radio-group>
                        </div>
                        <!-- Specialty -->
                        <div v-if="form.profile['custom:hcp']=='Y'">
                            <p class='fieldLabel'>Specialty:</p>
                            <v-select background-color="white" :items="hcpSpecialtyList" item-text="label" item-value="value" v-model="form.profile['custom:specialty']" placeholder="Select ..." outlined></v-select>
                        </div>
                    </div>
                    <!-- Country -->
                    <div class='country_wrap'>
                        <p class='fieldLabel'>Country:</p>
                        <v-text-field background-color="white" v-model="form.profile['custom:country']" outlined/>
                    </div>
                </div>

                <v-btn @click="update">Update</v-btn> <span class='buttonDivider'>|</span> <v-btn @click="ui.changePassword=true">Change Password</v-btn>
            </v-col>
        </v-row>
    </v-container>

    <v-dialog v-model="ui.changePassword" content-class="elevation-0">
        <v-card class='changePasswordModal'>
            <v-card-title>Change Password</v-card-title>
            <v-card-text>
                <v-otp-input v-model="temp.verificationCode" type="number" :length="config.pinLength"/>
                <v-btn class='requestChangePassword_button' @click="forgotPassword">Request Change <br v-if="this.$store.getters.width<515">Password Code</v-btn>
                <hr>
                <p class='fieldLabel'><span>*</span> Password:</p>
                <v-text-field background-color="white" v-model="form.password" :type="state.showPassword ? 'text' : 'password' " append-icon="mdi-eye" @click:append="state.showPassword=!state.showPassword" outlined />
                <p class='fieldLabel'><span>*</span> Repeat Password:</p>
                <v-text-field background-color="white" v-model="temp.confirmPassword" :type="state.showPassword ? 'text' : 'password' " append-icon="mdi-eye" @click:append="state.showPassword=!state.showPassword" outlined />
            </v-card-text>
            <v-card-actions class='bottomButtons'>
                <v-btn class='cancel_button' @click="ui.changePassword=false" text>Cancel</v-btn>
                <v-spacer/>
                <v-btn :disabled="!allowResetPassword" class="themed-button-dark" elevation="0" @click="resetPassword">Reset Password</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <v-snackbar v-model="state.snackbar.display" rounded="pill">
        <span v-html="state.snackbar.message"/>
    </v-snackbar>
  </div>
</template>

<script>
import validations from '@/plugins/validations.js'
import hcpSpecialtyList from '@/assets/hcpSpecialtyList.json'
export default {
    created: function(){
        this.validations = new validations.methods(this)
        this.rules = new validations.rules(this)
        let attributes = this.$store.getters.user.attributes
        let profile = this.form.profile
        console.log('created profile', profile);
        for(let key in profile){
            if(attributes[key]){
                profile[key] = attributes[key]
            }
        }
    },
    data: function(){
        return {
            config: {
                pinLength: 6
            },
            state: {
                showPassword: false,
                snackbar: {
                    display: false,
                    message: null
                }
            },
            ui: {
                changePassword: false
            },
            form: {
                profile: {
                    given_name: null,
                    family_name: null,
                    "custom:country": null,
                    "custom:specialty": null,
                    "custom:language": null,
                    "custom:hcp": "N"
                },
                password: null
            },
            temp: {
                confirmPassword: null,
                verificationCode: null
            },
            hcpSpecialtyList: hcpSpecialtyList
        }
    },
    methods: {
        update: async function(){
            let data = JSON.stringify(this.form.profile)
            data = JSON.parse(data)

            // specialty starts off null, but update won't allow null, so make empty string
            if( data['custom:specialty'] == null){
                data['custom:specialty'] = '';
            }
            // empty string for specialty value if answer to hcp is N
            if( data['custom:hcp'] == 'N'){
                data['custom:specialty'] = '';
            }

            data['custom:language'] = this.$store.getters.language
            let updateResult = await this.$store.getters.Authorizer.updateAttribute(data)
            console.log('updateResult', updateResult)
        },
        forgotPassword: async function(){
            let username = this.$store.getters.user.attributes.email
            let pinRequest = await this.$store.getters.Authorizer.forgotPassword(username)
            this.ui.changePassword = true
            console.log({
                pinRequest
            })
        },
        isValid: function(type, input){
            if(input===null){
                return false
            }

            if(type=='email'){
                return this.validations.validEmail(input)
            }

            if(type=='password'){
                return this.validations.validPassword(input)
            }

            if(type=='verificationCode'){
                return input.length==this.config.pinLength
            }
        },
        resetPassword: async function(){
            let username = this.$store.getters.user.attributes.email
            let password = this.form.password
            let verificationCode = this.temp.verificationCode
            let response = await this.$store.getters.Authorizer.resetPassword(username, verificationCode, password)
            if(response=='SUCCESS'){
                this.state.snackbar.display = true
                this.state.snackbar.message = 'Password reset successful'
                this.ui.changePassword = false
            }
            console.log('sendResetCode', response)
        }
    },
    computed: {
        allowResetPassword: function(){
            let temp = this.temp
            return this.isValid('email', this.$store.getters.user.attributes.email) && this.isValid('verificationCode', temp.verificationCode)
        }
    }
}
</script>

<style lang='scss' scoped>

.Profile {

    .myProfileForm {
        width: 100%;
        max-width: 720px;
        padding: 24px;

        .flexFields {
            display: flex;
            justify-content: space-between;

            @media(max-width: 515px){
                flex-flow: column;
            }

            > div {
                width: calc(50% - 8px);

                @media(max-width:515px){
                    width: 100%;
                    margin-top: -16px;
                }

                .fieldLabel {
                    margin-left: 2px;
                    position: relative;
                    top: 12px;
                }

                .v-text-field {

                }
            }
        }

        .hcp_specialty_country_wrap {
            display: flex;

            @media(max-width:515px){
                display: block;
            }

            .hcp_specialty_wrap {
                width: calc(50% - 8px);
                margin-right: 16px;

                @media(max-width:515px){
                    width: 100%;
                }

                > div:nth-of-type(2){
                    @media(max-width:515px){
                        width: 100%;
                    }
                }
            }

            .country_wrap {
                width: calc(50% - 8px);

                @media(max-width:515px){
                    width: 100%;
                }
            }
        }

        .v-input--switch {
            margin-top: 0px;
        }

        .v-btn {
            background-color: $primary;
            color: white;
            margin-bottom: 8px;

            @media(max-width: 515px){
                width: 100%;
                max-width: 240px;
                margin: 0 auto 8px;
                display: block;
            }
        }

        .buttonDivider {
            margin: 0 16px;
            @media(max-width: 515px){
                display: none;
            }
        }
    }
    // END myProfileForm
}


// NOTE: v-dialogs have outside-scope
.changePasswordModal {
    width: 100%;
    max-width: 640px;
    margin: 0 auto;

    .requestChangePassword_button {
        background-color: $primary !important;
        color: white !important;

        @media(max-width: 515px){
            width: 100%;
            display: block;
            margin: 0 auto !important;
            padding: 8px 0 !important;
            height: auto !important;
        }
    }

    .fieldLabel {
        position: relative;
        top: 12px;

        &:nth-of-type(2){
            margin-top: -24px;
        }

        span {
            color: red;
        }
    }

    .cancel_button {
        background-color: $red;
        color: white;
    }

    .v-card__actions {
        padding: 16px 24px !important;
        margin-top: -32px;

        @media(max-width:515px){
            display: flex;
            flex-flow: column;
        }

        .v-btn {
            @media(max-width:515px){
                width: 100%;
                margin-bottom: 8px;
            }
        }
    }
}

</style>
