<template>
  <div>
    <v-card flat>
      <v-card-text>
        <div class="guidecard" @click="$emit('selectGuide',guide)">
          <div class="guide-title">
            <router-link style="color: white;" :to="{params:{language,guideID:guide.id}}"><span v-html="guide.name"/></router-link>
          </div>

          <div class="guide-topics" v-html="_guideTopics"/>
          <div class="guide-objective" v-html="guide.objective"/>
        </div>
        <v-btn icon class="pin" :class="[{'pinned':pins.isPinned(pinKey, guide)}]" @click="pins.pin(pinKey, guide);$forceUpdate()">
          <v-icon color="white" large>mdi-pin</v-icon>
        </v-btn>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import pinsController from '@/plugins/pins'
export default {
  props: {
    language: {
      type: String,
      required: false,
      default(){
        return this.$store.getters.language
      }
    },
    guideTopics: {
      type: Array,
      required: true
    },
    guide: {
      type: Object,
      required: true
    }
  },
  created: function(){
      // this.pins = new pinsController('clinicalGuides', this)
      this.pins = this.$store.getters.controllers.pins
  },
  data: function(){
    return {
      pins: null,
      pinKey: 'clinicalGuides'
    }
  },
  computed: {
    _guideTopics: function(){
      let unique = []
      let guideID = this.guide.id
      let topics = this.guideTopics

      for(let i=0; i<topics.length; i++){
        let topic = topics[i]
        if(topic.guideIDs.includes(guideID)){
          unique.push(topic.name)
        }
      }

      let output = ''
      for(let i=0; i<unique.length; i++){
        output += i==0 ? unique[i] : ', '+unique[i]
      }
      return output
    }
  }
}
</script>

<style scoped lang="scss">
.guidecard{
  border: 1px solid grey;
  cursor: pointer;
}
.guide-title{
  color: white;
  background-color: $primary-1;
  font-size: 1.8em;
  font-weight: bold;
  padding: 12px;
  line-height: 32px;
}

.guide-topics{
  padding: 5px;
  font-style: italic;
}

.guide-objective{
  padding: 10px;
  font-weight: bold;
  color: $primary-1;
}

.pin{
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: $primary-1;
}

.pinned{
  background-color: $tc-red;
}
</style>
