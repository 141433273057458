<template>
  <v-card flat>
    <div class="member-headshot" :style="{'background-image':`URL('${$store.getters.bucket}/${typeof member.image=='string' ? member.image : member.image.Key}')`, 'background-color':`${member['background-color']}`}">
        <div class="triangle"></div>
    </div>
    <v-card-title>
        <div style="width: 100%;">
            {{member.name}} <span class="member-postnominals" v-html="`, ${member.postnominals[language]}`"/>
        </div>
    </v-card-title>
    <v-card-subtitle>
        <div class="member-title">
            {{member.title[language]}}
        </div>
        <div class="red-line"/>
    </v-card-subtitle>
    <v-card-text>
        <div class="member-about" v-html="member.about[language]"/>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
    props: {
        member: {
            type: Object,
            required: true
        },
        language: {
            type: String,
            required: true
        }
    }
}
</script>

<style lang="scss" scoped>
.member-headshot{
    position: relative;
    height: 300px;
    width: 100%;
    background-size: contain;
    background-position: center;
}

.member-postnominals{
    font-size: 0.7em;
    font-weight: normal;
    position: relative;
}

.member-title{
    margin: 10px;
}

.member-about{
    text-align: left;
}

.red-line{
    width: 100px;
    margin: 0 auto;
    margin-top: 15px;
    margin-bottom: 15px;
    border: 2px solid $red;
}

.triangle{
    position: absolute;
    bottom: 0px;
    right: 0px;
    transform: translate(0,0);
    width: 0; 
    height: 0; 
    border-left: 40px solid transparent;
    border-right: 40px solid $accent;  
    border-top: 40px solid transparent;
}
</style>