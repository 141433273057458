<template>
  <div>
    <div v-if="componentConfig.config.twitter.type=='highlight'"  style="text-align: center; max-width: 300px; margin: 0 auto;">
        <!-- <svg :data-src="`${$store.getters.bucket}/uploads/icons/twitter.svg`"/> -->
        <!-- <blockquote class="twitter-tweet">
            <a :href="`https://twitter.com/ThrombosisCan/status/${tweet_id}?ref_src=${twitter_ref}`">Tweet</a>
        </blockquote>
        <v-btn class="themed-button-dark" @click="$router.push({path:'/twitter'})">Thrombosis Canada Tweets ></v-btn>
        <hr> -->
        <a class="twitter-timeline" href="https://twitter.com/ThrombosisCan?ref_src=twsrc%5Etfw" data-height="400" data-chrome="noheader nofooter noscrollbar">Tweets by ThrombosisCan</a>
        <v-btn class="themed-button-dark" @click="$router.push({path:'/twitter'})">Thrombosis Canada Tweets ></v-btn>
    </div>
    
    <v-container v-else style="max-width: 800px;">
        <v-row>
            <v-col>
                <a class="twitter-timeline" href="https://twitter.com/ThrombosisCan?ref_src=twsrc%5Etfw">Tweets by ThrombosisCan</a>
            </v-col>
        </v-row>
    </v-container>
    
  </div>
</template>

<script>
export default {
    props: {
        language: {
            type: String,
            required: true
        },
        componentConfig: {
            type: Object,
            required: true
        },
        config: {
            type: Object,
            required: true
        }
    },
    created: async function(){
        this.init()
    },
    data: function(){
        return {
            ui: {
                tab: 0
            }
        }
    },
    methods: {
        init: async function(){
            let response = await this.sendRequest('get','https://platform.twitter.com/widgets.js')
            if(response.status==200){
                eval(response.data)
            }            
        }
    },
    computed: {        
        tweet_id: function(){
            return this.config[this.language]
        },
        twitter_ref: function(){
            return "twsrc%5Etfw"
        }
    }
}
</script>

<style scoped lang="scss">
svg {
    fill: white !important;
    height: 40px;
    width: 40px;
}
</style>